import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const WithdrawTable = () => {
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    getwithdrawHistory(1);
  }, [0]);


  const timeOption = {
    timeZone: 'Europe/Madrid', // Spanish timezone
    month: 'short', // Short month (e.g., Dec)
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  };
  const formatter = new Intl.DateTimeFormat('es-ES', timeOption);

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var withdraw_history_list = await postMethod(data);
    setSiteLoader(false);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;

  const handlepagewithdraw = (event, page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("address-copied"));
  };

  return (
    <>
      <section>
        <Header />
      </section>
      <main className="dashboard_main assets_main ">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12 ">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="buy_head p-0">
                      <div className="Buycrypto_title">{t("history")}</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          {t("deposit")}
                        </Link>
                        <Link
                          to="/withdrawHistory"
                          className="history-links active"
                        >
                          {t("withdraw")}
                        </Link>
                        <Link to="/tradeHistory" className="history-links">
                          {t("trade")}
                        </Link>
                        <Link to="/loginHistory" className="history-links">
                          {t("login")}
                        </Link>
                        <Link to="/referralHistory" className="history-links">
                          {t("referral")}
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          {t("reward")}
                        </Link>
                        <Link to="/stakingHistory" className="history-links">
                          {t("staking")}
                        </Link>
                        <Link to="/swapHistory" className="history-links">
                          {t("swap")}
                        </Link>
                        <Link to="/openorderHistory" className="history-links ">
                          {t("open-order-history")}
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          {t("cancel-order-history")}
                        </Link>
                        <Link
                          to="/buycryptoHistory"
                          className="history-links "
                        >
                          {t("buy-crypto-history")}
                        </Link>
                        {/* <Link to="/stoporderHistory" className="history-links ">
                        {t("stop-order-history")}
                      </Link> */}
                      </ul>
                      {/* <AssetListTable /> */}

                      {siteLoader == true ? (
                        <div className="loadercssss">
                          <Bars
                            height="80"
                            width="80"
                            color="#33c2b9"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead className="ref_table_head">
                              <tr className="stake-head font-satoshi thead-bor-bottom">
                                <th>{t("transaction-id")}</th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("amount")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("currency")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("date")}
                                </th>
                                <th className="opt-btn-flex table-action ">
                                  {t("status")}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {withdrawHistory.length > 0 ? (
                                withdrawHistory.map((data, i) => {
                                  return (
                                    <tr>
                                      <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                                        {data.txn_id != undefined
                                          ? data.txn_id.slice(0, 10)
                                          : "-"}
                                        <i
                                          class="fa fa-clone"
                                          aria-hidden="true"
                                          onClick={() => copy(data.txn_id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {data.amount == undefined
                                          ? 0
                                          : data.amount.toFixed(4)}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {data.currency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {/* {Moment(data.created_at).format(
                                          " lll"
                                        )} */}
                                      { formatter.format(new Date(data.created_at))}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 px-2 table_center_text pad-top-14px">
                                        {data.status !== "Pending" ? (
                                          <span className="text-green">
                                            {t("completed")}
                                          </span>
                                        ) : data.status == "Cancelled" ? (
                                          <span className="text-green">
                                            {t("Cancelled")}
                                          </span>
                                        ) : (
                                          <span className="text-red">
                                            {t("pending")}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.png")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        No Records Found
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {withdrawHistory && withdrawHistory.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(
                                    withdrawtotalpage / withdrawrecordpage
                                  )}
                                  page={withdrawcurrentpage}
                                  onChange={handlepagewithdraw}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#33c2b9",
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WithdrawTable;
