import React,{useEffect} from "react";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { setAuthorization } from "../core/service/axios";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TFA = () => {
  const { t } = useTranslation();

  const initialFormValue = {
    tfa: "",
  };
  

  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const navigate = useNavigate();

  const { tfa } = formValue;
  const { state } = useLocation();
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.tfaVerify,
        payload: {
          userToken: tfa,
          socketToken: state?.socketToken,
        },
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        // showSuccessToast(resp.Message);
         if(resp.Message=="Login Successfull!"){
          showSuccessToast(t("Login-successfully"))
        }
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("registe_type", resp.registerType);
        localStorage.setItem("tfa_status", resp.tfa);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        if(resp.Message=="User not found"){
          showErrorToast(t("User-not-found"))
        }else if(resp.Message=="Account has deactivated"){
          showErrorToast(t("Account has deactivated"))
        }else if(resp.Message=="Something Went Wrong. Please Try Again later"){
          showErrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
        }else if(resp.Message=="Invalid 2FA Code")
        {
          showErrorToast(t("Invalid 2FA Code"))
        }
        // showErrorToast(resp.Message);
      }
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = t("2fa-required");
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const movetologin= ()=>{
    navigate("/login");
  }
  
  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>

      <div>
        <div className="Verification enable2fa-pt">
          <div className="container">
            <div>
              <h6 onClick={movetologin} className="cursor">
                {" "}
                <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("login")}
              </h6>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span class="heading">{t("tfa")}</span>

                  <div className="notify"> {t("tfa-notify-content")} </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">{t("2fa-code")}</h6>
                    <input
                      type="text"
                      id="numberInput"
                      min="0"
                      maxLength={6}
                      name="tfa"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                      }}
                      value={tfa}
                      onChange={handleChange}
                      className="input-field"
                      placeholder={t("enter-code")}
                    />
                    {tfaValidate == true ? (
                      <p className="text-danger"> {validationnErr.tfa} </p>
                    ) : (
                      ""
                    )}
                    {/* <span className="textgreen eyeicons"> 56 s </span> */}
                  </div>

                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={formSubmit}>{t("submit")}</button>
                    ) : (
                      <button>{t("loading")} ...</button>
                    )}
                  </div>

                  <div className="foot">
                    <p>
                      {t("back-to")} <Link to="/login"> {t("login-page")}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TFA;
