import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData3";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [withdrawStatus, setWithdrawStatus, withdrawStatusref] = useState("");

  useEffect(() => {
    setSiteLoader(true)
    getSiteSettings();
  }, [0]);

  const [type, settype] = useState();

  useEffect(() => {
    var Type = localStorage.getItem("registe_type");
    settype(Type);
  }, [0]);

  const navigate = useNavigate();

  const [siteLoader, setSiteLoader,siteLoaderref] = useState(false);

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);



  const handleResend = async () => {
    withdrawPreview();
  };
  const bankdetails = () => {};
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      showsuccessToast(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      showerrorToast(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue, formValueref] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [withdrawcurrencyValidate, setwithdrawcurrencyValidate] =
    useState(false);
  const [
    withdrawnetworkValidate,
    setwithdrawnetworkValidate,
    withdrawnetworkValidateref,
  ] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);

  const [addresshide, setaddresshide, addresshideref] = useState("Deactive");

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    setSiteLoader(true);
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    // getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
    validate_preview(formData);
  };
  const handlekeydown = async (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const caluculateMax = async ()=>{
    try{
      setFormValue((prevState) => ({
        ...prevState,
        amount: (balanceref.current.balance).toFixed(6),
      }));
      console.log(formValue,"formValue")
    }catch(err){
      
    }
  }
  

  const addresshides = async (e) => {
    if (addresshideref.current == "Deactive") {
      setaddresshide("Active");
    } else {
      setaddresshide("Deactive");
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    setSiteLoader(true);
    if (resp) {
      setTimeout(() => {
        setSiteLoader(false);
      }, 800);
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].withdrawStatus == "Active") {
          var obj = {
            value: data[i]._id,
            //label: data[i].currencySymbol,
            coinType: data[i].coinType,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            arbtoken: data[i].arbtoken,
            trc20token: data[i].trc20token,
           rptc20token: data[i].rptc20token,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
    }
  };
  const onSelect1 = async (e, option) => {
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );

    var currencydata = allCryptoref.current[indexData];

    setnewCurrency(currencydata.currencySymbol);

    // setnewCurrency(option.label);
  };
  const onSelect = async (e, option) => {
    if (option != "" && option != null && option !== undefined) {
      formValue.withAddress = null;
      setwithdrawAddress("");
      setnet_default(null);
      setwithdrawAddress(null);
      setcur_network("");
      setcurrency(option.text);
      setcointype(option.coinType);
      setnet_current("");
      getAddress();
      let indexData = allCryptoref.current.findIndex(
        (x) => x._id == option.value
      );
      validate_preview(formValueref.current);

      if (option.label == "USD") {
        showerrorToast(t("fiat-withdraw-not-allowed-by-site"));
      } else {
        if (indexData != -1) {
          var currencydata = allCryptoref.current[indexData];
          setcurrentcurrency(currencydata);
          setcurrency(currencydata);
          setcur_network([]);
          setnet_default("");
          var network_cur = {};
          var network_names = [];
          if (currencydata.currencyType == "2") {
            if (currencydata.erc20token == "1") {
              network_cur = {
                value: "ERC20",
                //label: "erc20token",
                text: "ERC20",
                key: "erc20token",
              };
              network_names.push(network_cur);
            }
            if (currencydata.arbtoken == "1") {
              network_cur = {
                value: "arbtoken",
                //label: "BEP20",
                text: "ARB",
              };
              network_names.push(network_cur);
            }
            
            if (currencydata.bep20token == "1") {
              network_cur = {
                value: "BEP20",
                //label: "bep20token",
                text: "BEP20",
                key: "bep20token",
              };
              network_names.push(network_cur);
            }
            if (currencydata.trc20token == "1") {
              network_cur = {
                value: "TRC20",
                //label: "trc20token",
                text: "TRC20",
                key: "trc20token",
              };
              network_names.push(network_cur);
            }
            setcur_network(network_names);
            // setnet_default(network_currencyref.current[0].label);
          }
          setwithdrawType(currencydata.coinType);
          // if (currencydata.coinType== "1") {
          var obj = {
            currency: currencydata.currencySymbol,
            currId: option.value,
          };

          var data = {
            apiUrl: apiService.user_balance,
            payload: obj,
          };

          var resp = await postMethod(data);
          if (resp.status) {
            setview(true);
            setBalance(resp.data);
          } else {
          }

          // } else {
          // }
        }
      }

      setwithdrawcurrencyValidate(false);
      validate_preview(formValueref.current);
    } else {
      setcurrentcurrency("");
      setcurrency("");
    }
  };
  const getbalance = async (obj) => {
    var obj = {
      currency: obj.currency_symbol,
      currId: obj.currencyId,
    };

    var data = {
      apiUrl: apiService.user_balance,
      payload: obj,
    };

    var resp = await postMethod(data);
    if (resp.status) {
      setview(true);
      setBalance(resp.data);
    } else {
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    // if (currencyref.current==""  || currencyref.current==null || currencyref.current==undefined){
    //   errors.withdrawcurrency = t("withdraw-currency-required");
    //   setwithdrawcurrencyValidate(true);
    // }

    if (
      network_currencyref.current.length > 0 &&
      currentcurrencyref.current.currencyType == "2"
    ) {
      errors.withdrawnetwork = t("withdraw-network-required");
      setwithdrawnetworkValidate(true);
    } else {
      setwithdrawnetworkValidate(false);
    }

    if (!values.amount) {
      errors.amount = t("amount-required-field");
      setamountValidate(true);
    }

    if (!values.withAddress) {
      errors.withAddress = t("destination-addresss-required-field");
      setwithAddress(true);
    }

    if (!values.tfa) {
      errors.tfa = t("2fa-required-field");
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = t("withdraw-otp-required-field");
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = t("amount-required-field");
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = t("2fa-required-field");
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = t("withdraw-otp-required-field");
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = (values) => {
    const errors = {};
    if (
      !currencyref.current ||
      currencyref.current == "" ||
      !currentcurrencyref.current ||
      currentcurrencyref.current == "" ||
      currentcurrencyref.current == null ||
      currentcurrencyref.current == undefined
    ) {
      errors.withdrawcurrency = t("withdraw-currency-required");
      setwithdrawcurrencyValidate(true);
    } else {
      setwithdrawcurrencyValidate(false);
    }
    // if ( currentcurrencyref.current.currencyType =="2") {
    // if(!network_currencyref.current || network_currencyref.current==" " || network_currencyref.current==null || network_currencyref.current==undefined){
    if (
      network_currencyref.current.length > 0 &&
      currentcurrencyref.current.currencyType == "2" &&
      !network_currentref.current
    ) {
      errors.withdrawnetwork = t("withdraw-network-required");
      setwithdrawnetworkValidate(true);
    } else {
      setwithdrawnetworkValidate(false);
    }
    // }

    if (!values.withAddress) {
      errors.withAddress = t("destination-addresss-required-field");
      setwithAddress(true);
    } else {
      setwithAddress(false);
    }
    if (!values.amount) {
      errors.amount = t("amount-required-field");
      setamountValidate(true);
    } else {
      setamountValidate(false);
    }
    if (!values.tfa) {
      errors.tfa = t("2fa-required-field");
      settfaValidate(true);
    } else {
      settfaValidate(false);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = t("withdraw-otp-required-field");
      setotpValidate(true);
    } else {
      setotpValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = t("amount-required-field");
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = t("2fa-required-field");
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = t("withdraw-otp-required-field");
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = t("2fa-required-field");
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = t("withdraw-otp-required-field");
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };

    // setSiteLoader(true);
    var getKYC = await getMethod(data);
    // setSiteLoader(false);

    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      // setkycStatus(0);
    }
  };

  const onSelect_network = async (e, option) => {
    setnet_current(option.value);
    validate_preview(formValueref.current);
  };

  const onSelect_address = async (e) => {
    setwithdrawAddress(e.target.value);
    formValue.withAddress = e.target.value;
    validate_preview(formValueref.current);
    setwithAddress(false);
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };

  const withdrawPreview = async () => {
    try {
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "EUR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      t("please-enter-greater-than") +
                        currentcurrency.minWithdrawLimit +
                        t("amount-withdraw")
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      t("please-enter-less-than") +
                        currentcurrency.maxWithdrawLimit +
                        t("amount-withdraw")
                    );
                  } else {
                    const obj = {
                      currency_symbol: currentcurrency.currencySymbol,
                      withdrawalAddress: formValue.withAddress,
                      networkType: network_currentref.current,
                      withdrawalAmount: formValue.amount,
                      // otp: formValue.tfa,
                    };
                    var data = {
                      apiUrl: apiService.fieldValidate,
                      payload: obj,
                    };
                    // var data = {
                    //   apiUrl: apiService.send_otp,
                    // };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      setCounter(120);
                      setIsResendVisible(false);
                      showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                     if(resp.message=="Withdrawal validated and OTP sent successfully via email."){
                          showsuccessToast(t('Withdrawal validated and OTP sent successfully via email.'));
                          }
                    } else {
                      if(resp.message=="Invalid user."){
                        showerrorToast(t('Invalid user.'));
                        }else if(resp.message=="Internal server error"){
                        showerrorToast(t('Internal-server-error'));
                        }  
                      // showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast(t("insufi-bal-withdraw"));
                  setbuttonLoader(false);
                }
              } else {
                showerrorToast(t("please-give-valid-withdraw-amount"));
                setbuttonLoader(false);
              }
            }
          } else {
            validate_preview(formValueref.current);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      t("please-enter-greater-than") +
                        currentcurrency.minWithdrawLimit +
                        t("amount-withdraw")
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      t("please-enter-less-than") +
                        currentcurrency.maxWithdrawLimit +
                        t("amount-withdraw")
                    );
                  } else {
                    const obj = {
                      currency_symbol: currentcurrency.currencySymbol,
                      withdrawalAddress: formValue.withAddress,
                      withdrawalAmount: formValue.amount,
                      networkType: network_currentref.current,
                      // otp: formValue.tfa,
                    };
                    var data = {
                      apiUrl: apiService.fieldValidate,
                      payload: obj,
                    };
                    // var data = {
                    //   apiUrl: apiService.send_otp,
                    // };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      setCounter(120);
                      setIsResendVisible(false);
                     if(resp.message=="Withdrawal validated and OTP sent successfully via email."){
                          showsuccessToast(t('Withdrawal validated and OTP sent successfully via email.'));
                          }
                      // showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      if(resp.message=="Invalid user."){
                        showerrorToast(t('Invalid user.'));
                        }else if(resp.message=="Internal server error"){
                        showerrorToast(t('Internal-server-error'));
                        } 
                      // showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast(t("insufficient-balance"));

                  setbuttonLoader(false);
                }
              } else {
                showerrorToast(t("please-give-valid-withdraw-amount"));
              }
            }
          }
        } else {
          showerrorToast(t("kindly-update-your-bank-details"));
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    t("please-enter-greater-than") +
                      currentcurrency.minWithdrawLimit +
                      t("amount-withdraw")
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    t("please-enter-less-than") +
                      currentcurrency.maxWithdrawLimit +
                      t("amount-withdraw")
                  );
                } else {
                  const obj = {
                    currency_symbol: currentcurrency.currencySymbol,
                    withdrawalAddress: formValue.withAddress,
                    withdrawalAmount: formValue.amount,
                    networkType: network_currentref.current,
                    // otp: formValue.tfa,
                  };
                  var data = {
                    apiUrl: apiService.fieldValidate,
                    payload: obj,
                  };
                  // var data = {
                  //   apiUrl: apiService.send_otp,
                  // };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    setCounter(120);
                    setIsResendVisible(false);
                    setbuttonLoader(false);
                    setshow_otp(true);
                    if(resp.message=="Withdrawal validated and OTP sent successfully via email."){
                    showsuccessToast(t('Withdrawal validated and OTP sent successfully via email.'));
                    }
                  } else {
                    if(resp.message=="Invalid user."){
                      showerrorToast(t('Invalid user.'));
                      }else if(resp.message=="Internal server error"){
                      showerrorToast(t('Internal-server-error'));
                      }
                  }
                }
              } else {
                showerrorToast(t("insufi-bal-withdraw"));

                setwithdrawAddress("");
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValueref.current);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    t("please-enter-greater-than") +
                      currentcurrency.minWithdrawLimit +
                      t("amount-withdraw")
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    t("please-enter-less-than") +
                      currentcurrency.maxWithdrawLimit +
                      t("amount-withdraw")
                  );
                } else {
                  // var data = {
                  //   apiUrl: apiService.send_otp,
                  // };
                  const obj = {
                    currency_symbol: currentcurrency.currencySymbol,
                    withdrawalAddress: formValue.withAddress,
                    networkType: network_currentref.current,
                    withdrawalAmount: formValue.amount,
                    // otp: formValue.tfa,
                  };
                  var data = {
                    apiUrl: apiService.fieldValidate,
                    payload: obj,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    setCounter(120);
                    setIsResendVisible(false);
                    if(resp.message=="Withdrawal validated and OTP sent successfully via email."){
                      showsuccessToast(t('Withdrawal validated and OTP sent successfully via email.'));
                      }
                        setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    if(resp.message=="Invalid user."){
                      showerrorToast(t('Invalid user.'));
                      }else if(resp.message=="Internal server error"){
                      showerrorToast(t('Internal-server-error'));
                      }
                      setbuttonLoader(false);
                  }
                 
                  
                }
              } else {
                showerrorToast(t("insufi-bal-withdraw"));

                setbuttonLoader(false);
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
            }
          }
        }
      }
    } catch (error) {}
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  t("please-enter-greater-than") +
                    currentcurrency.minWithdrawLimit +
                    t("amount-withdraw")
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  t("please-enter-less-than") +
                    currentcurrency.maxWithdrawLimit +
                    t("amount-withdraw")
                );
              } else {
                // var obj = {
                //   amount: formValue.amount,
                //   tfaCode: formValue.tfa,
                //   currency_symbol: currentcurrency.currencySymbol,
                //   currId: currentcurrency._id,
                //   withdrawOtp: formValue.withdraw_otp,
                //   tfa_status: localStorage.getItem("tfa_status"),
                // };

                // var data = {
                //   apiUrl: apiService.submitfiatWithdraw,
                //   payload: obj,
                // };

                var obj = {
                  currencyId: currentcurrency._id,
                  currency_symbol: currentcurrency.currencySymbol,
                  amount: formValue.amount,
                  otp: formValue.withdraw_otp,
                  tfa: formValue.tfa,
                  networkType: network_currentref.current,
                  withdrawalAddress: formValue.withAddress,
                };
                // return;
                var data = {
                  apiUrl: apiService.withdrawProcess,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status == true) {
                  // setCounter(120);
                  // setIsResendVisible(false);
                  getbalance(obj);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  // onSelect_address("");
                  setcurrentcurrency("");
                  setcurrency("");
                  setnet_default("");
                  setcur_network("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                  // showsuccessToast(resp.message);

                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                    showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } 
                } else {
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                      showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } else if(resp.message=="Transaction Canceled."){
                      showsuccessToast(t('Transaction Canceled.'));
                    } else if(resp.message=="Invalid OTP. Please try again."){
                      showsuccessToast(t('Invalid OTP. Please try again.'));
                    } else if(resp.message=="OTP has expired. Please initiate the withdrawal process again."){
                      showsuccessToast(t('OTP has expired. Please initiate the withdrawal process again.'));
                    } else if(resp.message=="Invalid 2FA. Please try again."){
                      showsuccessToast(t('Invalid 2FA. Please try again.'));
                    } else if(resp.message=="Invalid user."){
                      showsuccessToast(t('Invalid user.'));
                   } 
                //  / showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
            }
          } else {
            // showerrorToast(t("please-give-all-fields"));
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  t("please-enter-greater-than") +
                    currentcurrency.minWithdrawLimit +
                    t("amount-withdraw")
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  t("please-enter-less-than") +
                    currentcurrency.maxWithdrawLimit +
                    t("amount-withdraw")
                );
              } else {
                // var obj = {
                //   amount: formValue.amount,
                //   tfaCode: formValue.tfa,
                //   currency_symbol: currentcurrency.currencySymbol,
                //   currId: currentcurrency._id,
                //   withdrawOtp: formValue.withdraw_otp,
                //   tfa_status: localStorage.getItem("tfa_status"),
                // };
                // var data = {
                //   apiUrl: apiService.submitfiatWithdraw,
                //   payload: obj,
                // };
                var obj = {
                  currencyId: currentcurrency._id,
                  currency_symbol: currentcurrency.currencySymbol,
                  amount: formValue.amount,
                  otp: formValue.withdraw_otp,
                  tfa: formValue.tfa,
                  networkType: network_currentref.current,
                  withdrawalAddress: formValue.withAddress,
                };
                // return;
                var data = {
                  apiUrl: apiService.withdrawProcess,
                  payload: obj,
                };

                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status == true) {
                  getbalance(obj);

                  // setCounter(120);
                  // setIsResendVisible(false);
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setcurrentcurrency("");
                  setwithdrawAddress("");
                  // onSelect_address("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  setcurrency("");
                  setnet_default("");
                  setcur_network("");
                  //window.location.reload(false);
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                    showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } 
                } else {
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                      showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } else if(resp.message=="Transaction Canceled."){
                      showsuccessToast(t('Transaction Canceled.'));
                    } else if(resp.message=="Invalid OTP. Please try again."){
                      showsuccessToast(t('Invalid OTP. Please try again.'));
                    } else if(resp.message=="OTP has expired. Please initiate the withdrawal process again."){
                      showsuccessToast(t('OTP has expired. Please initiate the withdrawal process again.'));
                    } else if(resp.message=="Invalid 2FA. Please try again."){
                      showsuccessToast(t('Invalid 2FA. Please try again.'));
                    } else if(resp.message=="Invalid user."){
                      showsuccessToast(t('Invalid user.'));
                   }
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
            }
          } else {
            // showerrorToast(t("please-give-all-fields"));
          }
        }
      } else {
        validate_submit(formValue);
        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  t("please-enter-greater-than") +
                    currentcurrency.minWithdrawLimit +
                    t("amount-withdraw")
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  t("please-enter-less-than") +
                    currentcurrency.maxWithdrawLimit +
                    t("amount-withdraw")
                );
              } else {
                // var obj = {
                //   amount: formValue.amount,
                //   withdraw_address: formValue.withAddress,
                //   tfaCode: formValue.tfa,
                //   currency_symbol: currentcurrency.currencySymbol,
                //   currId: currentcurrency._id,
                //   network: network_currentref.current,
                //   withdrawOtp: formValue.withdraw_otp,
                //   tfa_status: localStorage.getItem("tfa_status"),
                // };
                // // return;
                // var data = {
                //   apiUrl: apiService.submitWithdraw,
                //   payload: obj,
                // };
                var obj = {
                  currencyId: currentcurrency._id,
                  currency_symbol: currentcurrency.currencySymbol,
                  amount: formValue.amount,
                  otp: formValue.withdraw_otp,
                  tfa: formValue.tfa,
                  networkType: network_currentref.current,
                  withdrawalAddress: formValue.withAddress,
                };
                // return;
                var data = {
                  apiUrl: apiService.withdrawProcess,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status == true) {
                  getbalance(obj);

                  // setCounter(120);
                  // setIsResendVisible(false);
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  // onSelect_address("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  setcurrency("");
                  setcurrentcurrency("");
                  setnet_default("");
                  setcur_network("");
                  //window.location.reload(false);
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                    showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } 
                } else {
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                      showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } else if(resp.message=="Transaction Canceled."){
                      showsuccessToast(t('Transaction Canceled.'));
                    } else if(resp.message=="Invalid OTP. Please try again."){
                      showsuccessToast(t('Invalid OTP. Please try again.'));
                    } else if(resp.message=="OTP has expired. Please initiate the withdrawal process again."){
                      showsuccessToast(t('OTP has expired. Please initiate the withdrawal process again.'));
                    } else if(resp.message=="Invalid 2FA. Please try again."){
                      showsuccessToast(t('Invalid 2FA. Please try again.'));
                    } else if(resp.message=="Invalid user."){
                      showsuccessToast(t('Invalid user.'));
                   }
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
            }
          } else {
            // showerrorToast(t("please-give-all-fields"));
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  t("please-enter-greater-than") +
                    currentcurrency.minWithdrawLimit +
                    t("amount-withdraw")
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  t("please-enter-less-than") +
                    currentcurrency.maxWithdrawLimit +
                    t("amount-withdraw")
                );
              } else {
                var obj = {
                  currencyId: currentcurrency._id,
                  currency_symbol: currentcurrency.currencySymbol,
                  amount: formValue.amount,
                  otp: formValue.withdraw_otp,
                  tfa: formValue.tfa,
                  networkType: network_currentref.current,
                  withdrawalAddress: formValue.withAddress,
                };
                // return;
                var data = {
                  apiUrl: apiService.withdrawProcess,
                  payload: obj,
                };
                // var obj = {
                //   amount: formValue.amount,
                //   withdraw_address: formValue.withAddress,
                //   tfaCode: formValue.tfa,
                //   currency_symbol: currentcurrency.currencySymbol,
                //   currId: currentcurrency._id,
                //   network: network_currentref.current,
                //   withdrawOtp: formValue.withdraw_otp,
                //   tfa_status: localStorage.getItem("tfa_status"),
                // };
                // // return;
                // var data = {
                //   apiUrl: apiService.submitWithdraw,
                //   payload: obj,
                // };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status == true) {
                  getbalance(obj);
                  // setCounter(120);
                  // setIsResendVisible(false);
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  // onSelect_address("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  setcurrency("");
                  setcurrentcurrency("");
                  setnet_default("");
                  setcur_network("");
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                    showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } 
                } else {
                  if(resp.message=="Transaction is being processed. Please wait for confirmation.."){
                      showsuccessToast(t('Transaction is being processed. Please wait for confirmation..'));
                    } else if(resp.message=="Transaction Canceled."){
                      showsuccessToast(t('Transaction Canceled.'));
                    } else if(resp.message=="Invalid OTP. Please try again."){
                      showsuccessToast(t('Invalid OTP. Please try again.'));
                    } else if(resp.message=="OTP has expired. Please initiate the withdrawal process again."){
                      showsuccessToast(t('OTP has expired. Please initiate the withdrawal process again.'));
                    } else if(resp.message=="Invalid 2FA. Please try again."){
                      showsuccessToast(t('Invalid 2FA. Please try again.'));
                    } else if(resp.message=="Invalid user."){
                      showsuccessToast(t('Invalid user.'));
                   }
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast(t("please-give-valid-withdraw-amount"));
            }
          }
        }
      }
    } catch (error) {}
  };

  const [newCurrency, setnewCurrency] = useState("");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();

  const getSiteSettings = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setWithdrawStatus(resp.data.withdrawStatus);
      }
    } catch (err) {}
  };

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];

        if (element.currency) {
          var dropData = {
            value: element.address,
            //label: element.currency,
            key: element.currency,
            text: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    if (newAddres == "") {
      setnewAddresErr(true);
    } else if (newCurrency == "") {
      setnewCurrencyErr(true);
      setnewAddresErr(false);
    } else {
      setnewCurrencyErr(false);
      var obj = {
        Address: newAddres,
        currency: newCurrency,
        network: newNetwork,
      };

      var data = {
        apiUrl: apiService.Addaddress,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        showsuccessToast(resp.message);
        setnewAddres("");
        setaddresshide("Deactive");
        setnewCurrency("");
        setnewNetwork("");
        getAddress();
      } else {
        showerrorToast(resp.message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast(t("copied-success"));
  };

  const { t } = useTranslation();

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoaderref.current === true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <main className="dashboard_main h-100">
            <div className="container-fluid">
              <div className="row vh-100">
                <div className="col-lg-2 col-md-0 p-0">
                  <Side_bar />
                </div>

                <div className="col-lg-10 col-md-12">
                  <div className="pad-l-r">
                    <section className="asset_section pad-r-none pad-l-none">
                    {siteLoaderref.current === true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
                      withdrawStatusref.current == "Active" ? (
                        kycStatusref.current === 1 ? (
                          <>
                            <div className="row">
                              <div className="identification_title pad-l-none">
                                <div className="withdraw_title">
                                  {t("withdrawal")}
                                </div>
                              </div>

                              <div className="col-lg-7">
                                <div className="deposit border-rg mt-5">
                                  <div className="form_div">
                                    <div className="sides">
                                      <div className="w-100 rights">
                                        <h4>
                                          {" "}
                                          <span className="deposit-num">1</span>
                                          {t("choose-token-withdrawal")}
                                        </h4>
                                        <h6>{t("token")}</h6>
                                        <Dropdown
                                          placeholder={t("select-coin")}
                                          fluid
                                          className="dep-drops"
                                          selection
                                          options={allCurrencyref.current}
                                          onChange={onSelect}
                                          defaultValue={
                                            currencyref.current == "" ||
                                            currencyref.current == null ||
                                            currencyref.current == undefined
                                              ? ""
                                              : currencyref.current
                                          }
                                          isSearchable={true}
                                          disabled={show_otpref.current == true}
                                        />
                                        {withdrawcurrencyValidate == true ? (
                                          <p className="errorcss withdraw-errcss">
                                            {" "}
                                            {
                                              validationnErr.withdrawcurrency
                                            }{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {show_otpref.current == false &&
                                  currentcurrencyref.current.currencyType ==
                                    "2" ? (
                                    <div className="form_div ">
                                      <h4>
                                        {" "}
                                        <span className="deposit-num">
                                          2
                                        </span>{" "}
                                        {t("choose-network")}
                                      </h4>
                                      <h6>{t("network")}</h6>
                                      <Dropdown
                                        placeholder={t("select-network")}
                                        fluid
                                        className="dep-drops"
                                        selection
                                        options={network_currencyref.current}
                                        onChange={onSelect_network}
                                        defaultValue={net_defaultref.current}
                                        isSearchable={true}
                                        disabled={show_otpref.current == true}

                                        // onChange={(o) =>
                                        //   onSelect(o, "fromTab")
                                        // }
                                        // onChange={handleOnChange_from}
                                      />
                                      {withdrawnetworkValidateref.current ==
                                      true ? (
                                        <p className="errorcss withdraw-errcss">
                                          {" "}
                                          {validationnErr.withdrawnetwork}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form_div ">
                                    <h4>
                                      {currentcurrencyref.current
                                        .currencyType == "2" ? (
                                        <span className="deposit-num">3</span>
                                      ) : (
                                        <span className="deposit-num">2</span>
                                      )}
                                      {t("withdrawal-to")}
                                    </h4>
                                    <h6>{t("address")}</h6>
                                    <input
                                      type="text"
                                      value={
                                        withdrawAddressref.current == "" ||
                                        withdrawAddressref.current == null ||
                                        withdrawAddressref.current == undefined
                                          ? ""
                                          : withdrawAddressref.current
                                      }
                                      maxLength={60}
                                      onChange={onSelect_address}
                                      onKeyDown={handlekeydown}
                                      disabled={show_otpref.current == true}
                                      placeholder={t("enter-address")}
                                      fluid
                                      className="dep-drops"
                                    />

                                    {withAddressValidate == true ? (
                                      <p className="errorcss withdraw-errcss">
                                        {" "}
                                        {validationnErr.withAddress}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* {show_otpref.current == false ? ( */}
                                  <div className="form_div">
                                    <h4>
                                      {currentcurrencyref.current
                                        .currencyType == "2" ? (
                                        <span className="deposit-num">4</span>
                                      ) : (
                                        <span className="deposit-num">3</span>
                                      )}{" "}
                                      {t("withdrawal-amount")}
                                    </h4>
                                    <div className="with_new_max">
                                    <h6>{t("amount")}</h6>
                                    {/* <p onClick={caluculateMax}>{t("Max")}</p> */}
                                    </div>
                                    
                                    <div className="withdraw_new_maxinp">
                                    <input
                                      type="text"
                                      id="numberInput"
                                      maxLength={8}
                                      autoComplete="off"
                                      name="amount"
                                      value={amount}
                                      disabled={show_otpref.current == true}
                                      onChange={handleChange}
                                      placeholder={t("enter-amount")}
                                      className="dep-drops"
                                      onKeyDown={(evt) => {
                                        // Prevent non-numeric characters and symbols
                                        if (
                                          !(
                                            (
                                              (evt.key >= "0" &&
                                                evt.key <= "9") || // Allow number keys
                                              evt.key === "." || // Allow decimal point
                                              evt.key === "Backspace" || // Allow backspace
                                              evt.key === "Delete" || // Allow delete
                                              evt.key === "ArrowLeft" || // Allow left arrow key
                                              evt.key === "ArrowRight" || // Allow right arrow key
                                              evt.key === "Tab"
                                            ) // Allow tab key
                                          )
                                        ) {
                                          evt.preventDefault();
                                        }
                                      }}
                                      onInput={(evt) => {
                                        // Prevent more than one decimal point
                                        if (
                                          evt.target.value.split(".").length > 2
                                        ) {
                                          evt.target.value =
                                            evt.target.value.slice(0, -1);
                                        }
                                      }}
                                    />
                                    <p onClick={caluculateMax}>{t("Max")}</p>
                                    </div>

                                    {amountValidate == true ? (
                                      <p className="errorcss withdraw-errcss">
                                        {" "}
                                        {validationnErr.amount}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* ) : (
                                ""
                              )} */}

{show_otpref.current == true ? (

                                  localStorage.getItem("tfa_status") == 0 ||
                                  1 ? (
                                    <>
                                      <div
                                        // className="form_div boder-none"
                                        className={`${
                                          show_otpref.current == false
                                            ? "form_div boder-none"
                                            : "form_div boder-vi"
                                        }`}
                                      >
                                        <h4>
                                          {" "}
                                          {currentcurrencyref.current
                                            .currencyType == "2" ? (
                                            <span className="deposit-num">
                                              5
                                            </span>
                                          ) : (
                                            <span className="deposit-num">
                                              4
                                            </span>
                                          )}{" "}
                                          {t("verification")}
                                        </h4>
                                        <h6>2FA</h6>

                                        <input
                                          placeholder={t("enter-2fa")}
                                          type="text"
                                          id="numberInput"
                                          min="0"
                                          maxLength={6}
                                          name="tfa"
                                          autocomplete="off"
                                          value={tfa}
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(
                                              evt.key
                                            ) && evt.preventDefault()
                                          }
                                          onInput={(e) => {
                                            e.target.value =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              ); // Allows only numbers
                                          }}
                                          onChange={handleChange}
                                          className="dep-drops"
                                        />

                                        {/* <input
                                          placeholder={t("enter-2fa")}
                                          type="text"
                                          id="numberInput"
                                          min="0"
                                          maxLength={6} 
                                          name="tfa"
                                          autocomplete="off"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                            evt.preventDefault()
                                          }
                                          onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                                          }}
                                          onChange={handleChange}
                                          className="dep-drops"
                                        /> */}
                                        <div>
                                          {tfaValidate == true ? (
                                            <p className="errorcss withdraw-errcss text-danger">
                                              {" "}
                                              {validationnErr.tfa}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )
                                  ):""}
{show_otpref.current == true ? (
                                    <div>
                                      <div className="form_div boder-none">
                                        <h4>
                                          {currentcurrencyref.current
                                            .currencyType == "2" ? (
                                            <span className="deposit-num">
                                              6
                                            </span>
                                          ) : (
                                            <span className="deposit-num">
                                              5
                                            </span>
                                          )}{" "}
                                          {t("withdraw-otp")}
                                        </h4>
                                        <h6>OTP</h6>
                                        <input
                                          type="text"
                                          autocomplete="off"
                                          placeholder={t("enter-withdraw-otp")}
                                          name="withdraw_otp"
                                          value={withdraw_otp}
                                          maxLength={4}
                                          onInput={(e) => {
                                            e.target.value =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              ); // Allows only numbers
                                          }}
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(
                                              evt.key
                                            ) && evt.preventDefault()
                                          }
                                          onChange={handleChange}
                                          className="dep-drops"
                                        />

                                        {otpValidate == true ? (
                                          <p className="text-danger textleft">
                                            {" "}
                                            {validationnErr.withdraw_otp}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <div className="foot text-right">
                                          <span className="textright">
                                            {t("didnt-receive-code")}{" "}
                                            <a>
                                              {isResendVisible ? (
                                                <span
                                                  onClick={handleResend}
                                                  className="cursor-pointer"
                                                >
                                                  <a className="textgreen">
                                                    {" "}
                                                    {t("resend")}
                                                  </a>
                                                </span>
                                              ) : (
                                                <span className="textgreen">
                                                  {counter}s
                                                </span>
                                              )}
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div>
                                    <div className="fees_content">
                                      <h4>{t("balance")}</h4>
                                      <p>
                                        {balanceref.current.balance
                                          ? balanceref.current.balance.toFixed(
                                              6
                                            )
                                          : "0.0"}
                                      </p>
                                    </div>
                                    <div className="fees_content">
                                      <h4>{t("fees")}</h4>
                                      <p>
                                        {currentcurrencyref.current.withdrawFee
                                          ? currentcurrencyref.current
                                              .withdrawFee
                                          : "0.0"}{" "}
                                        %
                                      </p>
                                    </div>
                                    <div className="fees_content">
                                      <h4>{t("min-withdraw")}</h4>
                                      <p>
                                        {currentcurrencyref.current
                                          .minWithdrawLimit
                                          ? currentcurrencyref.current
                                              .minWithdrawLimit
                                          : "0.0"}
                                      </p>
                                    </div>
                                    <div className="fees_content">
                                      <h4>{t("max-withdraw")}</h4>
                                      <p>
                                        {currentcurrencyref.current
                                          .maxWithdrawLimit
                                          ? currentcurrencyref.current
                                              .maxWithdrawLimit
                                          : "0.0"}
                                      </p>
                                    </div>
                                  </div>
                                  {buttonLoader == false ? (
                                    <div className="sumbit_btn">
                                      {localStorage.getItem("tfa_status") ==
                                      0 ? (
                                        <button
                                          onClick={() =>
                                            nav_page("/security_2fa")
                                          }
                                        >
                                          {t("enable-2fa")}
                                        </button>
                                      ) : show_otpref.current == true ? (
                                        <button
                                          onClick={() => withdrawSubmit()}
                                        >
                                          {t("submit")}
                                        </button>
                                      ) : show_otpref.current == false ? (
                                        <button
                                          onClick={() => withdrawPreview()}
                                        >
                                          {t("next")}
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    <div className="sumbit_btn">
                                      <button>{t("loading")}...</button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-5">
                                <div>
                                  <div className="container">
                                    <div className="deposit-imp-notes mt-5">
                                      <div className="imp-notes-title">
                                        <span>
                                          <img
                                            src={ICON}
                                            alt="warn-icon"
                                            className="deposit-imp-icon"
                                          />
                                        </span>
                                        <p>{t("imp-notes")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>
                                          {t("withdraw-double-des-check")}
                                        </h6>
                                        <p>
                                          {t(
                                            "withdraw-double-des-check-content"
                                          )}
                                        </p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>
                                          {t("verify-withdrawal-details")}
                                        </h6>
                                        <p>
                                          {t(
                                            "verify-withdrawal-details-content"
                                          )}
                                        </p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("security")}</h6>
                                        <p>{t("withdraw-security-content")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("network-verification")}</h6>
                                        <p>
                                          {t("network-verification-content")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="dashboard_table">
                              <div className="staking-flex dash_assets">
                                <h5 className="opt-title">
                                  {t("recent-withdraw")}
                                </h5>
                                <Link to="/withdrawHistory">
                                  <div className="ref_view_main">
                                    <span className="invite_text">
                                      {t("view-all")}
                                    </span>
                                    <i
                                      class="ri-arrow-right-s-line"
                                      style={{ color: "#33C2B9" }}
                                    ></i>
                                  </div>
                                </Link>
                              </div>
                              <div className="table-responsive table-cont dash_table_content">
                                <table className="table ">
                                  <thead>
                                    <tr className="stake-head withdraw-head font-satoshi">
                                      <th> {t("date")}</th>
                                      {/* <th className="table_center_text">
                                        {t("time")}
                                      </th> */}
                                      <th className="table_center_text">
                                        {t("currency")}
                                      </th>
                                      <th className="table_center_text">
                                        {t("amount")}
                                      </th>
                                      <th className="table_center_text">
                                        {t("transaction-id")}
                                      </th>
                                      <th className="table_action">
                                        {t("status")}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {withdrawHistory &&
                                    withdrawHistory.length > 0 ? (
                                      withdrawHistory
                                        .slice(0, 5)
                                        .map((item, i) => {
                                          return (
                                            <tr>
                                              {/* <td className="table-flex">
                                    <img
                                      src={require(`../assets/${options.optImg}`)}
                                      alt=""
                                    />
                                    <div className="table-opt-name">
                                      <h4 className="opt-name font-satoshi font_14">
                                        {options.optName}
                                      </h4>
                                      <h3 className="opt-sub font-satoshi font_14">
                                        {options.optSub}
                                      </h3>
                                    </div>
                                  </td> */}
                                              <td className="opt-term font-satoshi font_14 px-4">
                                                {Moment(item.created_at).format(
                                                  "lll"
                                                )}
                                              </td>
                                              {/* <td className="opt-term font-satoshi font_14 table_center_text">
                                                {Moment(item.created_at).format(
                                                  "HH:mm:ss"
                                                )}
                                              </td> */}
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {item.currency}
                                              </td>
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {parseFloat(
                                                  item.amount
                                                ).toFixed(4)}
                                              </td>
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {item.txn_id
                                                  ? item.txn_id.substring(
                                                      0,
                                                      10
                                                    ) + "... "
                                                  : "----"}
                                                <i
                                                  class="fa fa-clone"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    copy(item.txn_id)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                ></i>
                                              </td>
                                              <td className="opt-term font-satoshi font_14 px-2 table_center_last text-success">
                                                {item.status !== "Pending" ? (
                                                  <span className="text-green">
                                                    {t("completed")}
                                                  </span>
                                                ) : item.status ==
                                                  "Cancelled" ? (
                                                  <span className="text-green">
                                                    {t("Cancelled")}
                                                  </span>
                                                ) : (
                                                  <span className="text-red">
                                                    {t("pending")}
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="text-center py-5"
                                        >
                                          <div className="empty_data">
                                            <div className="empty_data_img">
                                              <img
                                                src={require("../assets/No-data.png")}
                                                width="100px"
                                              />
                                            </div>
                                            <div className="no_records_text">
                                              {t("no-records-found")}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {/* <div className="pagination">
                          <Stack spacing={2}>
                            <Pagination count={4} size="small" />
                          </Stack>
                        </div> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/*/TODO: withdraw - KYC Verification required box*/}
                            <div className="row ">
                              <div className="identification_title pad-l-none d-flex justify-content-between align-items-center">
                                <div className="withdraw_title">
                                  {t("withdrawal")}
                                </div>
                                <Link to="" className="deposit--btn">
                                  {t("fiat-withdrawal")}{" "}
                                  <span>
                                    <i class="fa-solid fa-chevron-right"></i>
                                  </span>
                                </Link>
                              </div>

                              <div className="col-lg-7">
                                <div className="deposit-identity-box mt-5 h-100">
                                  <div className="dep-kyc">
                                    <div className="dep-kyc-head">
                                      <img
                                        src={ICON}
                                        alt="warn-icon"
                                        className="deposit-imp-icon"
                                      />
                                      {type == "Corporates" ? (
                                        <h6>
                                          {t("kyb-verification-required")}
                                        </h6>
                                      ) : (
                                        <h6>
                                          {t("kyc-verification-required")}
                                        </h6>
                                      )}
                                    </div>
                                    {type == "Corporates" ? (
                                      <p>
                                        {" "}
                                        {t(
                                          "kyb-verification-required-content"
                                        )}{" "}
                                      </p>
                                    ) : (
                                      <p>
                                        {" "}
                                        {t(
                                          "kyc-verification-required-content"
                                        )}{" "}
                                      </p>
                                    )}
                                    <div>
                                      <img
                                        src={require("../assets/deposit-id.webp")}
                                        alt="Verify kyc"
                                        className="before_kyc_depo withdraw-p-l-24"
                                      />
                                    </div>
                                    <p className="mt-4">
                                      {t("please-click-button")}
                                    </p>
                                    <div className="withdraw-p-l-24">
                                      {type == "Corporates" ? (
                                        <Link to="/kyb">
                                          <button className="dep-kyc-btn  w-100 mb-2">
                                            {t("verify-now")}
                                          </button>
                                        </Link>
                                      ) : (
                                        <Link to="/kyc">
                                          <button className="dep-kyc-btn  w-100 mb-2">
                                            {t("verify-now")}
                                          </button>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-5">
                                <div>
                                  <div className="container">
                                    <div className="deposit-imp-notes mt-5 max-lg-t-3">
                                      <div className="imp-notes-title">
                                        <span>
                                          <img
                                            src={ICON}
                                            alt="warn-icon"
                                            className="deposit-imp-icon"
                                          />
                                        </span>
                                        <p>{t("imp-notes")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>
                                          {t("withdraw-double-des-check")}
                                        </h6>
                                        <p>
                                          {t(
                                            "withdraw-double-des-check-content"
                                          )}
                                        </p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>
                                          {t("verify-withdrawal-details")}
                                        </h6>
                                        <p>
                                          {t(
                                            "verify-withdrawal-details-content"
                                          )}
                                        </p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("security")}</h6>
                                        <p>{t("withdraw-security-content")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("network-verification")}</h6>
                                        <p>
                                          {t("network-verification-content")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </>
                        )
                      ) : (
                        <>
                          {/*/TODO: withdraw - Temporary Unavailable */}
                          <div className="row ">
                            <div className="identification_title pad-l-none d-flex justify-content-between align-items-center">
                              <div className="withdraw_title">
                                {" "}
                                {t("withdrawal")}
                              </div>
                              <Link to="" className="deposit--btn">
                                {t("fiat-withdrawal")}{" "}
                                <span>
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </div>

                            <div className="col-lg-7">
                              <div className="deposit-identity-box mt-5 h-100">
                                <div className="dep-kyc">
                                  <div className="dep-kyc-head">
                                    <img
                                      src={require("../assets/dep-warn.png")}
                                      alt="warn-icon"
                                      className="deposit-imp-icon"
                                    />
                                    <h6>{t("withdrawal-temp-unavail")}</h6>
                                  </div>
                                  <p>{t("withdrawal-temp-unavail-content")}</p>
                                  <p className="my-3">
                                    {t("estimated-time-to-resolution: ")}{" "}
                                    <span className="text-primary">
                                      00:00:00
                                    </span>
                                  </p>
                                  <div>
                                    <img
                                      src={require("../assets/dep-unavail.png")}
                                      alt="Verify kyc"
                                      className="before_kyc_depo withdraw-p-l-24"
                                    />
                                  </div>
                                  <p className="mt-4">
                                    {t("thank-you-for-your-patience")}
                                  </p>
                                  <div className="withdraw-p-l-24">
                                    <Link to="/dashboard">
                                      <button className="dep-kyc-btn  w-100 mb-2">
                                        {t("back-to-home")}
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-5">
                              <div>
                                <div className="container">
                                  <div className="deposit-imp-notes mt-5 max-lg-t-3">
                                    <div className="imp-notes-title">
                                      <span>
                                        <img
                                          src={ICON}
                                          alt="warn-icon"
                                          className="deposit-imp-icon"
                                        />
                                      </span>
                                      <p>{t("imp-notes")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("withdraw-double-des-check")}</h6>
                                      <p>
                                        {t("withdraw-double-des-check-content")}
                                      </p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("verify-withdrawal-details")}</h6>
                                      <p>
                                        {t("verify-withdrawal-details-content")}
                                      </p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("security")}</h6>
                                      <p>{t("withdraw-security-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("network-verification")}</h6>
                                      <p>{t("network-verification-content")}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default Dashboard;
