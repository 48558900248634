import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FP = () => {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [OTP, setOTP] = useState("");
  const [activeStatus, seractiveStatus] = useState(false);

  const navigate = useNavigate();

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [validationnErr1, setvalidationnErr1] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const { t } = useTranslation();

  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    e.preventDefault();
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  
  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if ( e.target.value.startsWith(" ")) {
      return; // Prevent setting the value if it starts with a space
    }else{
    let formData1 = { ...formValue1, ...{ [name]: value } };
    setFormValue1(formData1);
    validate1(formData1);
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t("email-required-field");
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = t("invalid-email-address");
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email = t(
        "invalid-email-address-email-must-contain-atleast-one-char"
      );
      setemailValidate(true);
    } 
    // else if (values.email.length > 254) {
    //   errors.email = t("email-address-too-long");
    //   setemailValidate(true);
    // } 
    else {
      setemailValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (!values.password) {
      errors1.password = t("password-required-field");
      setpasswordValidate(true);
    } else if (values.password.length < 12 || values.password.length > 25) {
      setpasswordValidate(true);
      errors1.password = t("password-should-not-below-5-above-25-char");
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = t("please-enter-at-least-lower-char");
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = t("please-enter-at-least-upper-char");
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors1.password = t("please-enter-at-least-one-special-char");
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors1.confirmPassword = t("confirm-password-required-field");
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors1.confirmPassword = t("password-confirm-password-does-not-match");
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr1(errors1);
    return errors1;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0 && isEmailSubmitted) {
      setIsResendVisible(true);
    }
    return () => clearTimeout(timer);
  }, [counter, isEmailSubmitted]);

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    validate(formValue);
    try {
      if (formValue.email != "" && emailValidateref.current == false) {
        var obj = {
          email: formValue.email,
        };
        var data = {
          apiUrl: apiService.forgotemailotp,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          // showSuccessToast(resp.Message);
          if(resp.Message=="Email verified, OTP sent to your email"){
            showSuccessToast(t("Email-verified-OTP"))
          }
          setIsEmailSubmitted(true);
          setCounter(60);
          setIsResendVisible(false);
        } else {
          if(resp.Message=="Something Went Wrong. Please Try Again later"){
            showErrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
          }else if(resp.Message=="Email not verified, please try later"){
            showErrorToast(t("Email-not-verified-please-try-later"))
          }else if (resp.Message =="Email id not found"){
            showErrorToast(t("Email-id-not-found"))
          }else if (resp.Message =="Internal server error"){
            showErrorToast(t("Internal-server-error"))
          }
          // showErrorToast(resp.message);
        }
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (OTP !== "") {
        var obj = {
          emailOtp: OTP,
          email: formValue.email,
        };

        var data = {
          apiUrl: apiService.forgototpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          // showSuccessToast(resp.Message);
          if(resp.Message=="OTP verified successfully, Reset you password."){
            showSuccessToast(t("OTP verified successfully, Reset you password."))
          }
          seractiveStatus(true);
        } else {
          // showErrorToast(resp.Message);
          if(resp.Message=="OTP not verified, Please try again later."){
            showErrorToast(t("OTP not verified, Please try again later."))
          }else if(resp.Message=="OTP expired"){
            showErrorToast(t("OTP expired"))
          }else if(resp.Message=="User not found"){
            showErrorToast(t("User-not-found"))
          }else if(resp.Message=="Internal server error"){
            showErrorToast(t("Internal server error"))
          }
        }
      } else {
        showErrorToast(t("enter-otp"));
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };
  const [resendLoader, setResendLoader] = useState(false);
  const handleResend = async (e) => {
    e.preventDefault();
    validate(formValue);
    try {
      if (formValue.email != "" && emailValidateref.current == false) {
        var obj = {
          email: formValue.email,
        };
        var data = {
          apiUrl: apiService.forgotemailotp,
          payload: obj,
        };
        setResendLoader(true);
        var resp = await postMethod(data);
        setResendLoader(false);
        setCounter(60);
        setIsResendVisible(false);
        if (resp.status == true) {
          // showSuccessToast(resp.Message);
          if(resp.Message=="Email verified, OTP sent to your email"){
            showSuccessToast(t("Email-verified-OTP"))
          }
          setIsEmailSubmitted(true);
          setCounter(60);
          setIsResendVisible(false);
        } else {
          if(resp.Message=="Something Went Wrong. Please Try Again later"){
            showErrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
          }else if(resp.Message=="Email not verified, please try later"){
            showErrorToast(t("Email-not-verified-please-try-later"))
          }else if (resp.Message =="Email id not found"){
            showErrorToast(t("Email-id-not-found"))
          }else if (resp.Message =="Internal server error"){
            showErrorToast(t("Internal-server-error"))
          }
          // showErrorToast(resp.message);
        }
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false
    ) {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        email: formValue.email,
      };

      var data = {
        apiUrl: apiService.resetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue1(initialFormValue1);
      if (resp.status == true) {
        // showSuccessToast(resp.Message);
        if(resp.Message=="Password changed successfully"){
          showSuccessToast(t("Password-changed-successfully"))
        }
        navigate("/login");
      } else {
       if(resp.Message=="Something Went Wrong. Please Try Again later"){
          showErrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
        }else if(resp.Message =="User not found"){
          showErrorToast("User-not-found")
        }else if(resp.Message =="Password and confirm password does not match"){
          showErrorToast("Password and confirm password does not match")
        }else if(resp.Message =="Internal server error"){
          showErrorToast("Internal-server-error")
        }
        
        // showErrorToast(resp.Message);
      }
    }
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0 && isEmailSubmitted) {
      setIsResendVisible(true);
    }
    return () => clearTimeout(timer);
  }, [counter, isEmailSubmitted]);

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>

      <div>
        <div className="Verification fp-pt">
          <div className="container">
            <div>
              <Link to="/login">
                <h6>
                  <i className="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("login")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  {activeStatus == false ? (
                    <>
                      <span className="heading">{t("forget-password")}</span>
                      {!isEmailSubmitted ? (
                        <div className="notify">
                          {t("forget-notify-content1")}
                        </div>
                      ) : (
                        <div className="notify">
                          {t("forget-notify-content2")}
                        </div>
                      )}

                      <div className="input-groups icons">
                        <h6 className="input-label">{t("email")}</h6>
                        {isEmailSubmitted ==true ?
                         <input
                         type="email"
                         name="email"
                         value={email}
                         maxLength={250}
                         onChange={handleChange}
                         disabled
                         className="input-field"
                         placeholder={t("enter-email")}
                       />
                        : <input
                        type="email"
                        name="email"
                        value={email}
                        maxLength={250}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        className="input-field"
                        placeholder={t("enter-email")}
                      />
                        }
                        {emailValidateref.current == true ? (
                          <p className="errorcss"> {validationnErr.email} </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {isEmailSubmitted && (
                        <div className="input-groups icons">
                          <h6 className="input-label">
                            {t("verification-code")}
                          </h6>
                          <input
                            type="text"
                            id="numberInput"
                            min="0"
                            maxLength={4}
                            name="OTP"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Allows only numbers
                            }}
                            value={OTP}
                            onChange={(e) => setOTP(e.target.value)}
                            className="input-field"
                            placeholder={t("enter-code")}
                          />
                        </div>
                      )}
                      <div className="Submit my-4">
                        {!isEmailSubmitted ? (
                          <>
                            {buttonLoader == false ? (
                              <button onClick={handleEmailSubmit}>
                                {t("submit")}
                              </button>
                            ) : (
                              <button>{t("loading")} ...</button>
                            )}
                          </>
                        ) : (
                          <>
                            {buttonLoader == false ? (
                              <button onClick={handleResetPassword}>
                                {t("reset-password")}
                              </button>
                            ) : (
                              <button>{t("loading")} ...</button>
                            )}
                          </>
                        )}
                      </div>
                      {isEmailSubmitted && (
                        <div className="foot">
                          <p>
                            {t("didnt-receive-code")}{" "}

                            {resendLoader == false ?
                             isResendVisible ? (
                              <span
                                onClick={handleResend}
                                className="cursor-pointer textfidexcolor"
                              >
                                <a> {t("resend")}</a>
                              </span>
                            ) : (
                              <span className=" textfidexcolor">{counter}s</span>
                            )
                            :
                            <span className=" textfidexcolor" >{t("loading")} ...</span>
                            }
                           
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="heading">{t("reset-password")}</span>

                      <div className="notify">
                        {t("continue-create-new-pass")}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label"> {t("new-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange1}
                            className="input-field"
                            placeholder={t("enter-new-pass")}
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {passwordValidate == true ? (
                          <p className="errorcss">
                            {" "}
                            {validationnErr1.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">
                          {t("confirm-new-password")}
                        </h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange1}
                            className="input-field"
                            placeholder={t("re-enter-new-password")}
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {confirmPasswordValidate == true ? (
                          <p className="errorcss">
                            {" "}
                            {validationnErr1.confirmPassword}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="Submit my-4" onClick={formSubmitchange}>
                        {buttonLoader == false ? (
                          <button>{t("reset-password")}</button>
                        ) : (
                          <button>{t("loading")} ...</button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FP;
