import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  // const initialFormValue = {
  //   verifiedSteps: ""
  // };
  // const [formValue, setFormValue] = useState(initialFormValue);


  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const submit = async (value) => {
    try {
      if (value === "Submit") {
        var obj = {
          verifiedSteps: "Step-6",
        };
        var data = {
          apiUrl: apiService.kycSubmition,
          payload: obj,
      };
        var resp = await postMethod(data);
        if (resp.status == true) {
          if(resp.Message=="Successfully Uploaded to the KYC details, wait for admin verification"){
            showsuccessToast(t("Successfully Uploaded to the KYC details, wait for admin verification"))
          }
          navigate("/dashboard");
        } else {
          if(resp.Message=="Internal server error"){
            showerrorToast(t("Internal-server-error"))
          }else if(resp.Message=="Something Went Wrong. Please Try Again later"){
            showerrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
          }else if (resp.Message=="Oops , Please verify the previous steps of verification"){
            showerrorToast(t("Oops , Please verify the previous steps of verification"))
          }
        }
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main information_end">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation5">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("back")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no_6">6 </span>
                    {t("certification")}
                  </h3>
                  <div className="holder_checkbox_content">
                    {t("buy-clicking-submit")}
                  </div>
                  <div className="holder_checkbox_content">
                    {t("i-agree-notify-FIDEX ")}
                  </div>
                  <div className="Submit next_subit_buttons my-4">
                    <button
                      className="save_button"
                      onClick={() => submit("Save for Later")}
                    >
                      {t("safe-for-later")}
                    </button>
                    <button
                      className="subit_button"
                      onClick={() => submit("Submit")}
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
