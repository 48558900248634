import React from "react";
import Header from "./Header";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const Anti = () => {
  const navigate = useNavigate();

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const { t } = useTranslation();

  const { oldpassword, password, confirmPassword } = formValue;
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
    useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword == "") {
      errors.oldpassword = t("old-password-required");
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = t("password-required-security-change");
    } else if (values.password.length < 12 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = t("new-password-require-shouldnot-below-5");
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-lower-char-sec-change");
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-upper-char-sec-change");
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-one-digit-char-sec-change");
    } else if (values.password == values.oldpassword) {
      errors.password = t("old-password-new-password-shouldnot-same");
      setpasswordValidate(true);
    }else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-one-special-char");
    }else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = t("confirm-password-required");
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = t("password-confirm-password-does-not-match");
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const [buttonStatus, setButtonstatus] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      // setSiteLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      // setSiteLoader(false);
      if (resp.status) {
        // showSuccessToast(resp.Message);
        if(resp.Message=="Password changed successfully"){
          showSuccessToast("Password changed successfully")
        } 
        localStorage.clear();
        navigate("/login");
        window.location.reload(true);
      } else {
        if(resp.Message=="Password and confirm password should not be same !"){
          showErrorToast("Password and confirm password should not be same !")
        }else if(resp.Message=="Please fill all fields"){
          showErrorToast("Please-fill-all-fileds")
        }else if(resp.Message=="Something Went Wrong. Please Try Again later"){
          showErrorToast("Something-Went-Wrong-Please-Try-Again -later")
        }else if(resp.Message=="Internal server error"){
          showErrorToast("Internal server error")
        }else if(resp.Message=="Old password is wrong"){
          showErrorToast("Old password is wrong")
        }
        
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="anti_phishing_main enable2fa-pt">
          <div>
            <div className="Verification ">
              <div className="container">
                <div>
                  <Link to="/security">
                    <h6>
                      {" "}
                      <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                      {t("security")}
                    </h6>
                  </Link>
                  <div className="row justify-content-center cards">
                    <div className="col-lg-4">
                      <span class="heading">{t("change-login-password")}</span>

                      <div className="input-groups icons mt-4">
                        <h6 className="input-label">{t("old-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="oldpassword"
                            minLength={12}
                            maxLength={25}
                            value={oldpassword}
                            onChange={handleChange}
                                onKeyDown={handleKeyDown}
                            className="input-field"
                            placeholder={t("enter-old-password")}
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {oldpassvalidate == true ? (
                          <small className="errorcss">
                            {validationnErr.oldpassword}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">{t("new-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypenew}
                            name="password"
                            minLength={12}
                            maxLength={25}
                            value={password}
                            onChange={handleChange}
                                onKeyDown={handleKeyDown}
                            className="input-field"
                            placeholder={t("enter-new-password")}
                          />
                          {passHidnew == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHidenewP("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHidenewP("hide")}
                            ></i>
                          )}
                        </div>
                        {passwordValidate == true ? (
                          <small className="errorcss">
                            {" "}
                            {validationnErr.password}{" "}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">{t("confirm-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            minLength={12}
                            maxLength={25}
                            value={confirmPassword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="input-field"
                            placeholder={t("re-enter-your-new-password")}
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {confirmPasswordValidate == true ? (
                          <small className="errorcss">
                            {" "}
                            {validationnErr.confirmPassword}{" "}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="Submit my-4">
                        {buttonLoader == false ? (
                          <button onClick={formSubmit}>{t("confirm")}</button>
                        ) : (
                          <button> {t("loading")} ...</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Anti;
