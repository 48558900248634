import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ReferralTable = () => {
  const [swapHistory, setswapHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [siteLoader, setSiteLoader] = useState(false);
  const { t } = useTranslation();



  const timeOption = {
    timeZone: 'Europe/Madrid', // Spanish timezone
    month: 'short', // Short month (e.g., Dec)
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  };
  const formatter = new Intl.DateTimeFormat('es-ES', timeOption);

  
  useEffect(() => {
    swaphistory(1);
  }, [0]);

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setswapHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const recordPerPage = 5;

  const handlePageChange = (event,pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main h-100">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12 ">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="buy_head p-0">
                      <div className="Buycrypto_title">{t("history")}</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          {t("deposit")}
                        </Link>
                        <Link to="/withdrawHistory" className="history-links ">
                          {t("withdraw")}
                        </Link>
                        <Link to="/tradeHistory" className="history-links ">
                          {t("trade")}
                        </Link>
                        <Link to="/loginHistory" className="history-links ">
                          {t("login")}
                        </Link>
                        <Link to="/referralHistory" className="history-links">
                          {t("referral")}
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          {t("reward")}
                        </Link>
                        <Link to="/stakingHistory" className="history-links">
                          {t("staking")}
                        </Link>
                        <Link
                          to="/swapHistory"
                          className="history-links active"
                        >
                          {t("swap")}
                        </Link>
                        <Link to="/openorderHistory" className="history-links ">
                          {t("open-order-history")}
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          {t("cancel-order-history")}
                        </Link>
                        <Link
                          to="/buycryptoHistory"
                          className="history-links "
                        >
                          {t("buy-crypto-history")}
                        </Link>
                        {/* <Link to="/stoporderHistory" className="history-links ">
                          {t("stop-order-history")}
                        </Link> */}
                      </ul>
                      {/* <AssetListTable /> */}
                      {siteLoader == true ? (
                        <div className="loadercssss">
                          <Bars
                            height="80"
                            width="80"
                            color="#33c2b9"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead className="ref_table_head">
                              <tr className="stake-head font-satoshi thead-bor-bottom">
                                <th>S.No</th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("date/time")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("from-currency")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("to-currency")}{" "}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("amount")}{" "}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("receive-amount")}{" "}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("total-amount")}{" "}
                                </th>
                                <th className="opt-btn-flex table-action ">
                                  {t("fee")}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {swapHistory.length > 0 && swapHistory ? (
                                swapHistory.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="opt-percent font-satoshi font_14 px-4 pad-top-14px">
                                        {i + 1}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {/* {moment(item.createdDate).format(
                                          "lll"
                                        )} */}
                                      { formatter.format(new Date(item.createdDate))}

                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.fromCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.toCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {parseFloat(item.amount).toFixed(6)}{" "}
                                        {""} {item.fromCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        { !item.receiveAmount ?"0":parseFloat(item.receiveAmount).toFixed(6)} {""}{" "}
                                        {item.toCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {parseFloat(item.totalAmount).toFixed(
                                          6
                                        )}{" "}
                                        {item.fromCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 px-2 table_center_last pad-top-14px">
                                        {parseFloat(item.fee).toFixed(6)} {""}{" "}
                                        {item.fromCurrency}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.png")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        {t("no-records-found")}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {swapHistory && swapHistory.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(totalPage / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#33c2b9",
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ReferralTable;
