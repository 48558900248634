import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StakingTable = () => {
  const [stakestage, setstakestage] = useState("flexible");

  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const { t } = useTranslation();

  const timeOption = {
    timeZone: 'Europe/Madrid', // Spanish timezone
    month: 'short', // Short month (e.g., Dec)
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  };
  const formatter = new Intl.DateTimeFormat('es-ES', timeOption);


  useEffect(() => {
    getStakingHistory(1);
  }, [0]);
  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const [historyLoader, sethistoryLoader] = useState(false);

  const getStakingHistory = async (page) => {
    try {
      sethistoryLoader(true);
      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);
      sethistoryLoader(false);
      if (resp.status) {
        setstakeHistory(resp.data);
        settotal(resp.total);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      // if (resp.status) {
      //   showSuccessToast(resp.Message);
      //   getStakingHistory(1);
      // } else {
      //   showErrorToast(resp.Message);
      // }

      if (resp.status) {
        if(resp.Message=="Claimed successfully!"){
          showSuccessToast(t("Claimed successfully!"));
        }
        // showSuccessToast(resp.Message);
        getStakingHistory(1);
      } else {
        if(resp.Message=="Internal server error"){
          showErrorToast(t("Internal-server-error"));
        }else  if(resp.Message=="Please try again later"){
          showErrorToast(t("Please try again later"));
        }
        // showErrorToast(resp.Message);
      }
    } catch (error) {
      // showErrorToast("Please try again later");
    }
  };

  const claimNowFlexible = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapiFlexible,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        if(resp.Message=="Claimed successfully!"){
          showSuccessToast(t("Claimed successfully!"));
        }
        getStakingHistory(1);
      } else {
        if(resp.Message=="Internal server error"){
          showErrorToast(t("Internal-server-error"));
        }else  if(resp.Message=="Please try again later"){
          showErrorToast(t("Please try again later"));
        }else  if(resp.Message=="Now you cannot make claim your amount, please try  after one day"){
          showErrorToast(t("Now you cannot make claim your amount, please try  after one day"));
        }
      }
      // if (resp.status) {
      //   showSuccessToast(resp.Message);
      //   getStakingHistory(1);
      // } else {
      //   showErrorToast(resp.Message);
      // }
    } catch (error) {
      // showErrorToast("Please try again later");
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(5);
  const recordPerPage = 5;
  const pageRange = 5;
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getStakingHistory(pageNumber);
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main h-100">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12 ">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="buy_head p-0">
                      <div className="Buycrypto_title">{t("history")}</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          {t("deposit")}
                        </Link>
                        <Link to="/withdrawHistory" className="history-links ">
                          {t("withdraw")}
                        </Link>
                        <Link to="/tradeHistory" className="history-links ">
                          {t("trade")}
                        </Link>
                        <Link to="/loginHistory" className="history-links ">
                          {t("login")}
                        </Link>
                        <Link to="/referralHistory" className="history-links ">
                          {t("referral")}
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          {t("reward")}
                        </Link>
                        <Link
                          to="/stakingHistory"
                          className="history-links active"
                        >
                          {t("staking")}
                        </Link>
                        <Link to="/swapHistory" className="history-links">
                          {t("swap")}
                        </Link>
                        <Link to="/openorderHistory" className="history-links ">
                          {t("open-order-history")}
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          {t("cancel-order-history")}
                        </Link>
                        <Link
                          to="/buycryptoHistory"
                          className="history-links "
                        >
                          {t("buy-crypto-history")}
                        </Link>
                        {/* <Link to="/stoporderHistory" className="history-links ">
                        {t("stop-order-history")}
                      </Link> */}
                      </ul>
                      {historyLoader == true ? (
                        <div className="loadercssss">
                          <Bars
                            height="80"
                            width="80"
                            color="#33c2b9"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="table-responsive table-cont">
                            <table className="table">
                              <thead className="ref_table_head">
                                <tr className="stake-head stake-table-row font-satoshi thead-bor-bottom">
                                  <th>{t("package")}</th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("total-amount")}
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    APY/APR
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("type")}
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("interest-cycle")}
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("stake-date")}
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("stake-end-date")}
                                  </th>
                                  {/* <th className="opt-nowrap txt-center pad-left-23">
                                {t("next-claim-date")}
                              </th> */}
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("total-interest")}
                                  </th>
                                  <th className="opt-nowrap txt-center pad-left-23">
                                    {t("action")}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {stakeHistoryref.current &&
                                stakeHistoryref.current?.length > 0 ? (
                                  stakeHistoryref.current.map((item, i) => {
                                    var get_time = new Date(
                                      item.date
                                    ).getTime();
                                    var interest_cycle =
                                      item.type == "fixed"
                                        ? item.stakingPlan
                                        : "";

                                    var added_date =
                                      get_time +
                                      +interest_cycle * 24 * 60 * 60 * 1000;

                                    var claim_date = "";
                                    if (item.type == "fixed") {
                                      claim_date = formatter.format(new Date(item.endDate));
                                    } else {
                                      claim_date = "-";
                                    }

                                  //  var  claim_date =  formatter.format(new Date(claim_date))
                                 // moment(claim_date).format("lll");
                                    
                                    return (
                                      <tr>
                                        <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                                          <img
                                            src={item.currencyImage}
                                            width="30px"
                                          />{" "}
                                          {item.stakeCurrencsymbol}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {item.stakeAmont}{" "}
                                          {item.stakeCurrencsymbol}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {item.currentAPY}%
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {item.type == "fixed"
                                            ? t("fixed")
                                            : t("flexible")}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {item.type == "fixed"
                                            ? item.stakingPlan + " days"
                                            : "-"}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {/* {moment(item.startDate).format(
                                            "lll"
                                          )} */}
                                          {  formatter.format(new Date(item.startDate))}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                          {item.type == "fixed"
                                            ? 
                                            // moment(item.endDate).format(
                                            //     "lll"
                                            //   )
                                            formatter.format(new Date(item.endDate))
                                            : "-"}
                                        </td>
                                        <td>
                                          {item.type == "fixed" ? (
                                            <p>
                                              {parseFloat(
                                                item.totalInterest
                                              ).toFixed(6)}{" "}
                                              {item.stakeCurrencsymbol}
                                            </p>
                                          ) : (
                                            <p>
                                              {parseFloat(
                                                item.totalInterest
                                              ).toFixed(6)}{" "}
                                              {item.stakeCurrencsymbol}
                                            </p>
                                          )}
                                        </td>
                                        {/* <td>
                                      {item.type == "fixed" ? (
                                        <p>
                                          {parseFloat(
                                            item.totalInterest
                                          ).toFixed(6)}{" "}
                                          {item.stakeCurrencsymbol}
                                        </p>
                                      ) : (
                                        <p>
                                          {parseFloat(
                                            item.totalInterest
                                          ).toFixed(6)}{" "}
                                          {item.stakeCurrencsymbol}
                                        </p>
                                      )}
                                    </td> */}
                                        <td className="opt-btn-flex table_action pad-left-23">
                                          {item.type == "fixed" ? (
                                            <div className="minimum">
                                              {item.status == 1 ? (
                                                <button
                                                  className="active"
                                                  onClick={() => claimNow(item)}
                                                >
                                                  {t("claim-now")}
                                                </button>
                                              ) : item.status == 0 ? (
                                                <button className="notactive">
                                                  {" "}
                                                  {t("claim")}
                                                </button>
                                              ) : (
                                                <button className="notactive">
                                                  {" "}
                                                  {t("claimed")}
                                                </button>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="minimum">
                                              {item.status == 0 ? (
                                                <button
                                                  className="active"
                                                  onClick={() =>
                                                    claimNowFlexible(item)
                                                  }
                                                >
                                                  {t("claim-now")}
                                                </button>
                                              ) : (
                                                <button className="notactive">
                                                  {t("claimed")}
                                                </button>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={9}
                                      className="text-center py-5"
                                    >
                                      <div className="empty_data">
                                        <div className="empty_data_img">
                                          <img
                                            src={require("../assets/No-data.png")}
                                            width="100px"
                                          />
                                        </div>
                                        <div className="no_records_text">
                                          {t("no-records-found")}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {stakeHistoryref.current &&
                            stakeHistoryref.current.length > 0 ? (
                              <div className="pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={Math.ceil(total / recordPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    size="small"
                                    renderItem={(item) => (
                                      <PaginationItem
                                        slots={{
                                          previous: ArrowBackIcon,
                                          next: ArrowForwardIcon,
                                        }}
                                        {...item}
                                        sx={{
                                          "&.Mui-selected": {
                                            backgroundColor: "#33c2b9",
                                            color: "#fff",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Stack>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StakingTable;
