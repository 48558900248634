import React, { useEffect } from "react";
import Header from "./Header";
import Side_bar from "./Side_bar";
import { Dropdown } from "semantic-ui-react";
import { Button, Form, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const Swap = () => {
  const navigate = useNavigate();

  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
      getUserbalance();
      getKYCstatus();
    } else {
      setloginCheck(false);
      getCurrencyList();
    }
  }, []);

  const Movelogin = () => {
    navigate("/login");
  };
  const [siteLoader, setSiteLoader] = useState(false);
  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData] = useState("");
  const [appendToData, setappendFToData] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [swapTab, setswapTab] = useState(false);
  const [fromAmount, setfromAmount, fromAmountref] = useState(0);
  const [toAmount, settoAmount, toAmountref] = useState(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceRef] = useState(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [kycStatus, setkycStatus, kycstatusref] = useState(0);
  const [ButtonLoader, setButtonLoader] = useState(false);

  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);

  const [fromSwap, setfromSwapRef, fromSwapRef] = useState([]);
  const [toSwap, settoSwapRef, toSwapRef] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const getCurrencyList = async () => {
    try {
      var data = {
        apiUrl: apiService.getCurrencyList,
      };
      var resp = await getMethod(data);
      setSiteLoader(true);
      const transformedFromData = [];
      if (resp.status) {
        setSiteLoader(false);
        resp.currency.forEach((item) => {
          transformedFromData.push({
            value: item.currencyName,
            key: item._id,
            text: item.currencySymbol,
            image: {
              avatar: true,
              src: item.Currency_image,
            },
          });
          setToCurrencyRef(transformedFromData);
          setfromCurrencyRef(transformedFromData);
        });
      }
    } catch (Err) {}
  };

  const getUserbalance = async () => {
    var data1 = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data1);
    if (resp.status) {
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayCrypto = [];
      //var currArrayFiat = [{value: "all", label: "Select"}];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].currencySymbol) {
          var obj = {
            value: data[i].currid,
            // label: data[i].currencySymbol,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
          currArrayCrypto.push(obj);
        }
      }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayCrypto);
    } else {
    }
  };

  const setAmount = async (value, type) => {
    try {
      if (
        !isNaN(value) ||
        !value ||
        value != undefined ||
        value != null ||
        value == 0
      ) {
        if (appendFromData === "") {
          setErrorMsg(t("choose-spending-currency"));
        } else if (appendToData === "") {
          setErrorMsg(t("choose-receiving-currency"));
        } else if (value.length > 9) {
          setErrorMsg(t("enter-valid-amount"));
        } else if (value == "00000") {
          setErrorMsg(t("enter-valid-amount"));
        }
        // else if (value == "0.0000") {
        //   setErrorMsg(t("enter-valid-amount"));
        // }
        else if (value > appendFromData.currencyBalance) {
          setErrorMsg(t("insufficient-balance"));
        } else {
          console.log("=====");
          setErrorMsg("");
          type == "fromAmount" ? setfromAmount(value) : settoAmount(value);
          var obj = {
            from: appendFromData.currencySymbol,
            to: appendToData.currencySymbol,
          };
          var data = {
            apiUrl: apiService.currencyConversion,
            payload: obj,
          };
          if (fromCurrency != "" && toCurrency != "") {
            var resp = await postMethod(data);
            if (resp.status) {
              var fee = (+value * +appendFromData.swapFee) / 100;
              setEstimationFee(fee);
              var total = +value + +fee;
              //setTotalAmount(parseFloat(total).toFixed(8));
              setTotalAmount(total);

              setPrice(resp.Message);
              if (type == "fromAmount") {
                var amount = Number(resp.Message) * Number(value);
                // setfromAmount(parseFloat(value).toFixed(8));
                // settoAmount(parseFloat(amount).toFixed(8));
                // setfromAmount(value);
                settoAmount(amount.toFixed(8));
              } else if (type == "toAmount") {
                var amount = Number(value) / Number(resp.Message);
                // setfromAmount(parseFloat(amount).toFixed(8));
                // settoAmount(parseFloat(value).toFixed(8));
                //  setfromAmount(amount.toFixed(8));
                settoAmount(value);
              }
            }
          } else {
          }
        }
      } else {
        settoAmount(0);
      }
    } catch (error) {}
  };

  const swapAmount = async () => {
    try {
      if (
        appendFromData.currencySymbol != undefined &&
        appendToData.currencySymbol != undefined
      ) {
        if (appendFromData.currencySymbol != appendToData.currencySymbol) {
          if (fromAmount > 0 && toAmount > 0) {
            var obj = {
              from: appendFromData.currencySymbol,
              to: appendToData.currencySymbol,
              from_id: appendFromData.currid,
              to_id: appendToData.currid,
              fromAmount: +fromAmount,
              toAmount: +toAmount,
              fee: +estimateFee,
              withFee: +totatlAmount,
              currentPrice: +priceRef.current,
            };

            var data = {
              apiUrl: apiService.swapping,
              payload: obj,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setButtonLoader(false);
            if (resp.status) {
              setfromAmount(0);
              settoAmount(0);
              getUserbalance();
              // showSuccessToast(resp.Message);
              if(resp.Message=="Swaping success !"){
              showSuccessToast(t("Swaping success !"));
              }
              navigate("/swapHistory");
            } else {
              if(resp.Message=="Please try again later"){
                showErrorToast(t("please-try-again-later"));
              }else  if(resp.Message=="Insufficient Balance!"){
                showErrorToast(t("insufi-bal-withdraw"));
              }else if(resp.Message=="Should not allowed same currency swapping"){
                showErrorToast(t("Should not allowed same currency swapping"));
              }else if(resp.Message=="Should not allowed same currency swapping"){
                showErrorToast(t("Please fill all fields"));
              }else if (resp.Message.includes("maximum")) {
                var wordsAfterMaximum = resp.Message.split("maximum")[1].trim();
                showErrorToast(t("Please enter maximum"+wordsAfterMaximum+"amount"));
            } else if (resp.Message.includes("minimum")) {
              var wordsAfterManimum = resp.Message.split("minimum")[1].trim();
                showErrorToast(t("Please enter minimum"+wordsAfterManimum+"amount"));
            } else  if(resp.Message=="Internal server error"){
              showErrorToast(t("Internal-server-error"));
            }
            }
          } else {
            showErrorToast(t("please-enter-amount"));
          }
        } else {
          showErrorToast(t("same-currency-should-not-allow-swapping"));
        }
      } else {
        showErrorToast(t("please-choose-swapping-currencies"));
      }
    } catch (error) {}
  };

  const swapPrice = async () => {
    try {
      var obj = {
        from: fromref.current != undefined ? fromref.current : "BTC",
        to:
          toref.current != undefined ||
          toref.current != null ||
          toref.current != ""
            ? toref.current
            : "USDT",
      };
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setPrice(resp.Message);
      }
    } catch (error) {}
  };

  const handleOnChange_from = (e, data) => {
    setAmount(0, "fromAmount");
    setfromAmount(0);
    settoAmount(0);
    setErrorMsg("");
    setfromSwapRef(data.value);
    var findIndexing = fromTab.findIndex((x) => x.currid == data.value);
    if (findIndexing != -1) {
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (e, data) => {
    setfromAmount(0);
    settoAmount(0);
    setErrorMsg("");
    settoSwapRef(data.value);
    var findIndexingTo = toTab.findIndex((x) => x.currid == data.value);
    if (findIndexingTo != -1) {
      settoCurrency(fromTab[findIndexingTo].currencySymbol);
      setappendFToData(fromTab[findIndexingTo]);
      setTocurrencyImage(fromTab[findIndexingTo].image);
      swapPrice();
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    if (getKYC.status == true) {
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const swap = () => {
    // Swap currency data
    const tempCurrency = appendFromData;
    const tempCurrencySymbol = fromCurrency;

    // Update the state with the swapped values
    setappendFromData(appendToData);
    setappendFToData(tempCurrency);
    setfromCurrency(toCurrency);
    settoCurrency(tempCurrencySymbol);

    // Swap amount data
    const tempAmount = fromAmount;
    setfromAmount(toAmount);
    settoAmount(tempAmount);

    // Update the Dropdown selected values
    setfromSwapRef(appendToData.currid);
    settoSwapRef(appendFromData.currid);
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main">
        <div className="container-fluid">
          <div className="row swap_main">
            <div
              className={`${
                loginCheck == true ? "col-md-0 col-lg-2 p-0" : "d-none"
              }`}
            >
              <Side_bar />
            </div>
            {/* {loginCheck == false ? (
              <div className="col-lg-2 col-md-0 p-0"></div>
            ) : (
              ""
            )} */}

            {siteLoader == true ? (
              <div className="loadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div
                className={`${
                  loginCheck == true ? "col-md-12 col-lg-10" : "col-lg-12"
                }  `}
              >
                <div className="pad-l-r">
                  <div className="row">
                    <div className="col-lg-6 convert_center_box">
                      <div className="convert_card-wrapper border_none">
                        <div className="convert_card">
                          <div className="convert_title">
                            <h3>{t("convert")} </h3>
                            <div>
                              <Link to="/swapHistory">
                                <div className="text_green font-satoshi">
                                  {t("convert-history")}{" "}
                                  <i class="ri-arrow-right-s-line"></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                          {/* this (swap_lft_top) container copied from buycrypto component and below commenting code already exist */}
                          <div className="swap_lft_top">
                            <div className="foot_frst">
                              <span className="bals_divs_insubhead">
                                {t("spend")}
                              </span>
                              <input
                                type="text"
                                id="numberInput"
                                min="0"
                                maxLength={10}
                                value={fromAmountref.current}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ); // Allow numbers and dot
                                  if (
                                    (e.target.value.match(/\./g) || []).length >
                                    1
                                  ) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      -1
                                    ); // Prevent multiple dots
                                  }
                                }}
                                placeholder="0.00"
                                // value={fromAmount}
                                onChange={(e) =>
                                  setAmount(e.target.value, "fromAmount")
                                }
                                className="swap_in_val dark-text-white"
                              />
                            </div>
                            <div className="swap_chng_frst">
                              <div className=" Swapcard swap_drop_all">
                                <Dropdown
                                  placeholder={t("select-coin")}
                                  fluid
                                  selection
                                  options={fromCurrencyRef.current}
                                  // onChange={(o) =>
                                  //   onSelect(o, "fromTab")
                                  // }
                                  value={fromSwapRef.current}
                                  onChange={handleOnChange_from}
                                  className="reg-select-dropdown "
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            {errorMsg === "" ? (
                              ""
                            ) : (
                              <p className="text-red font-satoshi">
                                {errorMsg}
                              </p>
                            )}
                          </div>

                          <div className="swap_balance mt-3">
                            Balance{" "}
                            <span>
                              {appendFromData == ""
                                ? "0.0000"
                                : Number(
                                    appendFromData.currencyBalance
                                  ).toFixed(8)}{" "}
                            </span>
                          </div>
                          <div className="swap_icon">
                            <img
                              src={require("../assets/swap_icon.png")}
                              onClick={swap}
                            />
                          </div>
                          <div className="swap_lft_top">
                            <div className="foot_frst">
                              <span className="bals_divs_insubhead">
                                {t("receive")}
                              </span>
                              <input
                                type="number"
                                min="0"
                                placeholder="0.00"
                                disabled
                                value={toAmountref.current}
                                onChange={(e) =>
                                  setAmount(e.target.value, "toAmount")
                                }
                                className="swap_in_val dark-text-white"
                              />
                            </div>
                            <div className="swap_chng_frst">
                              <div className=" Swapcard swap_drop_all">
                                <Dropdown
                                  placeholder={t("select-coin")}
                                  fluid
                                  selection
                                  options={toCurrencyRef.current}
                                  value={toSwapRef.current}
                                  onChange={handleOnChange_to}
                                  className="reg-select-dropdown "
                                />
                              </div>
                            </div>
                          </div>
                          <div className="swap_balance mt-3">
                            Balance{" "}
                            <span>
                              {appendToData == ""
                                ? "0.0000"
                                : Number(appendToData.currencyBalance).toFixed(
                                    8
                                  )}{" "}
                            </span>
                          </div>
                          {/* <div className="swap_balance">
              Balance <span>00.00 BTC</span>
            </div> */}
                          {loginCheck == true ? (
                            <div className="Convert_btn">
                              {ButtonLoader == false ? (
                                <button
                                  onClick={swapAmount}
                                  className="font-satoshi"
                                >
                                  {t("convert")}
                                </button>
                              ) : (
                                <button>{t("loading")} ...</button>
                              )}
                            </div>
                          ) : (
                            <div className="sumbit_btn">
                              <button onClick={Movelogin}>
                                {t("login-to-continue")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 justify-content-center convert_center_box">
                      <div className="convert_card-summary border_none">
                        <span className="swap_balance"> {t("summary")}</span>
                        <hr />
                        <div className="swap_fst_rght">
                          <div className="swap_inner_main">
                            <span className="swap_change_clr">
                              {t("price")}
                            </span>
                            {fromref.current != "" && toref.current != "" ? (
                              <span className="swap_change_clr">
                                1 {fromref.current} ={" "}
                                {Number(priceRef.current).toFixed(8) == 0 ? (
                                  <span className="swap_change_clr">0</span>
                                ) : (
                                  Number(priceRef.current).toFixed(8)
                                )}{" "}
                                {toref.current}{" "}
                              </span>
                            ) : (
                              <span className="swap_change_clr">0.0000</span>
                            )}
                          </div>
                          <div className="swap_inner_main">
                            <span className="swap_change_clr">
                              {" "}
                              {t("min-swap")}
                            </span>
                            <span className="swap_change_clr">
                              {appendFromData == "" ? (
                                <span className="swap_change_clr">0.0000</span>
                              ) : (
                                appendFromData.minSwap
                              )}
                            </span>
                          </div>
                          <div className="swap_inner_main">
                            <span className="swap_change_clr">
                              {t("max-swap")}
                            </span>
                            <span className="swap_change_clr">
                              {appendFromData == "" ? (
                                <span>0.0000</span>
                              ) : (
                                appendFromData.maxSwap
                              )}{" "}
                            </span>
                          </div>
                          <div className="swap_inner_main">
                            <span
                              className="swap_change_clr"
                              style={{ color: "#888b93" }}
                            >
                              {t("swap-fee")} ({" "}
                              {appendFromData == ""
                                ? "0.0%"
                                : appendFromData.swapFee}{" "}
                              )
                            </span>
                            <span
                              className="swap_change_clr"
                              style={{ color: "#888b93" }}
                            >
                              {estimateFee == 0 ? (
                                <span>0.0000</span>
                              ) : (
                                Number(estimateFee).toFixed(8)
                              )}{" "}
                            </span>
                          </div>
                          <div className="swap_inner_main">
                            <span
                              className="swap_change_clr"
                              style={{ color: "#888b93" }}
                            >
                              {t("total-amount")}
                            </span>
                            <span
                              className="swap_change_clr"
                              style={{ color: "#888b93" }}
                            >
                              {" "}
                              {totatlAmount == 0 ? (
                                <span>0.0000</span>
                              ) : (
                                Number(totatlAmount).toFixed(4)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Swap;
