import React, { useEffect, useMemo } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  useEffect(() => {
    getStep();
  }, [0]);

  const [selectAssociate, setSelectAssociate, selectAssociateref] =
    useState("no");

  const [
    showOtherOccupationInput,
    setShowOtherOccupationInput,
    showOtherOccupationInputref,
  ] = useState(false);
  const [
    showOtherEmpstatusInput,
    setShowOtherEmpstatusInput,
    showOtherEmpstatusInputref,
  ] = useState(false);
  const [otherOccupation, setOtherOccupation] = useState("");
  const [unpaidActivity, setUnpaidActivity] = useState("");
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);

  const navigate = useNavigate();

  // relative or associate
  const handleYesAssociate = () => {
    setSelectAssociate("yes");
    handleCompClick()
    validate(formValue);
  };

  const handleNoAssociate = () => {
    setSelectAssociate("no");
    setCompanies([]);
    validate(formValue);
  };

  // const handleCompClick = () => {
  //   setIsAddCompany(true)
  // }

  const initialFormValue = {
    employStatus: "",
    empolyDuration: "",
    occupation: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [
    employStatusValidate,
    setemployStatusValidate,
    employStatusValidateref,
  ] = useState(false);
  const [
    empolyDurationValidate,
    setempolyDurationValidate,
    empolyDurationValidateref,
  ] = useState(false);
  const [occupationValidate, setoccupationValidate, occupationValidateref] =
    useState(false);
  const [companies, setCompanies] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { employStatus, empolyDuration, occupation } = formValue;

  const employStatusOptions = [
    { value: "Self-employed", label: t("self-employed") },
    { value: "Employee", label: t("employee") },
    { value: "Child", label: t("child") },
    {
      value: "Subsidy recipient (i.e., unemployed/pensioner)",
      label: t("subsidy-recipient"),
    },
    { value: "Rentier", label: t("rentier") },
    { value: "Unpaid activity", label: t("unpaid-activity") },
  ];

  const empolyDurationOptions = [
    { value: "Less than 1 year", label: t("less-than-1-year") },
    { value: "1 to 3 years", label: t("1-to-3-year") },
    { value: "3 to 7 years", label: t("3 to 7 years") },
    { value: "More than 7 years", label: t("more-than-7-years") },
  ];

  const occupationOptions = [
    {
      value: "Politically Exposed Person (PEP)",
      label: t("politically-expo-person"),
    },
    { value: "Law/Notary", label: t("law/notary") },
    { value: "Art/Antiques", label: t("art-antiques") },
    { value: "Public Office", label: t("public-office") },
    { value: "Currency Exchange Office", label: t("currency-exchange-office") },
    { value: "Casino/Betting", label: t("casino-betting") },
    {
      value: "Construction/Real Estate Development",
      label: t("construction/real-estate-development"),
    },
    { value: "Wealth Management", label: t("wealth-manage") },
    { value: "Information Technology", label: t("info-tech") },
    { value: "Precious Metals", label: t("precious-metals") },
    { value: "Import-Export", label: t("import-export") },
    { value: "Sports Company", label: t("sports-company") },
    {
      value: "Holding/Instrumental Company",
      label: t("holding-company"),
    },
    { value: "Others", label: t("others") },
  ];

  const handleCompanyChange = (index, key, value) => {
    if (value.startsWith(" ")) {
      return; // Prevent setting the value if it starts with a space
    }else{
      const updatedCompanies = companies.map((company, i) =>
        i === index ? { ...company, [key]: value } : company
      );
      setCompanies(updatedCompanies);
      
      // Re-validate after each change
      validate(formValue);
    }
   
  };
  
  const handleCompanyDelete = (index) => {
    const updatedCompanies = companies.filter((_, i) => i !== index);
    setCompanies(updatedCompanies);
   if( updatedCompanies.length ==0){
    setSelectAssociate('no')
   }
  };
  const validate = (values) => {
    let errors = {};
    if (values.employStatus === "") {
      setemployStatusValidate(true);
      errors.employStatus = t("employ-status-requierd");
    } 
    if (values.employStatus === "Unpaid activity" && !unpaidActivity) {
      setemployStatusValidate(true);
      errors.employStatus = t("employ-status-requierd");
    }
    // else {
    //   setemployStatusValidate(false);
    // }
    else if (values.empolyDuration === "") {
      setempolyDurationValidate(true);
      errors.empolyDuration = t("employment-duration-required");
    }
    //  else {
    //   setempolyDurationValidate(false);
    // }
    else if (values.occupation === "") {
      setoccupationValidate(true);
      errors.occupation = t("occupation-required");
    }

    // else {
    //   setoccupationValidate(false);
    // }
  
    if (selectAssociateref.current === "yes" && companies.length >= 0) {
      companies.forEach((company, index) => {
        if (!company.legalEntityName) {
          errors[`legalEntityName${index}`] = t("legal-entity-name-required");
        } else if (company.legalEntityName.length < 3 || company.legalEntityName.length > 60) {
           errors[`legalEntityName${index}`] = t("name-nust-3-25-char");
        } else if (!/^[a-zA-Z\s]*$/.test(company.legalEntityName)) {
          errors[`legalEntityName${index}`] = t(
            "legal-entity-name-letters-only"
          );
        } else if (!company.taxIdNumber) {
          errors[`taxIdNumber${index}`] = t("tax-identify-number-required");
        } 
        else if (company.taxIdNumber.length < 3 || company.taxIdNumber.length > 25) {
              errors[`taxIdNumber${index}`] = t("tax-identify-number-length");
        } 
        else if (!/^[a-zA-Z0-9]*$/.test(company.taxIdNumber)) {
              errors[`taxIdNumber${index}`] = t("tax-identify-number-alphanumeric");
        }
        else if (!company.ownershipShare) {
          errors[`ownershipShare${index}`] = t("ownership-share-required");
        } 
        // else if (!/^\d{1,2}(\.\d+)?%$/.test(company.ownershipShare) && company.ownershipShare.length <= 6) {
        //     errors[`ownershipShare${index}`] = t("ownership-share-percentage");
        // } else if (parseFloat(company.ownershipShare) < 0 || parseFloat(company.ownershipShare) > 100) {
        //             errors[`ownershipShare${index}`] = t("ownership-share-valid-range");
        // } else if (company.ownershipShare.length < 2 || company.ownershipShare.length > 6) {
        //         errors[`ownershipShare${index}`] = t("ownership-share-length");
        // }
         else {
          setoccupationValidate(false);
          setempolyDurationValidate(false);
          setemployStatusValidate(false);
        }
      });
    } else {
      setoccupationValidate(false);
      setempolyDurationValidate(false);
      setemployStatusValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const handleKeyPress =(e)=>{
    const cursorPosition = e.target.selectionStart;
    const value = e.target.value;

    if (e.key === " " && cursorPosition === 0) {
      e.preventDefault();
      return;
    }
    if (
      [
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key)
    ) {
      return;
    }

    e.preventDefault();
  }

  const handleEmployStatusChange = (selectedEmployStatus) => {
    const updatedFormValue = {
      ...formValue,
      employStatus: selectedEmployStatus.value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
    if(formValue.employStatus=="Unpaid activity"){
      setShowOtherEmpstatusInput(true)
    }else{
      setShowOtherEmpstatusInput(false)
    }
  };

  const handleUnpaidActivityChange = (e) => {
    const value = e.target.value;
    setUnpaidActivity(value);

    // const updatedFormValue = {
    //   ...formValue,
    //   employStatus: value,
    // };

    validate(formValue);
  };

  const handleEmpolyDurationChange = (selectedEmpolyDuration) => {
    const updatedFormValue = {
      ...formValue,
      empolyDuration: selectedEmpolyDuration.value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleOccupationChange = (selectedOccupation) => {
    const updatedFormValue = {
      ...formValue,
      occupation: selectedOccupation.value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
    if(formValue.occupation=="Others"){
      setShowOtherOccupationInput(true);
    }else{
      setShowOtherOccupationInput(false);
    }
  };

  const handleOtherOccupationChange = (e) => {
    const value = e.target.value;
    setOtherOccupation(value);

    const updatedFormValue = {
      ...formValue,
      occupation: value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleCompClick = () => {
    const newCompany = {
      legalEntityName: "",
      taxIdNumber: "",
      ownershipShare: "",
    };
    const updatedCompanies = [...companies, newCompany];
    setCompanies(updatedCompanies);
    validate(formValue);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };


  const formSubmit = async (payload) => {
    const errors = validate(formValue);
    formValue["individualControl"] = selectAssociateref.current;
    formValue["entities"] = companies;
    formValue["UnpaidActivity"] = unpaidActivity;
    formValue["verifiedSteps"] = "Step-2";

    if (Object.keys(errors).length === 0) {
      var data = {
        apiUrl: apiService.kycsecond,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        // toast.success(resp.Message);
        if(resp.Message=="Successfully completed your Second step verification , continue your next step of verification"){
          showsuccessToast(t("Successfully completed your Second step verification , continue your next step of verification"))
        }
        navigate("/KycInformation3");
      } else {
        if(resp.Message=="Internal server error"){
          showerrorToast(t("Internal-server-error"))
        }else if(resp.Message=="Something Went Wrong. Please Try Again later"){
          showerrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
        }else if (resp.Message=="Oops , Please verify the previous steps of verification"){
          showerrorToast(t("Oops , Please verify the previous steps of verification"))
        }
        // toast.error(resp.Message);
      }
    }
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);

      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.employStatus = kycData.employStatus;
        setUnpaidActivity(kycData.unpaidActivity);
        formValue.empolyDuration = kycData.empolyDuration;
        formValue.occupation = kycData.occupation;
        formValue["individualControl"] = kycData.individualControl;
        setSelectAssociate(kycData.individualControl =="" ?"no":kycData.individualControl);
        // formValue["entities"] =kycData.entities;
        setCompanies(kycData.entities);
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation1">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no two">2</span>
                    {t("additional-account-holder")}
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("what-is-your-employment-status")}
                    </h6>
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper input-mt">
                        <Select
                          options={employStatusOptions}
                          value={employStatusOptions.find(
                            (option) => option.value === employStatus
                          )}
                          onChange={handleEmployStatusChange}
                          placeholder={t("select-employ-status")}
                          className="reg-select-dropdown "
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    {formValue.employStatus=="Unpaid activity" && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          {t("please-specify-employ-status")}
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={unpaidActivity}
                            onChange={handleUnpaidActivityChange}
                            className=""
                            placeholder={t("enter-your-occupation")}
                          />
                        </div>
                      </div>
                    )}
                    {validationnErr && validationnErr.employStatus && (
                      <p className="errorcss">{validationnErr.employStatus}</p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label kycallignment">
                      {t("indicate-the-duration-current-status")}
                    </h6>
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper input-mt">
                        <Select
                          options={empolyDurationOptions}
                          value={empolyDurationOptions.find(
                            (option) => option.value === empolyDuration
                          )}
                          onChange={handleEmpolyDurationChange}
                          placeholder={t("select-employ-duration")}
                          className="reg-select-dropdown "
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    {validationnErr && validationnErr.empolyDuration && (
                      <p className="errorcss">
                        {validationnErr.empolyDuration}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("occupation")}</h6>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper input-mt">
                        <Select
                          options={occupationOptions}
                          value={occupationOptions.find(
                            (option) => option.value === occupation
                          )}
                          onChange={handleOccupationChange}
                          placeholder={t("select-occupation")}
                          className="reg-select-dropdown "
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                    {showOtherOccupationInputref.current && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          {t("please-specify-occupation")}
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={otherOccupation}
                            minLength={3}
                            maxLength={30}
                            onChange={handleOtherOccupationChange}
                            className=""
                            placeholder={t("enter-your-occupation")}
                          />
                        </div>
                      </div>
                    )}
                    {validationnErr && validationnErr.occupation && (
                      <p className="errorcss">{validationnErr.occupation}</p>
                    )}
                  </div>
                  <div className="access_act_text">
                    <p className="holder_content">
                      {t("does-this-individual-control")}
                    </p>
                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAssociate}
                        className={
                          selectAssociateref.current === "yes"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={handleNoAssociate}
                        className={
                          selectAssociateref.current === "no"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  {selectAssociateref.current === "yes" ? (
                    <>
                      <div className="access_act_text">
                        <p className="holder_content">
                          {t("if-yes-add-info-entity")}
                        </p>
                        <div
                          className="add_compant_content cur-pointer"
                          onClick={handleCompClick}
                        >
                          <i class="ri-add-circle-line"></i>
                          <h4>{t("add-company")}</h4>
                        </div>
                      </div>

                      {companies.map((company, index) => (
                        <div key={index}>
                          <div className="input-groups icons">
                            <h6 className="input-label">
                              {t("legal-entity-name")}
                            </h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                                type="text"
                                maxLength={60}
                                value={company.legalEntityName}
                                placeholder={t("enter-entity-name")}
                                onChange={(e) => {
                                 
                                  handleCompanyChange(index, "legalEntityName", e.target.value);
                                }}
                              />
                            </div>
                            {validationnErr[`legalEntityName${index}`] && (
                              <p className="errorcss">
                                {validationnErr[`legalEntityName${index}`]}
                              </p>
                            )}
                          </div>

                          <div className="input-groups icons">
                            <h6 className="input-label">
                              {t("tax-identify-number")}
                            </h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                                type="text"
                                maxLength={25}
                                value={company.taxIdNumber}
                                placeholder={t("tax-identify-number")}
                                onChange={(e) =>
                                  handleCompanyChange(
                                    index,
                                    "taxIdNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            {validationnErr[`taxIdNumber${index}`] && (
                              <p className="errorcss">
                                {validationnErr[`taxIdNumber${index}`]}
                              </p>
                            )}
                          </div>

                          <div className="input-groups icons">
                            <h6 className="input-label">
                              {t("ownership-share")}, %
                            </h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                              type="text"
                              id="numberInput"
                              min="0"
                              maxLength={6}
                              name="tfa"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                              }}
                               placeholder={t("enter-ownership-share")}
                               value={company.ownershipShare}
                               onChange={(e) =>
                                 handleCompanyChange(index, "ownershipShare", e.target.value)
                                }
                              />
                            </div>
                            {validationnErr[`ownershipShare${index}`] && (
                              <p className="errorcss">
                                {validationnErr[`ownershipShare${index}`]}
                              </p>
                            )}
                          </div>

                          <div
                            className="delete_text cursor-pointer"
                            onClick={() => handleCompanyDelete(index)}
                          >
                            <i className="ri-delete-bin-6-line"></i>{" "}
                            {t("delete")}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={formSubmit}>{t("next")}</button>
                    ) : (
                      <button>{t("loading")} ...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
