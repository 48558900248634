import React, { useEffect} from "react";
import useState from "react-usestateref";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";

import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Bars } from "react-loader-spinner";

const Privacy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    get_privacy_page();
    setlanguage(localStorage.getItem("i18nextLng")); // Store the language in localStorage
  },[]);

  const [language,setlanguage,languageref]=useState('en');
  const [buttonLoader,setbuttonLoader]=useState();
  const [data,setdata]=useState();

  var get_privacy_page =async ()=>{
    var obj={
      launguage:languageref.current,
      key:"Privacy"
    }
    var data = {
      apiUrl: apiService.get_privacy_page,
      payload: obj,
    };
    setbuttonLoader(true);
    var resp = await postMethod(data);
    console.log(resp,"respfor privacy page");
    setbuttonLoader(false);
    setdata(resp.data);
  }

  const timeOption = {
    timeZone: 'Europe/Madrid', // Spanish timezone
    month: 'short', // Short month (e.g., Dec)
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  };
  const formatter = new Intl.DateTimeFormat('es-ES', timeOption);

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>
        {
  buttonLoader
  == true ? (
                          <div className="loadercssss">
                            <Bars
                              height="80"
                              width="80"
                              color="#33c2b9"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </div>
                        ) : (
        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  {/* <h5 className="mb-5">{t("privacy-policy")}</h5> */}
                  <h5 className="mb-5">{data.title}</h5>
                  <h6>{t("effective-date")} : 
                  { formatter.format(new Date(data.updated_at))}
                  </h6>
                  <div>
                  <p dangerouslySetInnerHTML={{ __html:data? data.content_description:"" }}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
                        )}
        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Privacy;
