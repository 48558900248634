import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import StakeOptTable from "./StakeOptTable";
import StakeHisTable from "./StakeHisTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Moment from "moment";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

const Staking = () => {
  useEffect(() => {
    get_staking_details();
    stakchoose({ value: "fixed" });
    getStakingHistory(1);
    get_stake_profit();
    getUserTotalbalance();
    gettotalbalnce();
  }, [0]);

  const [perpage, setperpage] = useState(10);
  const [fixedStaking, setfixedStaking, fixedStakingref] = useState([]);
  const [flexibleStaking, setflexibleStaking] = useState([]);
  const [stakingdDetails, setstakingdDetails, stakingdDetailsref] = useState(
    []
  );

  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const { t } = useTranslation();

  const stakingOptions = [
    { value: "fixed", label: t("fixed") },
    { value: "flexible", label: t("flexible") },
  ];

  const [plan, setplan] = useState("fixed");

  const [stakeHistory, setstakeHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);
  const [historyLoader, sethistoryLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(5);

  const [YesterdayProfit, setYesterdayProfit] = useState(0);
  const [todayProfit, settodayProfit] = useState(0);
  const [YesterdayEURProfit, setYesterdayEURProfit] = useState(0);
  const [todayEURProfit, settodayEURProfit] = useState(0);

  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getStakingHistory(pageNumber);
  };

  const get_staking_details = async (pages) => {
    try {
      var stake_obj = {
        FilPerpage: perpage,
        FilPage: pages,
        search: "",
      };

      var data = {
        apiUrl: apiService.get_staking_details,
        payload: stake_obj,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setstakingdDetails(resp.data.result);
        stakchoose({ value: "fixed" });
      }
    } catch (err) {}
  };

  const stakchoose = (data) => {
    setplan(data.value);
    setSiteLoader(true);

    if (data.value === "fixed") {
      var stakedata = [];
      for (var i = 0; i < stakingdDetailsref.current.length; i++) {
        if (stakingdDetailsref.current[i].status == "Active") {
          var obj = {
            currencyName: stakingdDetailsref.current[i].currencyName,
            currencyImage: stakingdDetailsref.current[i].currencyImage,
            currencySymbol: stakingdDetailsref.current[i].currencySymbol,
            minimimumStaking: stakingdDetailsref.current[i].minimumStaking,
            maximimumStaking: stakingdDetailsref.current[i].maximumStaking,
            currencyId: stakingdDetailsref.current[i].currencyId,
            duration: [
              {
                duration: stakingdDetailsref.current[i].firstDuration,
                durationApy: stakingdDetailsref.current[i].FistDurationAPY,
              },
              {
                duration: stakingdDetailsref.current[i].secondDuration,
                durationApy: stakingdDetailsref.current[i].SecondDurationAPY,
              },
              {
                duration: stakingdDetailsref.current[i].thirdDuration,
                durationApy: stakingdDetailsref.current[i].ThirdDurationAPY,
              },
              {
                duration: stakingdDetailsref.current[i].fourthDuration,
                durationApy: stakingdDetailsref.current[i].FourthDurationAPY,
              },
            ],
            apy: stakingdDetailsref.current[i].apy,
            stakeid: stakingdDetailsref.current[i]._id,
            type: "fixed",
            // APRinterest: stakingdDetailsref.current[i].APRinterest,
            APRinterest: stakingdDetailsref.current[i].FistDurationAPY+"-"+stakingdDetailsref.current[i].FourthDurationAPY
          };
          stakedata.push(obj);
        }
      }

      setfixedStaking(stakedata);
    } else {
      var stakedata = [];

      for (var i = 0; i < stakingdDetailsref.current.length; i++) {
        if (stakingdDetailsref.current[i].statusflex == "Active") {
          var obj = {
            currencyname: stakingdDetailsref.current[i].currencyName,
            currencyImage: stakingdDetailsref.current[i].currencyImage,
            currencySymbol: stakingdDetailsref.current[i].currencySymbol,
            currencyId: stakingdDetailsref.current[i].currencyId,
            minimimumStaking: stakingdDetailsref.current[i].minimumStakingflex,
            maximimumStaking: stakingdDetailsref.current[i].maximumStakingflex,
            apy: stakingdDetailsref.current[i].apy,
            stakeid: stakingdDetailsref.current[i]._id,
            type: "flexible",
            APRinterest: stakingdDetailsref.current[i].APRinterest,
          };
        }
        stakedata.push(obj);
      }
      setflexibleStaking(stakedata);
    }
    setSiteLoader(false);
  };

  const getStakingHistory = async (page) => {
    try {
      sethistoryLoader(true);
      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { FilPerpage: 5, FilPage: page },
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      sethistoryLoader(false);

      if (resp.status) {
        setstakeHistory(resp.data);
        settotal(resp.total);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const get_stake_profit = async () => {
    try {
      var data = {
        apiUrl: apiService.get_stake_profit,
      };
      var resp = await postMethod(data);
      settodayProfit(resp.todayProfit);
      setYesterdayProfit(resp.yesterdayProfit);
      setYesterdayEURProfit(resp.yesterEURdayProfit);
      settodayEURProfit(resp.todayEURProfit);
    } catch (err) {}
  };

  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [AvailableEURPrice, setAvailableEURPrice] = useState(0);




  const [themeToggle, setThemeToggle] = useState(
    localStorage.getItem("currency") || "USD"
  );

  const handleUSDToggle = () => {
    setThemeToggle("USD");
    localStorage.setItem("currency", "USD");
    getUserTotalbalance();
    gettotalbalnce();
    get_stake_profit();
  };

  const handleEURToggle = () => {
    setThemeToggle("EUR");
    localStorage.setItem("currency", "EUR");
    getUserTotalbalance();
    gettotalbalnce();
    get_stake_profit();
  };

  const gettotalbalnce = async () => {
    try {
      var data = {
        apiUrl: apiService.getCurrencyConvertion,
      };

      // setbalanceLoader(true);
    setSiteLoader(true);

      var resp = await getMethod(data);
      // setbalanceLoader(false);
      setSiteLoader(false);

      setAvailablePrice(resp.totalUsdValue);
      setAvailableEURPrice(resp.totalEurValue);
    } catch (err) {}
  };

  const [search, setsearch, searchref] = useState("");

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: 1,
      search: search,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      // setAvailablePrice(balanceData.available_balance);
      // setAvailableEURPrice(balanceData.available_balanceEUR);
    }
  };


  return (
    <>
      <Header />
      <div className="fidex_refferal_content">
        <div className="staking-hero">
          <div className="container">
            <div className="row stake-wrapper">
              <div className="col-lg-8 ">
                <h4 className="stake-title">{t("staking")}</h4>
                <p className="stake-para font-satoshi">
                  {t("staking-content")}
                </p>
                {/* <button className="head-btn mt-3">Start earning</button> */}
              </div>
              {loginCheck == true ? (
              <div className="col-lg-4">
                <div className="staking-rewards staking_rewards_card">
                  <div className="staking-flex">
                    <h4 className="stake-asset">
                      {t("assets")}({themeToggle})
                    </h4>

                    <div className="stake-toggle">
                      <button
                        onClick={handleUSDToggle}
                        className={
                          themeToggle === "USD" ? "usd_btn" : "Eur_btn"
                        }
                      >
                        USD
                      </button>
                      <button
                        onClick={handleEURToggle}
                        className={
                          themeToggle === "EUR" ? "usd_btn" : "Eur_btn"
                        }
                      >
                        EUR
                      </button>
                    </div>
                    {/* <h6 className="stake-sub-asset font-satoshi">
                      My Rewards{" "}
                      <span className="stake-arrow">
                        <i class="fa-solid fa-chevron-right"></i>
                      </span>{" "}
                    </h6> */}
                  </div>
                  <h2 className="stake-price font-satoshi staking_price_green mt-3">
                    {/* 00.00 */}
                    {themeToggle == "USD"
                      ? AvailablePrice == " " ||
                        AvailablePrice == "0" ||
                        AvailablePrice == 0
                        ? 0.0
                        : AvailablePrice
                      : AvailableEURPrice == " " ||
                        AvailableEURPrice == "0" ||
                        AvailableEURPrice == 0
                      ? 0.0
                      : AvailableEURPrice}
                  </h2>
                  {/* <h5 className="stake-total">=$00.00</h5> */}
                  <div className="staking-flex">
                    <div>
                      <h5 className="stake-profit">
                        {t("yesterday-profit")}({themeToggle})
                      </h5>
                      <h6 className="stake-profit-total font-satoshi">
                        {themeToggle == "USD"
                          ? YesterdayProfit
                            ? YesterdayProfit.toFixed(2)
                            : 0.0
                          : YesterdayEURProfit
                          ? YesterdayEURProfit.toFixed(2)
                          : 0.0}
                      </h6>
                    </div>
                    <div className="font-satoshi">
                      <h5 className="stake-profit">
                        {t("total-profit")}({themeToggle})
                      </h5>
                      <h6 className="stake-profit-total">
                        {themeToggle == "USD"
                          ? todayEURProfit
                            ? todayEURProfit.toFixed(2)
                            : 0.0
                          : todayProfit
                          ? todayProfit.toFixed(2)
                          : 0.0}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              ):""}
            </div>
          </div>
        </div>
        {/* staking options */}
        <div>
          <div className="container">
            <h5 className="opt-title">{t("staking-options")}</h5>
            <div className="staking-flex">
              <div className="custom-select-stake mb-2">
                <div className="input-groups select-wrapper">
                  <Select
                    options={stakingOptions}
                    styles={colourStyles}
                    onChange={stakchoose}
                    // placeholder={t("all-items")}
                    placeholder={stakingOptions[0].label}
                    className="reg-select-dropdown"
                  />
                </div>
              </div>

              {/* <div className="stake-search-container">
                <input
                  type="text"
                  placeholder="Search"
                  className="stake-input"
                />
                <i class="fa-solid fa-magnifying-glass"></i>
              </div> */}
            </div>

            <StakeOptTable
              data={plan == "fixed" ? fixedStaking : flexibleStaking}
            />
          </div>
        </div>
        {loginCheck == true ? (
          <>
            {/* staking history */}
            <div className="padding_staking">
              <div className="container">
                <h5 className="opt-title ">{t("staking-history")}</h5>
                <StakeHisTable />

                {/* <div className="table-responsive table-cont">
        <table className="table">
          <thead>
          <tr className="stake-head font-satoshi">
            <th>Package</th>
            <th className="pad-left-23 txt-center">Amount</th>
            <th className="pad-left-23 txt-center">APY/APR</th>
            <th className="pad-left-23 txt-center">Type</th>
            <th className="pad-left-23 txt-center opt-nowrap">Stake date</th>
            <th className="pad-left-23 txt-center opt-nowrap">Stake end date</th>
            <th className="pad-left-23 txt-center opt-nowrap">Next Claim date</th>
            <th className="pad-left-23 txt-center opt-nowrap">Total interest</th>
            <th className="opt-btn-flex pad-left-23 table-action ">Int.per cycle</th>
          </tr>
        </thead>

        <tbody>
              {stakeHistory && stakeHistory.length > 0 ? (
                stakeHistory.slice(0, 5).map((options, i) => {
                  return (
                    
              <tr key={options.stakeId}>
                <td className="table-flex">
                  <img src={options.currencyImage} alt="" />
                  <div className="table-opt-name">
                    <h4 className="opt-name font-satoshi font_14">
                      {options.stakeCurrencsymbol}
                    </h4>
                    <h3 className="opt-sub font-satoshi font_14">
                      {options.stakeCurrencyName}
                    </h3>
                  </div>
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.stakeAmont}
                </td>
                <td className=" opt-percent font-satoshi font_14 table_center_text pad-left-23">
                  {options.currentAPY}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.type}
                </td>           
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.startDate}
                </td>       
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.endDate}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.endDate}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                  {options.dailyinterest.toFixed(6)}
                </td>
              </tr>
            )
          })
        ):""}
        </tbody>
      </table>
      
      <div className="paginate font-satoshi">
        <span>
          <i class="fa-solid fa-chevron-left"></i>
        </span>
        <span className="paginate-one">1</span>
        <span>2</span>
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div>
    </div> */}
              </div>
            </div>
            {/* FAQ */}
            <div className="faq_padding">
              <div className="container">
                <h5 className="opt-title">FAQ</h5>
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="faq">
                    <div class="accordion-item font-satoshi">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          1. {t("staking-faq-question1")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {t("staking-faq-answer1")}
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item font-satoshi">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          2. {t("staking-faq-question2")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {t("staking-faq-answer2")}
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item font-satoshi">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          3. {t("staking-faq-question3")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {t("staking-faq-answer3")}
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item font-satoshi">
                      <h2 class="accordion-header" id="flush-headingfour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapsefour"
                          aria-expanded="false"
                          aria-controls="flush-collapsefour"
                        >
                          4. {t("staking-faq-question4")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapsefour"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingfour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {t("staking-faq-answer4")}
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item font-satoshi">
                      <h2 class="accordion-header" id="flush-headingfive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapsefive"
                          aria-expanded="false"
                          aria-controls="flush-collapsefive"
                        >
                          5. {t("staking-faq-question5")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapsefive"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingfive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {t("staking-faq-answer5")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Staking;
