import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import { socket } from "../context/socket";
import { socket_web } from "../context/socket_web";
import "./Trade.css";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { toast } from "react-toastify";

// import {// toast} from "react-// toastify";
import { env } from "../core/service/envconfig";
import moment from "moment";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import button from "@mui/material/Button";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import isEmpty from "../core/lib/isEmpty";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import PriceFooter from "./TradePage/PriceFooter";

function Home() {
  const [theme, setTheme] = useState("Dark");
  const [pair, setPair] = useState("ETH_USDC");

  const options = ["one", "two", "three"];
  const [pairlist, setpairlist, pairlistref] = useState([]);
  const [orderbookask, setorderbookask, orderbookaskref] = useState([]);
  const [orderbooksloader, setorderbooksloader, orderbooksloaderref] =
    useState(false);

  const [orderbookbid, setorderbookbid, orderbookbidref] = useState([]);
  const [currentlasprice, setcurrentlasprice] = useState("");
  const [searchInputlist, setsearchInputlist, searchInputlistref] = useState(
    []
  );
  const [chartPair, setchartPair] = useState("ETHUSDC");
  const [curnt_Ordertype_tab, setcurnt_Ordertype_tab, curnt_Ordertype_tabref] =
    useState("Limit");
  const [currentPair, setcurrentPair] = useState("");
  const [viewPair, setviewPair] = useState("");
  const [fromCurrency, setFromCurrenncy] = useState("");
  const [toCurrency, setToCurrenncy] = useState("");
  const [currentType, setcurrentType, currentTyperef] = useState("buy");
  const [frombalance, setFromBalance] = useState(0);
  const [tobalance, settobalance] = useState(0);
  const [maxbuy, setmaxbuy, maxbuyref] = useState(0);
  const [maxsell, setmaxsell, maxsellref] = useState(0);

  const [checkAuth, setcheckAuth] = useState(false);
  const [pairDetails, setpairDetails] = useState("");
  const [orderbookLoader, setorderbookLoader] = useState(true);
  const [orderbookLoaderbid, setorderbookLoaderbit] = useState(true);
  const [orderbookdivider, setorderbookdivider] = useState(true);
  const [pairLoader, setPairLoader] = useState(false);
  const [sideLoader, setsideLoader] = useState(false);

  const [perpage, setperpage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  // const [alltradeHistoryData, setalltradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [pairTickerDetails, setpairTickerDetails, pairTickerDetailsref] =
    useState("");
  const [marketPrice, setmarketPrice, marketPriceref] = useState(0);
  const [marketTrade, setmarketTrade, marketTraderef] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [authentication, setauthentication] = useState(false);
  const [searchpair, setsearchpair] = useState(null);
  const [currentpairs, setcurrentpairs, currentpairsref] = useState(null);
  const [allpairslist, setallpairslist, allpairslistref] = useState([]);
  const [orderloader, setorderloader, orderloaderref] = useState(false);
  const [sellorderloader, setsellorderloader, sellorderloaderref] =
    useState(false);

  const [siteLoader, setSiteLoader, siteLoaderref] = useState(false);
  const [priceLoader, setpriceLoader, priceLoaderref] = useState(false);
  const [fromcurrency, Setfromcurrency, fromcurrencyref] = useState(false);

  const [marketTradeloader, setmarketTradeloader, marketTradeloaderref] =
    useState(false);
  const [sliderValue, setSliderValue, sliderValueref] = useState(0);
  const [sliderValue1, setSliderValue1, sliderValue1ref] = useState(0);
  const [sliderValue2, setSliderValue2, sliderValue2ref] = useState(0);
  const [sliderValue3, setSliderValue3, sliderValue3ref] = useState(0);
  const [sliderValue4, setSliderValue4, sliderValue4ref] = useState(0);
  const [sliderValue5, setSliderValue5, sliderValue5ref] = useState(0);

  const { t } = useTranslation();
  const [tradeChoosingType, setTradeChoosingType] = useState("mtrade");
  const [cancelloader, setcancelloader, cancelloaderref] = useState(false);
  const tradechoose = (data) => {
    setTradeChoosingType(data);
  };
  const Fullorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(true);
    setorderbookdivider(true);
  };
  const bidorderbook = (event, newValue) => {
    setorderbookLoader(false);
    setorderbookLoaderbit(true);
    setorderbookdivider(false);
  };
  const askorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(false);
    setorderbookdivider(false);
  };

  const handleSliderChange = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue(newValue);
    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");

    buy_sell_percentage1("0");
  };
  const handleSliderChange1 = (event, newValue) => {
    setcurrentType(event.target.name);

    setSliderValue1(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage("0");
  };
  const handleSliderChange2 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue2(newValue);

    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage1("0");
  };
  const handleSliderChange3 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue3(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage("0");
  };
  const handleSliderChange4 = (event, newValue) => {
    setSliderValue4(newValue);
    setcurrentType(event.target.name);
    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue("0");
    setSliderValue5("0");
    buy_sell_percentage1("0");
  };
  const handleSliderChange5 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue5(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue("0");
    buy_sell_percentage("0");
  };
  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const navigate = useNavigate();

  const tvWidget = null;

  useEffect(() => {
    var urls = window.location.href;
    var pair = urls.split("trade/")[1];
    var replace_string = pair.replace("_", "/");
    socket.emit("GetOrderBook", { symbol: replace_string });
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
    setorderbooksloader(true);
    check();
    socketinit();
    selectPairbyCurrency("USDC");

  }, [socket]);

  async function check() {
    let mecheck = await getAuthToken();

    var callapi = false;
    if (mecheck != "" && mecheck != undefined && mecheck != null) {
      await setcheckAuth(true);
      callapi = true;
    } else {
      await setcheckAuth(false);
      callapi = false;
    }
    var urls = window.location.href;
    var fetchPair = urls.split("trade/")[1];
    setPair(fetchPair);
    if (fetchPair) {
      var fromcurr = fetchPair.split("_")[0];
      var toCurr = fetchPair.split("_")[1];
      setcurrentPair(fromcurr+"_"+toCurr);
      setviewPair(fromcurr + " / " + toCurr);
      setFromCurrenncy(fromcurr);
      setToCurrenncy(toCurr);
      getCurrpairDatas(fetchPair);
      // fetchTickerPrice(fetchPair);
      //getMarketTrades(fetchPair);
      selectPair(fetchPair);

      if (callapi == true) {
        await getUserbalance(fetchPair);
        await getstopLimitOrders(1);
        await getActiveOrders(1, fetchPair);
        await tradeHistory(1);
        getCancelOrders(1);
      } else {
      }

      setchartPair(fromcurr + toCurr);

      allpairDatas();
    } else {
      navigate("/");
    }
    socket.connect();
    socket_web.connect();
  }
  const activePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber);
  };

  const [topMovers, setTopMovers, topMoversref] = useState([]);
  const socketinit = async () => {
    socket.on("OrderBook", async (response) => {
      var data = await response.data;

      setorderbookask([]);
      setorderbookbid([]);
      var orderbookbid = [];
      var orderbookask = [];
      orderbookbid = data["bids"].length === 0 ? [] : data["bids"];
      orderbookask = data["asks"].length === 0 ? [] : data["asks"];
      var askcumtotal = 0;
      for (let index = 0; index < orderbookask.length; index++) {
        var element = orderbookask[index];
        var multiply = element[0] * element[1];
        askcumtotal = parseFloat(askcumtotal) + parseFloat(multiply);
        orderbookask[index]["percent"] = (multiply / askcumtotal) * 100;
      }
      var bidcumtotal = 0;
      for (let index = 0; index < orderbookbid.length; index++) {
        var element = orderbookbid[index];
        var multiply = element[0] * element[1];
        bidcumtotal = parseFloat(bidcumtotal) + parseFloat(multiply);
        orderbookbid[index]["percent"] = (multiply / bidcumtotal) * 100;
      }
      setorderbookask(
        orderbookask.sort(function (a, b) {
          return b[0] - a[0];
        })
      );
      setorderbookbid(
        orderbookbid.sort(function (a, b) {
          return b[0] - a[0];
        })
      );
      setorderbooksloader(false);
    });

    socket.on("TickerPrice", async (response) => {
      if (response.data) {
        var tickerdetail = response.data;
        setpairTickerDetails(tickerdetail);
        setmarketPrice(
          tickerdetail.last
            ? parseFloat(tickerdetail.last).toFixed(
              pairDetails?.liq_price_decimal
            )
            : 0.0
        );
        setmaxbuy(parseFloat(tobalance) / parseFloat(formValue.price));

        setmaxsell(parseFloat(frombalance) * parseFloat(formValue.price));
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
          //formValue.stop_price = "";
        } else if (curnt_Ordertype_tabref.current == "Market") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        } else {
          if (priceLoaderref.current == false) {
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        }
      }
    });

    socket.on("TopmoversList", async (response) => {
      if (response != null) {
        if (response.data != null) {
          var moversarr = JSON.parse(response.data ? response.data : []);
          setTopMovers(moversarr);
        }
      }
    });
    socket.on("TradeHistory", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        setmarketTrade(tickerdetail);
      } else {
        if (marketTrade.length > 0) {
        }
      }
    });

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      let socketToken = token_socket.split("_")[0];
      console.log("socketToken", socketToken);
      socket_web.on("userDetails" + socketToken, async (response) => {
        console.log("socket user details", response);
        if (currentPair !== "") {
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
          // getMarketTrades(currentPair);
        } else {
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          if (fetchPair) {
            setcurrentPair(fetchPair);
            let view_pair = fetchPair.split("_")[0]+" / "+fetchPair.split("_")[1];
            setviewPair(view_pair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
            //getMarketTrades(fetchPair);
          }
        }
      });
      socket_web.on("cancelOrder" + socketToken, async (response) => {
        showSuccessToast(
          t("your-order-cancelled-by-admin-please-try-again-later")
        );
        if (currentPair !== "") {
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
        } else {
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          if (fetchPair) {
            setcurrentPair(fetchPair);
            let view_pair = fetchPair.split("_")[0]+" / "+fetchPair.split("_")[1];
            setviewPair(view_pair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
          }
        }
      });
    }
    socket.on("close", function () {
      socket.connect();
      setorderbooksloader(true);
      socketinit();
    });
    socket_web.on("close", function () {
      socket_web.connect();
    });
  };

  useEffect(() => {
    fetchTheme();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    return () => {
      socket_web.disconnect();
    };
  }, [socket_web]);

  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair) => {
    let themeLocal = localStorage.getItem("theme");

    const widgetOptions = {
      symbol: pair,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        env.apiHost + "chartapi/chart"
      ),
      interval: pair == "USDC_EUR" ? "240" : "1",
      container_id: "tv_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      //autosize: true,
      width: "100%",
      height: "518",
      studies_overrides: {},
      loading_screen: {
        backgroundColor: themeLocal === "light" ? "#fff" : "#151717",
      },
      theme: theme,
      toolbar_bg: "#151717",
      timezone: "Asia/Kolkata",
      overrides: {
        "paneProperties.background": "#151717",
        "paneProperties.vertGridProperties.color": "transparent",
        "paneProperties.horzGridProperties.color": "transparent",
      },
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => { },
          })
        );
      });
    });
  };

  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setTheme("White");
      } else {
        setTheme("Dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("Dark");
    }
    let urls = window.location.href;
    let selected_pair = urls.split("trade/")[1];
    if (!isEmpty(selected_pair)) {
      let themeValue = "Dark";
      if (theme == "light") {
        themeValue = "White";
      } else if (theme == "dark") {
        themeValue = "Dark";
      }
      buildchart(themeValue, selected_pair);
    }
  };

  const selectPair = async (pair) => {
    var replace_string = pair.replace("_", "/");
    var changelower = replace_string.toLowerCase();
    socket.emit("GetOrderBook", { symbol: replace_string });
    setchartPair(replace_string);
    setPair(pair);
    socketinit();
  };

  const Movelogin = () => {
    navigate("/login");
  };

  const getCurrpairDatas = async (pair) => {
    var data = {
      apiUrl: apiService.getCurrpairData,
      payload: { pair: pair },
    };
    var fetchticker = await postMethod(data);
    if (fetchticker) {
      setpairDetails(fetchticker.data);
      Setfromcurrency(fetchticker.fromCurr);
    }
  };

  const [pairName, setPairName] = useState("USDC");
  const selectPairbyCurrency = async (curr) => {
    setPairLoader(true);
    setsideLoader(true);
    setPairName(curr);
    socket.emit("GetTickerPrice", "getall");
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: { currency: curr },
    };
    var pairdetail = await postMethod(data);
    if (pairdetail) {
      socket.on("DashTickerPrice", async (response) => {
        setPairLoader(false);
        // setsideLoader(false);
        var tickarr = Object.values(response.data ? response.data : []);
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index]; // Current pair element
          const replace_string = element.pair.replace("_", "");
          const changelower = replace_string;

          // Find matching currency in tickarr
          const matchingTicker = tickarr.find(
            (tick) => tick.currency === changelower
          );

          if (matchingTicker) {
            pairdetail.data[index]["price_change"] = matchingTicker.change_pct
              ? parseFloat(matchingTicker.change).toFixed(
                pairDetails?.liq_price_decimal
              )
              : 0.0;
            pairdetail.data[index]["lastprice"] = matchingTicker.last
              ? matchingTicker.last
              : 0.0;
          }
        }
      });
      await setsearchInputlist(pairdetail.data);
      if (searchpair != null) {
        setpairlist(
          searchInputlistref.current.filter(function (tag) {
            if (
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0 ||
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0
            ) {
              return tag;
            }
          })
        );
      } else {
        await setpairlist(pairdetail.data);
        await setsearchInputlist(pairdetail.data);
        setPairLoader(false);
        setsideLoader(false);
      }
    }
  };

  async function handleInputChange(event) {
    event.preventDefault();

    const sanitizedValue = event.target.value.replace(/\s+/g, "");

    if (sanitizedValue.indexOf("_") > 0) {
      var searchval = sanitizedValue.replace("_", "");
      setcurrentpairs(sanitizedValue);
      pair_change();
      setpairlist(
        allpairslistref.current.filter(function (tag) {
          if (
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
            0 ||
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
            0
          ) {
            return tag;
          }
        })
      );
    } else {
      setsearchpair(sanitizedValue);
      setpairlist(
        searchInputlistref.current.filter(function (tag) {
          if (
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0 ||
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );
    }
  }

  const pairChange = async (pair) => {
    try {
      setPairLoader(true);
      navigate("/trade/" + pair.pair);
      setcurrentPair(pair.pair);
      let view_pair = pair.pair.split("_")[0]+" / "+pair.pair.split("_")[1];
      setviewPair(view_pair);
      getCurrpairDatas(pair.pair);

      var replace_string = pair.pair.replace("_", "/");
      socket.emit("GetOrderBook", { symbol: replace_string });
      setchartPair(replace_string);
      socketinit();

      var indexPage = pairlist.findIndex((x) => x.pair == pair.pair);

      if (indexPage != -1) {
        var getPair = pairlist[indexPage];
        var fromcurr = getPair.pair.split("_")[0];
        var toCurr = getPair.pair.split("_")[1];
        setFromCurrenncy(fromcurr);
        setToCurrenncy(toCurr);
        if (checkAuth === true) {
          getUserbalance(pair.pair);
          getCancelOrders(1);
          getActiveOrders(1, pair.pair);
        }
        getCurrpairDatas(getPair.pair);
        // fetchTickerPrice(getPair.pair);
        //getMarketTrades(getPair.pair);
        setPair(getPair.pair);
        setPairLoader(false);
        var themevalue =
          localStorage.getItem("theme") == "light" ? "White" : "Dark";

        buildchart(themevalue, pair.pair);

        // var replace_string = getPair.pair.replace("_", "/");
        // var replace_string = pair.pair.replace("_", "/");
        // var changelower = replace_string.toLowerCase();
        // socket.emit("GetOrderBook", { symbol: replace_string });
        // setchartPair(replace_string);
        // socketinit();
      }
    } catch (error) { }
  };

  const getUserbalance = async (pair) => {
    var obj = {
      pair: pair,
    };
    var data = {
      apiUrl: apiService.getparUserBalance,
      payload: obj,
    };
    setSiteLoader(true);
    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      var getFromBalance = resp.data.fromCurrency;
      var getToBalance = resp.data.toCurrency;
      setFromBalance(getFromBalance.totalBalance);
      settobalance(getToBalance.totalBalance);
    } else {
    }
  };

  const pair_change = async () => {
    try {
      if (currentpairsref.current.indexOf("_") > 0) {
        var pairname = currentpairsref.current;
        var indexPage = pairlist.findIndex((x) => x.pair == pairname);
        if (indexPage != -1) {
          setPairLoader(true);
          navigate("/trade/" + pairname);
          setcurrentPair(pairname);
          let view_pair = pairname.split("_")[0]+" / "+pairname.split("_")[1];
          setviewPair(view_pair);
          getCurrpairDatas(pairname);
          var getPair = pairlist[indexPage];
          var fromcurr = getPair.pair.split("_")[0];
          var toCurr = getPair.pair.split("_")[1];
          setFromCurrenncy(fromcurr);
          setToCurrenncy(toCurr);
          if (checkAuth === true) {
            getUserbalance(pair.pair);
            getCancelOrders(1);
            getActiveOrders(1, pair.pair);
          }
          getCurrpairDatas(getPair.pair);
          // fetchTickerPrice(getPair.pair);
          //getMarketTrades(getPair.pair);
          setPair(getPair.pair);
          setPairLoader(false);
        }
      }
    } catch (error) { }
  };

  const allpairDatas = async () => {
    var data = {
      apiUrl: apiService.allpairs,
    };
    var allpairs = await getMethod(data);
    if (allpairs) {
      setallpairslist(allpairs.data);
    }
  };

  const initialFormValue = {
    price: "",
    amount: "",
    total: "",
    stop_price: "",
    sellprice: "",
    sellamount: "",
    selltotal: "",
    sellstop_price: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();

  const {
    price,
    amount,
    total,
    stop_price,
    sellprice,
    sellamount,
    selltotal,
    sellstop_price,
  } = formValue;
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (/[eE\+\-]/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (/[eE\+\-]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/[eE\+\-]/.test(value)) {
      let formData = { ...formValue, ...{ [name]: value } };
      if (curnt_Ordertype_tabref.current == "Limit") {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.price * formData.amount;
          formData = {
            ...formData,
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else if (curnt_Ordertype_tabref.current == "Market") {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = +marketPriceref.current * formData.amount;
          formData = {
            ...formData,
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.price * formData.amount;
          formData = {
            ...formData,
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      }

      if (formData.price < 0) {
        showErrorToast(t("enter-valid-price"));
      }
      if (formData.amount < 0) {
        showErrorToast(t("enter-valid-amount-trade"));
      }

      setFormValue(formData);
    } else {
    }
  };

  // const handleChange_total = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;

  //   let formData = { ...formValue, ...{ [name]: value } };
  //   if (curnt_Ordertype_tabref.current == "Limit") {
  //     let amount = value / formData.price;
  //     formData = {
  //       ...formData,
  //       ...{ ["total"]: parseFloat(value) },
  //       ...{ ["amount"]: parseFloat(amount).toFixed(8) },
  //     };
  //   } else if (curnt_Ordertype_tabref.current == "Market") {
  //     let amount = value / +marketPriceref.current;
  //     formData = {
  //       ...formData,
  //       ...{ ["total"]: parseFloat(value) },
  //       ...{ ["amount"]: parseFloat(amount).toFixed(8) },
  //     };
  //   } else {
  //     let amount = value / formData.price;
  //     formData = {
  //       ...formData,
  //       ...{ ["total"]: parseFloat(value) },
  //       ...{ ["amount"]: parseFloat(amount).toFixed(8) },
  //     };
  //   }

  //   if (formData.price < 0) {
  //     showErrorToast(t("enter-valid-price"));
  //   }
  //   if (formData.amount < 0) {
  //     showErrorToast(t("enter-valid-amount-trade"));
  //   }
  //   if (formData.total < 0) {
  //     showErrorToast(t("enter-valid-total"));
  //   }
  //   setFormValue(formData);
  // };


  const handleChange_total = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/[eE\+\-]/.test(value)) {
      let formData = { ...formValue, ...{ [name]: value } };
      if (curnt_Ordertype_tabref.current == "Limit") {
        if (formData.selltotal != "") {
          let totalPrice = formData.selltotal / formData.sellprice;
          formData = {
            ...formData,
            ...{ ["sellamount"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.total / formData.price;
          formData = {
            ...formData,
            ...{ ["amount"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else if (curnt_Ordertype_tabref.current == "Market") {
        if (formData.selltotal != "") {
          let totalPrice = formData.selltotal / formData.sellprice;
          formData = {
            ...formData,
            ...{ ["sellamount"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.total / +marketPriceref.current;
          formData = {
            ...formData,
            ...{ ["amount"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else {
        if (formData.selltotal != "") {
          let totalPrice = formData.selltotal / formData.sellprice;
          formData = {
            ...formData,
            ...{ ["sellamount"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.total / formData.price;
          formData = {
            ...formData,
            ...{ ["amount"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      }

      if (formData.price < 0) {
        showErrorToast(t("enter-valid-price"));
      }
      if (formData.amount < 0) {
        showErrorToast(t("enter-valid-amount-trade"));
      }
      if(formData.total < 0)
      {
        showErrorToast(t("enter-valid-total"));
      }

      setFormValue(formData);
    } else {
    }
  };
  const [choosingTradeType, setchoosingTradeType] = useState("limit");
  const choosingTrade = (value) => {
    setchoosingTradeType(value);
    formValue.amount = "";
    formValue.sellamount = "";
    formValue.total = "";
    formValue.selltotal = "";
  };

  const [
    choosingHistoryTypes,
    setchoosingHistoryType,
    choosingHistoryTypesref,
  ] = useState("OpenOrders");
  const choosingHistoryType = (value) => {
    setchoosingHistoryType(value);
  };
  const orderPlace = async (ordertype, ordertab) => {
    try {
      var checkPrice = 0;
      if (ordertype == "Limit") {
        checkPrice = formValue.price;
      } else if (ordertype == "Market") {
        checkPrice = marketPrice;
      } else {
        checkPrice = formValue.price;
      }
      if (formValue.amount > 0 && checkPrice > 0 && total) {
        // if(frombalance >= total){
        if (ordertype == "Limit") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(formValue.price),
            total: parseFloat(total),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Market") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(marketPrice),
            total: marketPrice * parseFloat(formValue.amount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }

        if (ordertype == "Stop") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(formValue.price),
            total: formValue.price * parseFloat(formValue.amount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: parseFloat(formValue.stop_price),
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        var data = {
          apiUrl: apiService.orderPlaceapi,
          payload: orderData,
        };
        setorderloader(true);
        var resp = await postMethod(data);
        setorderloader(false);
        setSliderValue("0");
        setSliderValue1("0");
        setSliderValue2("0");
        setSliderValue3("0");
        setSliderValue4("0");
        setSliderValue5("0");
        if (resp.status === true) {
          formValue.amount = "";
          // fetchTickerPrice(currentPair);
          //   if(curnt_Ordertype_tabref.current=="Stop")
          //    {
          //  // formValue.stop_price = marketPrice;
          //   formValue.price = "";
          //    }
          //    else
          //    {
          //       formValue.price = marketPrice;
          //    }
          formValue.total = "";
          formValue.stop_price = "";
          getActiveOrders(1, currentPair);
          if(resp.message=="Order placed successfully."){
          showSuccessToast(t("Order placed successfully."));
          }
          getUserbalance(currentPair);
        } else {
          formValue.amount = "";
          //fetchTickerPrice(currentPair);
          formValue.total = "";
          formValue.stop_price = "";
          if(resp.message=="Invalid ID format provided."){
            showSuccessToast(t("Invalid ID format provided."));
             }else  if(resp.message=="Amount and price should be valid numeric values."){
              showSuccessToast(t("Amount and price should be valid numeric values."));
             }else  if(resp.message=="KYB not verified."){
              showSuccessToast(t("KYB not verified."));
               }else  if(resp.message=="KYC not verified."){
             showSuccessToast(t("KYC not verified."));
              }else  if(resp.message=="Invalid trading pair"){
               showSuccessToast(t("Invalid trading pair"));
              }else  if(resp.message.includes("Quantity")){
               var wordsAfterMaximum = resp.Message.split("least")[1].trim()
               showSuccessToast(t("Quantity should be at least"+wordsAfterMaximum));
                }else  if(resp.message.includes("Amount should")){
                var wordsAfterMaximum = resp.Message.split("than")[1].trim()
                showSuccessToast(t("Amount should not have more than"+wordsAfterMaximum+"decimal places."));
                 }else  if(resp.message.includes("Price should")){
                  var wordsAfterMaximum = resp.Message.split("than")[1].trim()
                  showSuccessToast(t("Price should not have more than"+wordsAfterMaximum+"decimal places."));
                   }else  if(resp.message.includes("Order total should be at least")){
                    var wordsAfterMaximum = resp.Message.split("least")[1].trim()
                   showSuccessToast(t("Order total should be at least"+wordsAfterMaximum));
                    }else  if(resp.message=="Trigger price is required for stop_loss and take_profit orders"){
                     showSuccessToast(t("Trigger price is required for stop_loss and take_profit orders"));
                      }else  if(resp.message=="Something went wrong, Please try again later"){
                       showSuccessToast(t("Something went wrong, Please try again later"));
                        }else  if(resp.message=="Insufficient balance for buy order."){
                         showSuccessToast(t("Insufficient balance for buy order"));
                          }else  if(resp.message=="Insufficient balance for sell order."){
                       showSuccessToast(t("Insufficient balance for sell order."));
                       }
          // showErrorToast(resp.message);
        }
        // }else{
        //   showErrorToast(t("insufficient-funds"));
        // }
      } else {
        showErrorToast(t("enter-all-fields"));
      }
    } catch (error) {
      showErrorToast(t("please-try-again-later"));
    }
  };
  const sellorderPlace = async (ordertype, ordertab) => {
    try {
      var checkPrice = 0;
      if (ordertype == "Limit") {
        checkPrice = formValue.sellprice;
      } else if (ordertype == "Market") {
        checkPrice = marketPrice;
      } else {
        checkPrice = formValue.sellprice;
      }
      if (formValue.sellamount > 0 && checkPrice > 0 && selltotal) {
        // if(frombalance >= amount ){
        if (ordertype == "Limit") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(formValue.sellprice),
            total: parseFloat(selltotal),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Market") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(marketPrice),
            total: marketPrice * parseFloat(formValue.sellamount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Stop") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(formValue.sellprice),
            total: formValue.sellprice * parseFloat(formValue.sellamount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: parseFloat(formValue.sellstop_price),
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }

        var data = {
          apiUrl: apiService.orderPlaceapi,
          payload: orderData,
        };

        setsellorderloader(true);
        var resp = await postMethod(data);
        setsellorderloader(false);

        setSliderValue("0");
        setSliderValue1("0");
        setSliderValue2("0");
        setSliderValue3("0");
        setSliderValue4("0");
        setSliderValue5("0");
        if (resp.status) {
          setsellorderloader(false);
          showSuccessToast(resp.message);
          formValue.sellamount = "";
          // fetchTickerPrice(currentPair);
          // if(curnt_Ordertype_tabref.current=="Stop")
          //  {
          // //formValue.stop_price = marketPrice;
          // formValue.sellprice = "";
          //  }
          //  else
          //  {
          //     formValue.sellprice = marketPrice;
          //  }
          formValue.selltotal = "";
          formValue.sellstop_price = "";
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
        } else {
          setsellorderloader(false);
          formValue.sellamount = "";
          // if(curnt_Ordertype_tabref.current=="Stop")
          //  {
          // //formValue.stop_price = marketPrice;
          // formValue.sellprice = "";
          //  }
          //  else
          //  {
          //     formValue.sellprice = marketPrice;
          //  }
          formValue.selltotal = "";
          formValue.sellstop_price = "";
          showErrorToast(resp.message);
        }
        // }else{
        //   // showErrorToast(t("insufficient-funds"));
        // }
      } else {
        showErrorToast(t("enter-all-fields"));
      }
    } catch (error) {
      showErrorToast(t("please-try-again-later"));
    }
  };

  const buy_sell_percentage = (percentage) => {
    if (checkAuth) {
      if (percentage == 25) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.price = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 50) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.price = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 75) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.price = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 100) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.price = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }

      if (currentTyperef.current == "buy") {
        var total = (+percentage * +tobalance) / 100;
        // if (total <= 0) {
        //   showErrorToast(t("quantity-cannot-less-than-0.0000"));
        // }

        var amt = total / +formValue.price;

        // formValue.amount = amt.toFixed(pairDetails?.liq_amount_decimal);
        // formValue.total = +total.toFixed(pairDetails?.liq_price_decimal);
        formValue.amount = amt.toFixed(8);
        formValue.total = +total.toFixed(8);
      } else if (currentTyperef.current == "sell") {
        var total = (+percentage * +frombalance) / 100;
        // if (total <= 0) {
        //   showErrorToast(t("quantity-cannot-less-than-0.00000000"));
        // }
        var tot = total * +formValue.price;
        // formValue.amount = total.toFixed(pairDetails?.liq_price_decimal);
        // formValue.total = +tot.toFixed(pairDetails?.liq_price_decimal);
        formValue.amount = total.toFixed(8);
        formValue.total = +tot.toFixed(8);
      }
    } else {
    }
  };

  const buy_sell_percentage1 = (percentage) => {
    if (checkAuth) {
      if (percentage == 25) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.sellprice = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 50) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.sellprice = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 75) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.sellprice = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }
      if (percentage == 100) {
        // if (curnt_Ordertype_tabref.current != "Stop") {
        //   formValue.sellprice = (+marketPriceref.current).toFixed(
        //     pairDetails?.liq_price_decimal
        //   );
        // }
      }

      if (currentTyperef.current == "buy") {
        var total = (+percentage * +tobalance) / 100;

        // if (total <= 0) {
        //   showErrorToast(t("quantity-cannot-less-than-0.0000"));
        // }

        var amt = total / +formValue.sellprice;

        // formValue.amount = amt.toFixed(pairDetails?.liq_amount_decimal);
        // formValue.total = +total.toFixed(pairDetails?.liq_price_decimal);
        formValue.sellamount = amt.toFixed(8);

        formValue.selltotal = +total.toFixed(8);
      } else if (currentTyperef.current == "sell") {
        var total = (+percentage * +frombalance) / 100;
        // if (total <= 0) {
        //   showErrorToast(t("quantity-cannot-less-than-0.00000000"));
        // }

        var tot = total * +formValue.sellprice;

        // formValue.sellamount = total.toFixed(pairDetails?.liq_price_decimal);
        // formValue.total = +tot.toFixed(pairDetails?.liq_price_decimal);
        formValue.sellamount = total.toFixed(8);
        formValue.selltotal = +tot.toFixed(8);
      }
    } else {
    }
  };

  const getActiveOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) { }
  };

  const getstopLimitOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getStop_limit_Orders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // settotalactive(resp.count);
      } else {
      }
    } catch (error) { }
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: pairDetails.pair,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) { }
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: currentPair,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) { }
  };

  //========FETCH TICKER PRICE ==========//

  const fetchTickerPrice = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.fetch_tickers,
        payload: { pair: pair },
      };
      var fetchticker = await postMethod(data);
      if (fetchticker) {
        var data = await fetchticker.data;
        setpairTickerDetails(data);
        setmarketPrice(
          data.lastprice.lastprice
            ? parseFloat(data.lastprice.lastprice).toFixed(
              pairDetails?.liq_price_decimal
            )
            : 0.0
        );

        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (
          curnt_Ordertype_tabref.current == "Market" ||
          curnt_Ordertype_tabref.current == "Limit"
        ) {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        }
      }
    } catch (error) { }
  };

  // ================FETCH MARKET =============//

  const getMarketTrades = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.marketTrades,
        payload: { pair: pair },
      };
      // setauthentication(true);
      setmarketTradeloader(true);
      var fetchTradeHisotory = await postMethod(data);
      // setauthentication(false);
      if (fetchTradeHisotory) {
        if (fetchTradeHisotory.Message.length > 0) {
          setmarketTradeloader(false);
          setmarketTrade(fetchTradeHisotory.Message);
        } else {
          setmarketTradeloader(false);
          // setmarketTrade([]);
        }
      } else {
      }
    } catch (error) { }
  };

  const cancelPageChange = (event, pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (event, pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      setcancelloader(true);
      if (fetchTradeHisotory) {
        setcancelloader(false);
        showSuccessToast(
          t("order-cancelled-successfully-your-amount-credit-to-your-wallet")
        );
        getActiveOrders(1, currentPair);
        getUserbalance(currentPair);
      } else {
        showErrorToast(t("please-try-again-later"));
      }
    } catch (error) {
      showErrorToast(t("please-try-again-later"));
    }
  };

  const callCancelOrder = async () => {
    if (checkAuth === true) {
      getCancelOrders(1);
    }
  };

  const callOrdehistory = async () => {
    if (checkAuth === true) {
      tradeHistory(1);
    }
  };

  const addPrice = async (price) => {
    setpriceLoader(true);
    if (curnt_Ordertype_tabref.current == "Limit") {
      formValue.price = price.toFixed(pairDetails?.liq_price_decimal);
      formValue.sellprice = price.toFixed(pairDetails?.liq_price_decimal);
    }
  };

  return (
    <>
      <Header />
      <div className="header trade_dark ">
        <main className="min-height-100vh">
          <div className="trade_page_global">
            <>
              <div className="subHeader">
                <div className="inner_sub">
                  <div className="content">
                    <div class="left">
                      {/* <img
                        src={require("../assets/stars.png")}
                        width="20px"
                        height="20px"
                        className="mt-1"
                      /> */}
                      <div class="layout">
                        <div class="childrenContainer">
                          <div class="css-l36dyj">
                            <div class="css-4cffwv">
                              <div class="css-4h6mys">
                                <div data-bn-type="text" class="css-1qkv3vk">
                                  <h1> {viewPair}</h1>
                                </div>
                                <div class="css-f4kgqr">
                                  <a
                                    data-bn-type="link"
                                    href="https://www.binance.com/en/price"
                                    target="_blank"
                                    class="css-o1v5sz"
                                    disabled=""
                                  >
                                    {fromcurrencyref.current} {t("price")}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="css-10nf7hq">
                              <span
                                data-bn-type="text"
                                class="tag-group-container css-as916g"
                              >
                                <div class="tag-text css-4cffwv">
                                  <div
                                    data-bn-type="text"
                                    class="tag css-vurnku"
                                  >
                                    POW
                                  </div>
                                  <div
                                    data-bn-type="text"
                                    class="tag-margin css-vurnku"
                                  ></div>
                                  <div
                                    data-bn-type="text"
                                    class="tag css-vurnku"
                                  >
                                    Vol
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    class="css-omng2l"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="nowPrice">
                          <div class="showPrice css-13n52y">
                            {" "}
                            {isNaN(marketPriceref.current)
                              ? ""
                              : parseFloat(marketPriceref.current).toFixed(
                                pairDetails?.liq_price_decimal
                              )}
                          </div>
                          <div class="subPrice">{pairDetails.to_symbol}</div>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="tickerListContainer">
                        <div class="tickerList">
                          <div>
                            <div class="tickerItemLabel">
                              {t("trade-24h-change")}{" "}
                              <small className="small">
                                ({pairDetails.to_symbol})
                              </small>{" "}
                            </div>
                            <div class="tickerPriceText">
                              <span className="price_red">
                                {pairTickerDetailsref.current?.change <= 0 ? (
                                  <div className="tickerPriceText d-flex">
                                    {isNaN(pairTickerDetailsref.current?.change)
                                      ? ""
                                      : parseFloat(
                                        pairTickerDetailsref.current?.change
                                      ).toFixed(
                                        pairDetails?.liq_price_decimal
                                      )}
                                    <span className="ml-2 pink_text color-buy">
                                      {parseFloat(
                                        pairTickerDetailsref.current?.change_pct
                                      ).toFixed(2)}{" "}
                                      %
                                    </span>
                                  </div>
                                ) : (
                                  <div className="tickerPriceText d-flex">
                                    {isNaN(pairTickerDetailsref.current?.change)
                                      ? ""
                                      : parseFloat(
                                        pairTickerDetailsref.current?.change
                                      ).toFixed(
                                        pairDetails?.liq_price_decimal
                                      )}
                                    &nbsp; &nbsp;
                                    <span className=" ml-2 pink_text red-green">
                                      {parseFloat(
                                        pairTickerDetailsref.current?.change_pct
                                      ).toFixed(2)}{" "}
                                      %
                                    </span>
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">{t("24h-high")}</div>
                            <div class="tickerPriceText">
                              {" "}
                              {isNaN(pairTickerDetailsref.current?.high)
                                ? ""
                                : parseFloat(
                                  pairTickerDetailsref.current?.high
                                ).toFixed(
                                  pairDetails?.liq_price_decimal
                                )}{" "}
                              <small>{pairDetails.to_symbol}</small>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">{t("24h-low")}</div>
                            <div class="tickerPriceText">
                              {" "}
                              {isNaN(pairTickerDetailsref.current?.low)
                                ? ""
                                : parseFloat(
                                  pairTickerDetailsref.current?.low
                                ).toFixed(
                                  pairDetails?.liq_price_decimal
                                )}{" "}
                              <small>{pairDetails.to_symbol}</small>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">
                              {t("24h-Volume")}{" "}
                              <small>({pairDetails.from_symbol})</small>
                            </div>
                            <div class="tickerPriceText">
                              {isNaN(pairTickerDetailsref.current?.volume)
                                ? ""
                                : parseFloat(
                                  pairTickerDetailsref.current?.volume
                                ).toFixed(pairDetails?.liq_price_decimal)}
                            </div>
                          </div>
                          {/* <div>
                            <div class="tickerItemLabel">
                              {t("24h-Volume")}{" "}
                              <small>({pairDetails.to_symbol})</small>
                            </div>
                            <div class="tickerPriceText">
                              {isNaN(pairTickerDetailsref.current?.vwap)
                                ? ""
                                : parseFloat(
                                    pairTickerDetailsref.current?.vwap
                                  ).toFixed(pairDetails?.liq_price_decimal)}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="order_form">
                <ul class="nav nav-pills">
                  <li class="active">
                    <a data-toggle="pill" href="#home" className="active">
                      {t("spot")}
                    </a>
                  </li>
                </ul>
                <div class="tab-content tradeform">
                  <div id="home" class="tab-pane fade in active show">
                    <ul class="nav nav-pills">
                      <li
                        className={choosingTradeType == "limit" ? "active" : ""}
                      >
                        <a
                          className={
                            choosingTradeType == "limit" ? "active" : ""
                          }
                          onClick={() => choosingTrade("limit")}
                        >
                          {t("limit")}
                        </a>
                      </li>
                      <li
                        className={choosingTradeType == "limit" ? "active" : ""}
                      >
                        <a
                          className={
                            choosingTradeType == "market" ? "active" : ""
                          }
                          onClick={() => choosingTrade("market")}
                        >
                          {t("market")}
                        </a>
                      </li>
                      <li
                        className={choosingTradeType == "limit" ? "active" : ""}
                      >
                        <a
                          className={
                            choosingTradeType == "stop" ? "active" : ""
                          }
                          onClick={() => choosingTrade("stop")}
                        >
                          {t("stop-limit")}
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      {choosingTradeType == "limit" ? (
                        <div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(tobalance).toFixed(8)}{" "}
                                      {toCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      // id="numberInput"
                                      min="0"
                                      className="form-control"
                                      placeholder={t("price")}
                                      name="price"
                                      // value={parseFloat(price).toFixed(
                                      //   pairDetails?.liq_price_decimal
                                      // )}
                                      value={price}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ); // Allows only numbers
                                      }}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValueref.current}
                                        step={25}
                                        marks
                                        name="buy"
                                        min={0}
                                        max={100}
                                        onChange={handleSliderChange}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}

                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={total}
                                      name="total"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="buy_selecu  "
                                      onClick={Movelogin}
                                    >
                                      <Link
                                        to="/login"
                                        className="text-white log-button"
                                      >
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : orderloaderref.current == true ? (
                                    <Button className="buy_selecu">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buy_selecu"
                                      onClick={() => orderPlace("Limit", "buy")}
                                    >
                                      {t("buy")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(frombalance).toFixed(8)}{" "}
                                      {fromCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder={t("price")}
                                      name="sellprice"
                                      // value={parseFloat(sellprice).toFixed(
                                      //   pairDetails?.liq_price_decimal
                                      // )}
                                      value={sellprice}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValue1ref.current}
                                        step={25}
                                        marks
                                        min={0}
                                        name="sell"
                                        max={100}
                                        onChange={handleSliderChange1}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}

                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={selltotal}
                                      name="selltotal"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                {/* <div className="avali">
                            <p>
                              Max Sell{" "}
                              <span>
                                {" "}
                                {isNaN(maxsellref.current)
                                  ? 0
                                  : parseFloat(maxsellref.current)}{" "}
                                {toCurrency}{" "}
                              </span>
                            </p>
                          </div> */}
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="Sell"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : sellorderloaderref.current == true ? (
                                    <Button className="Sell">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="Sell"
                                      onClick={() =>
                                        sellorderPlace("Limit", "sell")
                                      }
                                    >
                                      {t("sell")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : choosingTradeType == "market" ? (
                        <div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(tobalance).toFixed(8)}{" "}
                                      {toCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("price")}
                                      value={parseFloat(marketPrice).toFixed(
                                        pairDetails?.liq_price_decimal
                                      )}
                                      name="price"
                                      disabled
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValue2ref.current}
                                        step={25}
                                        marks
                                        name="buy"
                                        min={0}
                                        max={100}
                                        onChange={handleSliderChange2}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}

                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={total}
                                      name="total"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                {/* <div className="avali">
                        <p>
                          {" "}
                          Max Buy{" "}
                          <span>
                            {" "}
                            {isNaN(maxbuyref.current)
                              ? 0
                              : parseFloat(maxbuy)}{" "}
                            {fromCurrency}{" "}
                          </span>
                        </p>
                      </div> */}
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="buy_selecu"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : orderloaderref.current == true ? (
                                    <Button className="buy_selecu">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buy_selecu"
                                      onClick={() =>
                                        orderPlace("Market", "buy")
                                      }
                                    >
                                      {t("buy")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(frombalance).toFixed(8)}{" "}
                                      {fromCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder={t("price")}
                                      // value={parseFloat(marketPrice).toFixed(
                                      //   pairDetails?.liq_price_decimal
                                      // )}
                                      value={sellprice}
                                      name="sellprice"
                                      disabled
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValue3ref.current}
                                        step={25}
                                        marks
                                        min={0}
                                        name="sell"
                                        max={100}
                                        onChange={handleSliderChange3}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}
                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={selltotal}
                                      name="selltotal"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                {/* <div className="avali">
                        <p>
                          {" "}
                          Max Sell{" "}
                          <span>
                            {" "}
                            {isNaN(maxsellref.current)
                              ? 0
                              : parseFloat(maxsell)}{" "}
                            {toCurrency}{" "}
                          </span>
                        </p>
                      </div> */}
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="Sell"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {" "}
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : sellorderloaderref.current == true ? (
                                    <Button className="Sell">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="Sell"
                                      onClick={() =>
                                        sellorderPlace("Market", "sell")
                                      }
                                    >
                                      {t("sell")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(tobalance).toFixed(8)}{" "}
                                      {toCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("limit")}
                                      value={price}
                                      name="price"
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("stop-limit")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder={t("stop")}
                                      value={stop_price}
                                      name="stop_price"
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValue4ref.current}
                                        step={25}
                                        marks
                                        name="buy"
                                        min={0}
                                        max={100}
                                        onChange={handleSliderChange4}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}
                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={total}
                                      name="total"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                {/* <div className="avali">
                       <p>
                         Max Buy{" "}
                         <span>
                           {" "}
                           {isNaN(maxbuyref.current)
                             ? 0
                             : parseFloat(maxbuy)}{" "}
                           {fromCurrency}{" "}
                         </span>
                       </p>
                     </div> */}
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="buy_selecu"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {" "}
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : orderloaderref.current == true ? (
                                    <Button className="buy_selecu">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buy_selecu"
                                      onClick={() => orderPlace("Stop", "buy")}
                                    >
                                      {t("buy")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_trade">
                                <div className="avali">
                                  <p>
                                    Avbl{" "}
                                    <span>
                                      {parseFloat(frombalance).toFixed(8)}{" "}
                                      {fromCurrency}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div className="form_right">
                                  <label>{t("price")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder={t("limit")}
                                      value={sellprice}
                                      name="sellprice"
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>
                                <div className="form_right">
                                  <label>{t("stop-limit")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder={t("stop")}
                                      value={sellstop_price}
                                      name="sellstop_price"
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{toCurrency}</span>
                                  </div>
                                </div>

                                <div className="form_right">
                                  <label>{t("amount")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                      onPaste={handlePaste}
                                      onKeyDown={handleKeyDown}
                                    />
                                    <span>{fromCurrency}</span>
                                  </div>
                                </div>
                                {!checkAuth ? (
                                  ""
                                ) : (
                                  <div className="slider_spacing">
                                    <Box>
                                      <Slider
                                        value={sliderValue5ref.current}
                                        step={25}
                                        marks
                                        name="sell"
                                        min={0}
                                        max={100}
                                        onChange={handleSliderChange5}
                                        sx={{
                                          "& .MuiSlider-track": {
                                            backgroundColor: "#888B93 ",
                                          },
                                          "& .MuiSlider-thumb": {
                                            backgroundColor: "#888B93",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </div>
                                )}
                                <div className="form_right">
                                  <label>{t("total")}</label>
                                  <div className="input_section">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={selltotal}
                                      name="selltotal"
                                      onChange={handleChange_total}
                                    />
                                  </div>
                                </div>
                                {/* <div className="avali">
                       <p>
                         Max Buy{" "}
                         <span>
                           {" "}
                           {isNaN(maxsellref.current)
                             ? 0
                             : parseFloat(maxsell)}{" "}
                           {toCurrency}{" "}
                         </span>
                       </p>
                     </div> */}
                                <div className="buy_BTN">
                                  {!checkAuth ? (
                                    <Button
                                      className="Sell"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>
                                  ) : sellorderloaderref.current == true ? (
                                    <Button className="Sell">
                                      {t("loading")}...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="Sell"
                                      onClick={() =>
                                        sellorderPlace("Stop", "sell")
                                      }
                                    >
                                      {t("sell")}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="order_book">
                <div className="d-flex span-div justify-content-between">
                  <span>{t("order-book")}</span>
                </div>
                <div className="contant_scetion">
                  <div className="orderbook-header ">
                    <div class="orderbook-header-tips">
                      <button
                        data-bn-type="button"
                        data-testid="defaultModeButton"
                        class=" css-sz6ky9"
                        onClick={Fullorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v7H4V4z" fill="#F6465D"></path>
                          <path d="M4 13h7v7H4v-7z" fill="#0ECB81"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </button>
                      <button
                        data-bn-type="button"
                        data-testid="buyModeButton"
                        class=" css-1meiumy"
                        onClick={bidorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v16H4V4z" fill="#0ECB81"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </button>
                      <button
                        data-bn-type="button"
                        data-testid="sellModeButton"
                        class=" css-1meiumy"
                        onClick={askorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v16H4V4z" fill="#F6465D"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="market_order">
                    <article>
                      <section class="orderbook-header ml-0 mr-0">
                        <table width="100%">
                          <tr>
                            <th class="price" width="25%">
                              {t("price")}({pairDetails.to_symbol})
                            </th>
                            <th width="25%">
                              {t("amount")}({pairDetails.from_symbol})
                            </th>
                            <th width="25%">{t("total")}</th>
                          </tr>
                        </table>
                      </section>

                      {orderbooksloaderref.current == true ? (
                        <i class="fa-solid fa-spinner text-center fa-spin"></i>
                      ) : (
                        <>
                          <section class="side" id="asks">
                            <table width="100%" className="green_content">
                              {orderbookLoader == false ? (
                                ""
                              ) : orderbookaskref.current.length > 0 ? (
                                orderbookaskref.current
                                  .slice(0, 20)
                                  .map((ask, i) => {
                                    return (
                                      <tr data-width="70">
                                        <td
                                          width="25%"
                                          style={{ cursor: "pointer" }}
                                          className="color-buy price sell priceclick"
                                          onClick={() => addPrice(ask[0])}
                                        >
                                          {ask.price}
                                        </td>
                                        <td width="25%">{ask.amount}</td>
                                        <td width="25%">{ask.total}</td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  {" "}
                                  <td colSpan="3">
                                    {" "}
                                    {t("data-not-found")}
                                  </td>{" "}
                                </tr>
                              )}
                            </table>
                          </section>
                          {orderbookdivider == false ? (
                            ""
                          ) : (
                            <section class="divider">
                              <div class="current-price">
                                {parseFloat(marketPrice).toFixed(
                                  pairDetails?.liq_price_decimal
                                )}
                              </div>
                            </section>
                          )}
                          <section class="side bids">
                            <table width="100%">
                              {orderbookLoaderbid == false ? (
                                ""
                              ) : orderbookbidref.current.length > 0 ? (
                                orderbookbidref.current
                                  .slice(0, 20)
                                  .map((bid, i) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          width="25%"
                                          className="red-green price buy priceclick"
                                          onClick={() => addPrice(bid[0])}
                                        >
                                          {bid.price}
                                        </td>
                                        <td width="25%">{bid.amount}</td>
                                        <td width="25%">{bid.total}</td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  {" "}
                                  <td colSpan="3">
                                    {" "}
                                    {t("data-not-found")}
                                  </td>{" "}
                                </tr>
                              )}
                            </table>
                          </section>
                        </>
                      )}
                    </article>
                  </div>
                </div>
              </div>

              <div className="chart_trade">
                <div id="tv_chart_container"></div>
              </div>

              <div className="trades pb-0">
                <div className="">
                  <div className="form_seldect__pair pt-0">
                    <ul class="nav nav-pills my-3">
                      <li class={tradeChoosingType == "mtrade" ? "active" : ""}>
                        <a
                          data-toggle="pill"
                          // href="#mtrade"
                          onClick={() => tradechoose("mtrade")}
                          className={
                            tradeChoosingType == "mtrade" ? "active" : ""
                          }
                        >
                          {t("market-trades")}
                        </a>
                      </li>
                      <li
                        class={tradeChoosingType == "mytrade" ? "active" : ""}
                      >
                        <a
                          data-toggle="pill"
                          // href="#mytrade"
                          onClick={() => tradechoose("mytrade")}
                          className={
                            tradeChoosingType == "mytrade" ? "active" : ""
                          }
                        >
                          {t("my-trades")}
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content pair_details">
                      {tradeChoosingType == "mtrade" ? (
                        <>
                          <div class="tab-pane fade in active show">
                            <div class="fixTableHead mt-2">
                              <table>
                                <thead>
                                  <tr>
                                    <th className="">
                                      {t("price")} ({pairDetails.to_symbol})
                                    </th>
                                    <th className="text-end ">
                                      {t("volume")} ({pairDetails.from_symbol})
                                    </th>
                                    <th className="text-end ">{t("time")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    // !checkAuth ? (
                                    //   <tr>
                                    //     {" "}
                                    //     <td colSpan="3">
                                    //       <Button
                                    //         className="Butn_header my-4 d-block mx-auto"
                                    //         onClick={Movelogin}
                                    //       >
                                    //         <Link
                                    //           to="/login"
                                    //           className="text-white"
                                    //         >
                                    //           {t("login-to-continue")}
                                    //         </Link>
                                    //       </Button>{" "}
                                    //     </td>{" "}
                                    //   </tr>
                                    // )
                                    // :
                                    marketTrade.length > 0 ? (
                                      marketTrade.map((item, i) => {
                                        {
                                          {
                                          }
                                        }
                                        return (
                                          <tr className="position_rel_over">
                                            {item.tradeType == "buy" ? (
                                              <td>
                                                <span className="color-buy">
                                                  {" "}
                                                  {item.price}{" "}
                                                </span>
                                              </td>
                                            ) : (
                                              <td>
                                                <span className="red-green">
                                                  {" "}
                                                  {item.price}{" "}
                                                </span>
                                              </td>
                                            )}
                                            <td className="text-right">
                                              {item.amount}{" "}
                                            </td>
                                            <td className="text-right">
                                              {moment(
                                                Math.abs(item.time * 1000)
                                              ).format("hh:mm")}{" "}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="3"
                                          className="text-center mt-4"
                                        >
                                          {t("no-market-trades")}
                                        </td>
                                      </tr>
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="tab-pane fade in active show ">
                            <div class="fixTableHead mt-2">
                              <table>
                                <thead>
                                  <tr>
                                    <th>
                                      {t("price")} ({pairDetails.to_symbol})
                                    </th>
                                    <th className="text-right">
                                      {t("volume")} ({pairDetails.from_symbol})
                                    </th>
                                    <th className="text-right">{t("time")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!checkAuth ? (
                                    <tr>
                                      {" "}
                                      <td colSpan="3">
                                        <Button
                                          className="Butn_header my-4 d-block mx-auto"
                                          onClick={Movelogin}
                                        >
                                          <Link
                                            to="/login"
                                            className="text-white"
                                          >
                                            {t("login-to-continue")}
                                          </Link>
                                        </Button>{" "}
                                      </td>{" "}
                                    </tr>
                                  ) : tradeHistoryData.length > 0 ? (
                                    tradeHistoryData.map((item, i) => {
                                      return (
                                        <tr className="position_rel_over">
                                          {item.tradeType == "buy" ? (
                                            <td>
                                              <span className="color-buy">
                                                {" "}
                                                {item.askPrice}{" "}
                                              </span>
                                            </td>
                                          ) : (
                                            <td>
                                              <span className="red-green">
                                                {" "}
                                                {item.askPrice}{" "}
                                              </span>
                                            </td>
                                          )}
                                          <td className="text-right">
                                            {item.askAmount}{" "}
                                          </td>
                                          <td className="text-right">
                                            {moment(item.created_at).format(
                                              "hh:mm:ss"
                                            )}{" "}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="text-center mt-4"
                                      >
                                        {t("no-market-trades")}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="markets">
                <div className="form_seldect__pair">
                  <div className="searcj">
                    <i class="ri-search-line"></i>
                    <input
                      type="text"
                      placeholder={t("Search-Pair")}
                      onChange={handleInputChange}
                      name="searchpair"
                      minLength={1}
                      maxLength={15}
                      value={searchpair}
                    />
                  </div>
                  <ul class="nav nav-pills">
                    {/* <li>
                      <a data-toggle="pill" href="#fav">
                        <i class="ri-star-s-fill"></i>
                      </a>
                    </li> */}
                    <li className={pairName == "USDC" ? "active" : ""}>
                      <a
                        data-toggle="tab"
                        // href="#USDC"
                        className={pairName == "USDC" ? "active" : ""}
                        onClick={() => selectPairbyCurrency("USDC")}
                      >
                        USDC
                      </a>
                    </li>
                    <li className={pairName == "EUR" ? "active" : ""}>
                      <a
                        data-toggle="tab"
                        // href="#RPTC"
                        className={pairName == "EUR" ? "active" : ""}
                        onClick={() => selectPairbyCurrency("EUR")}
                      >
                        EUR
                      </a>
                    </li>
                    {/* <li>
                      <a
                        data-toggle="tab"
                        href="#BTC"
                        onClick={() => selectPairbyCurrency("BTC")}
                      >
                        BTC
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        data-toggle="tab"
                        href="#ETH"
                        onClick={() => selectPairbyCurrency("ETH")}
                      >
                        ETH
                      </a>
                    </li> */}
                  </ul>
                  <div class="tab-content pair_details">
                    <div id="USDT" className="tab-pane fade in active show">
                      <div class="fixTableHead mt-2">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("pair")}</th>
                              <th className="text-end">{t("price")}</th>
                              <th className="text-end">{t("change")}</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/* <tr>
                          <td>
                            <p>
                              {" "}
                              <i class="ri-star-s-fill"></i>1INCH <span>/BTC</span>
                            </p>
                          </td>
                          <td className="text-right">0.00000881</td>
                          <td className="text-right">
                            <span className="text-green">+0.69%</span>
                          </td>
                        </tr> */}

                            {sideLoader == true ? (
                              <tr>
                                <td colSpan="3" className="text-center">
                                  <i class="fa-solid fa-spinner fa-spin text-center tradeicons"></i>
                                </td>
                              </tr>
                            ) : pairlistref.current.length < 0 ? (
                              "NO Data"
                            ) : (
                              pairlistref.current &&
                              pairlistref.current.map((obj, i) => {
                                return (
                                  <>
                                    {obj.pair === pair ? (
                                      <tr
                                        className="pair_section active curpoint"
                                        onClick={() => pairChange(obj)}
                                      >
                                        <td>
                                          <div className="d-flex align-items-center gap-2">
                                            <img
                                              src={obj.Currency_image}
                                              className=""
                                            />

                                            <h2>
                                              {obj.from_symbol}/
                                              <small className="usdc-dark-symbol">
                                                {obj.to_symbol}
                                              </small>
                                            </h2>
                                          </div>
                                        </td>
                                        <td className="text-right">
                                          <div className="price_symbol">
                                            <small>
                                              <span class="material-symbols-outlined">
                                                {/* currency_rupee */}
                                              </span>
                                            </small>
                                            {obj.lastprice == null ||
                                              obj.lastprice == undefined ||
                                              obj.lastprice == "" ? (
                                              <p className="color">0.0</p>
                                            ) : obj.lastprice <= 0 ? (
                                              <span className="color-buy">
                                                {parseFloat(
                                                  obj.lastprice
                                                ).toFixed(
                                                  pairDetails?.liq_price_decimal
                                                )}
                                              </span>
                                            ) : (
                                              <span className="text-green">
                                                {parseFloat(
                                                  obj.lastprice
                                                ).toFixed(
                                                  pairDetails?.liq_price_decimal
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                        <td className="text-right">
                                          {obj.price_change <= 0 ? (
                                            <p>
                                              <i class="bi bi-caret-down-fill color-buy"></i>{" "}
                                              <span className="color-buy">
                                                {" "}
                                                {obj.price_change}%
                                              </span>
                                            </p>
                                          ) : (
                                            <p>
                                              <i class="bi bi-caret-up-fill red-green"></i>{" "}
                                              <span className="red-green">
                                                {obj.price_change}%
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        className="pair_section curpoint"
                                        onClick={() => pairChange(obj)}
                                      >
                                        <td>
                                          <div className="d-flex align-items-center gap-2">
                                            <img
                                              src={obj.Currency_image}
                                              className=""
                                            />

                                            <h2>
                                              {obj.from_symbol}/
                                              <small className="usdc-dark-symbol">
                                                {obj.to_symbol}
                                              </small>
                                            </h2>
                                          </div>
                                        </td>
                                        <td className="text-right">
                                          <div className="price_symbol">
                                            <small>
                                              <span class="material-symbols-outlined">
                                                {/* currency_rupee */}
                                              </span>
                                            </small>
                                            {obj.lastprice == null ||
                                              obj.lastprice == undefined ||
                                              obj.lastprice == "" ? (
                                              ""
                                            ) : // setsideLoader(true)""
                                              obj.lastprice <= 0 ? (
                                                <span className="color-buy">
                                                  {parseFloat(
                                                    obj.lastprice
                                                  ).toFixed(
                                                    pairDetails?.liq_price_decimal
                                                  )}
                                                </span>
                                              ) : (
                                                <span className="trade-text-green">
                                                  {parseFloat(
                                                    obj.lastprice
                                                  ).toFixed(
                                                    pairDetails?.liq_price_decimal
                                                  )}
                                                </span>
                                              )}
                                          </div>
                                        </td>
                                        <td className="text-right">
                                          {obj.price_change <= 0 ? (
                                            <p>
                                              <i class="bi bi-caret-down-fill color-buy"></i>{" "}
                                              <span className="color-buy">
                                                {" "}
                                                {obj.price_change}%
                                              </span>
                                            </p>
                                          ) : (
                                            <p>
                                              <i class="bi bi-caret-up-fill red-green"></i>{" "}
                                              <span className="red-green">
                                                {obj.price_change}%
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="basictable tradeform">
                <ul class="nav nav-pills my-4">
                  <li
                    className={
                      choosingHistoryTypesref.current == "OpenOrders"
                        ? "active"
                        : ""
                    }
                    onClick={() => choosingHistoryType("OpenOrders")}
                  >
                    <a
                      className={
                        choosingHistoryTypesref.current == "OpenOrders"
                          ? "active"
                          : ""
                      }
                    >
                      {t("open-orders")}
                    </a>
                  </li>
                  <li
                    className={
                      choosingHistoryTypesref.current == "OrderHistory"
                        ? "active"
                        : ""
                    }
                    onClick={() => choosingHistoryType("OrderHistory")}
                  >
                    <a
                      className={
                        choosingHistoryTypesref.current == "OrderHistory"
                          ? "active"
                          : ""
                      }
                      onClick={callOrdehistory}
                    >
                      {t("order-history")}
                    </a>
                  </li>
                  <li
                    className={
                      choosingHistoryTypesref.current == "OrderBook"
                        ? "active"
                        : ""
                    }
                    onClick={() => choosingHistoryType("OrderBook")}
                  >
                    <a
                      className={
                        choosingHistoryTypesref.current == "OrderBook"
                          ? "active"
                          : ""
                      }
                      onClick={callCancelOrder}
                    >
                      {t("cancel-orders")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content order-table-row pair_details pading_oedar">
                  {choosingHistoryTypesref.current == "OpenOrders" ? (
                    <div>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("date")}</th>
                              <th>{t("pair")} </th>
                              <th> {t("price")} </th>
                              <th>{t("side")} </th>
                              <th>{t("order-type")} </th>
                              <th> {t("amount")} </th>
                              <th> {t("total")} </th>
                              <th>{t("cancel")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeOrderDatas.length > 0 ? (
                              activeOrderDatas.map((item, i) => {
                                var dates = moment(item.createddate).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                );
                                return (
                                  <tr>
                                    <td>{dates} </td>
                                    <td>{item.pairName} </td>
                                    <td>
                                      {item.tradeType == "buy" ? (
                                        <span className="text-green">
                                          {/* {" "}
                                    {item.ordertype == "Stop"
                                      ? parseFloat(
                                          item.stoporderprice
                                        ).toFixed(pairDetails?.liq_price_decimal)
                                      : parseFloat(item.price).toFixed(
                                          4
                                        )}{" "} */}{" "}
                                          {/* {item.ordertype == "Stop"
                                      ? parseFloat(
                                          item.stoporderprice
                                        ).toFixed(8)
                                      : parseFloat(item.price).toFixed(
                                          8
                                        )}{" "} */}
                                          {parseFloat(item.price).toFixed(8)}{" "}
                                        </span>
                                      ) : (
                                        <span className="text-red">
                                          {/* {" "}
                                    {item.ordertype == "Stop"
                                      ? parseFloat(
                                          item.stoporderprice
                                        ).toFixed(pairDetails?.liq_price_decimal)
                                      : parseFloat(item.price).toFixed(
                                          4
                                        )}{" "} */}{" "}
                                          {/* {item.ordertype == "Stop"
                                      ? parseFloat(
                                          item.stoporderprice
                                        ).toFixed(8)
                                      : parseFloat(item.price).toFixed(
                                          8
                                        )}{" "} */}
                                          {parseFloat(item.price).toFixed(8)}{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td> {item.tradeType} </td>
                                    <td> {item.ordertype} </td>
                                    <td>
                                      {" "}
                                      {parseFloat(item.filledAmount).toFixed(
                                        8
                                      )}{" "}
                                    </td>
                                    <td>
                                      {/* {item.ordertype == "Stop"
                                  ? parseFloat(
                                      item.filledAmount *
                                        item.stoporderprice
                                    ).toFixed(pairDetails?.liq_price_decimal)
                                  : parseFloat(
                                      item.filledAmount * item.price
                                    ).toFixed(pairDetails?.liq_price_decimal)} */}

                                      {/* {item.ordertype == "Stop"
                                  ? parseFloat(
                                      item.filledAmount *
                                        item.stoporderprice
                                    ).toFixed(8)
                                  : parseFloat(
                                      item.filledAmount * item.price
                                    ).toFixed(8)} */}

                                      {parseFloat(
                                        item.filledAmount * item.price
                                      ).toFixed(8)}
                                    </td>
                                    <td>
                                      {cancelloaderref.current == true ? (

                                        <span
                                          className="tradebuttun"
                                        >
                                          Loading
                                        </span>

                                      ) : (

                                        <span
                                          className="tradebuttun"
                                          onClick={() => orderCancel(item)}
                                        >
                                          {t("cancel2")}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {" "}
                                {!checkAuth ? (
                                  <td colSpan="8">
                                    <Button
                                      className="Butn_header my-4 d-block mx-auto"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>{" "}
                                  </td>
                                ) : (
                                  <td colSpan="8" className="text-center">
                                    {" "}
                                    {t("no-found-open-orders")}
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>{" "}
                        {activeOrderDatas.length > 0 ? (
                          <div className="pagination mt-4">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(totalactive / recordPerPage)}
                                page={currentPage}
                                onChange={activePageChange}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                    sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: "#33c2b9",
                                        color: "#fff",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : ("")}
                      </div>
                    </div>
                  ) : choosingHistoryTypes == "OrderHistory" ? (
                    <div>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("date")}</th>
                              <th>{t("pair")} </th>
                              <th>{t("type")} </th>
                              <th>{t("price")} </th>
                              <th>{t("amount")} </th>
                              <th>{t("total")} </th>
                            </tr>
                          </thead>

                          <tbody>
                            {tradeHistoryData.length > 0 ? (
                              tradeHistoryData.map((item, i) => {
                                var datesHis = moment(item.created_at).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                );
                                return (
                                  <tr>
                                    <td>{datesHis} </td>
                                    <td>{item.pair} </td>
                                    <td>{item.type} </td>
                                    <td>
                                      {item.type == "buy" ? (
                                        <span className="text-green">
                                          {" "}
                                          {item.askPrice}{" "}
                                        </span>
                                      ) : (
                                        <span className="text-red">
                                          {" "}
                                          {item.askPrice}{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td> {item.askAmount} </td>
                                    <td>{item.total}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {" "}
                                {!checkAuth ? (
                                  <td colSpan="6">
                                    <Button
                                      className="Butn_header my-4 d-block mx-auto"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>{" "}
                                  </td>
                                ) : (
                                  <td colSpan="6" className="text-center">
                                    {" "}
                                    {t("no-records-found")}
                                  </td>
                                )}{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {tradeHistoryData.length > 0 ? (
                          <div className="pagination mt-4">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(totalHist / recordPerPageHist)}
                                page={currentPageHis}
                                onChange={orderhistoryactive}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                    sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: "#33c2b9",
                                        color: "#fff",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : ("")}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("date")}</th>
                              <th>{t("pair")}</th>
                              <th>{t("side")} </th>
                              <th>{t("type")} </th>
                              <th> {t("price")} </th>
                              <th>{t("amount")} </th>
                              <th>{t("total")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cancelOrders.length > 0 ? (
                              cancelOrders.map((item, i) => {
                                var total =
                                  item.ordertype == "Stop"
                                    ? +item.filledAmount * +item.stoporderprice
                                    : +item.filledAmount * +item.price;
                                return (
                                  <tr>
                                    <td>{item.createddate} </td>
                                    <td>{item.pairName} </td>
                                    <td>
                                      {item.tradeType == "buy" ? (
                                        <span className="text-green">
                                          {" "}
                                          {t("buy")}{" "}
                                        </span>
                                      ) : (
                                        <span className="text-red">
                                          {" "}
                                          {t("sell")}{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td>{item.ordertype} </td>
                                    <td>
                                      <span className="text-green">
                                        {/* {item.ordertype == "Stop"
                                                      ? parseFloat(
                                                          item.stoporderprice
                                                        ).toFixed(pairDetails?.liq_price_decimal)
                                                      : parseFloat(item.price).toFixed(
                                                          4
                                                        )}{" "} */}
                                        {item.ordertype == "Stop"
                                          ? parseFloat(
                                            item.stoporderprice
                                          ).toFixed(8)
                                          : parseFloat(item.price).toFixed(
                                            8
                                          )}{" "}
                                      </span>
                                    </td>
                                    <td> {item.amount} </td>
                                    <td>
                                      {/* {parseFloat(total).toFixed(pairDetails?.liq_price_decimal)} */}
                                      {parseFloat(total).toFixed(8)}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {" "}
                                {!checkAuth ? (
                                  <td colSpan="7">
                                    <Button
                                      className="Butn_header my-4 d-block mx-auto"
                                      onClick={Movelogin}
                                    >
                                      <Link to="/login" className="text-white">
                                        {t("login-to-continue")}
                                      </Link>
                                    </Button>{" "}
                                  </td>
                                ) : (
                                  <td colSpan="7" className="text-center">
                                    {" "}
                                    {t("no-cancel-order-found")}{" "}
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {cancelOrders.length > 0 ? (
                          <div className="pagination mt-4">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(totalcan / recordPerPagecan)}
                                page={currentPagecan}
                                onChange={cancelPageChange}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                    sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: "#33c2b9",
                                        color: "#fff",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : ("")}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <PriceFooter /> */}

              <div className="markert_activity">
                <div class="css-13fs1br">{t("top-movers")}</div>
                {/* <ul class="nav nav-pills">
                  <li class="active">
                    <a data-toggle="pill" href="#All" className="active">
                      {t("all")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="pill" href="#Change">
                      {t("change")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="pill" href="#High">
                      {t("new-high/low")}
                    </a>
                  </li>
                </ul> */}
                <div class="tab-content pair_details pading_tabs_content">
                  <div id="All" class="tab-pane fade in active show">
                    {/* <a href="" target="_blank" class="css-14d05gv">
                      <div class="css-1pysja1">
                        <div data-bn-type="text" class="css-qt6vj7">
                          <span data-bn-type="text" class="css-1iqe90x">
                            ATA
                          </span>
                          /USDT
                        </div>
                        <div data-bn-type="text" class="css-21cgr0">
                          15:42:01
                        </div>
                      </div>
                      <div class="css-m3c6zl">
                        <div data-bn-type="text" class="css-4na7jw">
                          +11.83%
                        </div>
                        <div
                          data-bn-type="text"
                          title="New 7day High"
                          class="css-wk8c7j"
                        >
                          {t("new-7-day-high")}
                        </div>
                      </div>
                      <div class="css-vjdxdv">
                        <div class="css-ao5z3i">
                          <div class="css-1qlplmi"></div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 16"
                            fill="none"
                            class="css-3kwgah"
                          >
                            <path
                              d="M12.555 13v-2.676l3.465-2.677 3.465 2.677V13l-3.465-2.676L12.555 13z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M12.555 8.352V5.677L16.02 3l3.465 2.676v2.676L16.02 5.676l-3.465 2.676zM22 6.784h7.02v2.432H22V6.784zm-18.98 0h7.02v2.432H3.02V6.784z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </a> */}
                    {topMoversref.current.length > 0 ? (
                      topMovers.map((item, i) => {
                        return (
                          <a href="" target="_blank" class="css-14d05gv">
                            <div class="css-1pysja1">
                              <div data-bn-type="text" class="css-qt6vj7">
                                <span data-bn-type="text" class="css-1iqe90x">
                                  {/* ATA */}
                                  {item.pair}
                                </span>
                                {/* /USDT */}
                              </div>
                              {/* <div data-bn-type="text" class="css-21cgr0">
                            15:42:01
                          </div> */}
                            </div>
                            <div class="css-m3c6zl">
                              <div data-bn-type="text" class="css-4na7jw">
                                {item.change_pct} %
                              </div>
                              {/* <div
                            data-bn-type="text"
                            title="New 7day High"
                            class="css-wk8c7j"
                          >
                            {t("new-7-day-high")}
                          </div> */}
                            </div>
                            {/* <div class="css-vjdxdv">
                          <div class="css-ao5z3i">
                            <div class="css-1qlplmi"></div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 16"
                              fill="none"
                              class="css-3kwgah"
                            >
                              <path
                                d="M12.555 13v-2.676l3.465-2.677 3.465 2.677V13l-3.465-2.676L12.555 13z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M12.555 8.352V5.677L16.02 3l3.465 2.676v2.676L16.02 5.676l-3.465 2.676zM22 6.784h7.02v2.432H22V6.784zm-18.98 0h7.02v2.432H3.02V6.784z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </div> */}
                          </a>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center mt-4">
                          {/* {t("no-trades")} */}
                          {t("no-trades-found")}
                        </td>
                      </tr>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </main>
      </div>
    </>
  );
}
export default Home;
