import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [isShowOther, setIsShowOther] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const handleDrop = () => {
    setIsShowDrop((prevDrop) => !prevDrop);
  };
  const handleOther = () => {
    setIsShowOther((prevDrop) => !prevDrop);
  };
  // AUTHOR OF JOTHI

  const Business = [
    { value: "Up to 25,000 euros", label: t("Up to 25,000 euros") },
    {
      value: "From 25,001 to 100,000 euros",
      label: t("From 25,001 to 100,000 euros"),
    },
    {
      value: "From 100,001 to 250,000 euros",
      label: t("From 100,001 to 250,000 euros"),
    },
    { value: "More than 250,000 euros", label: t("More than 250,000 euros") },
  ];

  const fundAccountslist = [
    {
      value: "Ordinary business profits",
      label: t("Ordinary business profits"),
    },
    { value: "Dividends", label: t("Dividends") },
    { value: "Sale of assets", label: t("Sale of assets") },
    { value: "Sale of properties", label: t("Sale of properties") },
    { value: "Leasing", label: t("Leasing") },
    { value: "Investments", label: t("Investments") },
    { value: "Others", label: t("others") },
  ];

  useEffect(() => {
    getStep();
  }, [0]);

  const initialFormValue = {
    fundAccounts: "",
    outflowsFidex: "",
    InvestmentsfundAccounts: "",
    otherfundAccount: "",
    bussinessworth: "",
  };
  const [formValue, setFormValue,formValueref] = useState(initialFormValue);

  const [errors, setErrors] = useState({
    bussinessworth: "",
    fundAccounts: "",
    otherfundAccount: "",
    InvestmentsfundAccounts: "",
  });

  // const [fundAccountsErrMsg, setfundAccountsErrMsg, fundAccountsErrMsgref] =
  //   useState("");
  // const [
  //   fundAccountsvallidate,
  //   setfundAccountsvallidate,
  //   fundAccountsvallidateref,
  // ] = useState(false);
  // const [
  //   bussinessworthErrMsg,
  //   setbussinessworthErrMsg,
  //   bussinessworthErrMsgref,
  // ] = useState("");
  // const [
  //   bussinessworthvallidate,
  //   setbussinessworthvallidate,
  //   bussinessworthvallidateref,
  // ] = useState(false);

 
  const handlefundingChange = (event) => {
    const { value, checked } = event.target;
    let updatedFundAccounts;

    if (checked) {
      updatedFundAccounts = [...formValue.fundAccounts, value];
    } else {
      updatedFundAccounts = formValue.fundAccounts.filter((item) => item !== value);
    }
    setFormValue({ ...formValue, fundAccounts: updatedFundAccounts });
    Vallidate(formValueref.current);
  };

  const handleInvestmentsChange = (event) => {
    setFormValue({ ...formValue, InvestmentsfundAccounts: event.target.value });
    Vallidate(formValueref.current);
  };

  const handleotherfundAccountsChange = (event) => {
    setFormValue({ ...formValue, otherfundAccount: event.target.value });
    Vallidate(formValueref.current);
  };

  const handleworthChange = (selectOption) => {
    setFormValue({ ...formValue, bussinessworth: selectOption.value });
    Vallidate(formValueref.current);
  };

  const Vallidate = (formValue) => {
    const newErrors = { ...errors }; 
    let isValid = true;
    if (formValue.bussinessworth === "") {
      newErrors.bussinessworth = t("your-business-worth-required");
      isValid = false; 
    }else if (formValue.fundAccounts.length === 0) {
      newErrors.fundAccounts = t("fund-account-required");
      newErrors.bussinessworth = ""; 
      isValid = false; 
    } else if ((formValue.fundAccounts.includes("Others") && formValue.otherfundAccount === "")) {
        newErrors.otherfundAccount = t("fund-account-required");
        newErrors.fundAccounts =""
        isValid = false; 
    }else  if ((formValue.fundAccounts.includes("Investments")&& formValue.InvestmentsfundAccounts === "")) {
        newErrors.InvestmentsfundAccounts = t("fund-account-required");
        newErrors.otherfundAccount=""
        isValid = false; 
    }else{
      isValid = true; 
      newErrors.otherfundAccount=""
      newErrors.fundAccounts =""
      newErrors.InvestmentsfundAccounts=""
    }
    setErrors(newErrors); 
    return isValid; 

  };
   
const showsuccessToast = (message) => {
  toast.dismiss();
  toast.success(message);
};

const showerrorToast = (message) => {
  toast.dismiss();
  toast.error(message);
};
  
  const submit = async (e) => {
    e.preventDefault();
    
    const isValid = Vallidate(formValueref.current);

    if (isValid) {
      formValue["verifiedSteps"] = "Step-3";
      const data = {
        apiUrl: apiService.kybThird,
        payload: formValue,
      };
      setButtonLoader(true);
      
      var resp = await postMethod(data);
      setButtonLoader(false);
      
      // if (resp.status === true) {
      //   toast.success(resp.Message);
      //   navigate("/KybInformation4");
      // } else {
      //   toast.error(resp.Message);
      // }
      if (resp.status) {
        // toast.success(resp.Message);
        if(resp.Message=="Successfully completed your Third step verification , continue your next step of verification"){
          showsuccessToast(t("Successfully completed your Third step verification , continue your next step of verification"))
        }
        navigate("/KybInformation4");
      } else {
        if(resp.Message=="Internal server error"){
          showerrorToast(t("Internal-server-error"))
        }else if(resp.Message=="Something Went Wrong. Please Try Again later"){
          showerrorToast(t("Something-Went-Wrong-Please-Try-Again -later"))
        }else if (resp.Message=="Oops , Please verify the previous steps of verification"){
          showerrorToast(t("Oops , Please verify the previous steps of verification"))
        }
        // toast.error(resp.Message);
      }
    }
  };
  

  const getStep = async () => {
    try {
      const data = { apiUrl: apiService.get_kyb_details };
      const resp = await getMethod(data);
      if (resp.data) {
        const kybData = resp.data;
        setFormValue({
          fundAccounts: kybData.fundAccounts || [],
          InvestmentsfundAccounts: kybData.InvestmentsfundAccounts || "",
          otherfundAccount: kybData.otherfundAccount || "",
          bussinessworth: kybData.bussinessworth || "",
        });
      }
    } catch (err) {
    }
  };
 return (
  <>
    <section className="Non_fixed_nav">
      <Header />
    </section>
    <div>
      <div className="Verification assets_main kyc_page_main">
        <div className="container">
          <div className="table_padding_bottom">
            <Link to="/KybInformation2">
              <h6>
                <i className="fa-solid fa-arrow-left-long mr-3"></i>
                {t("verification")}
              </h6>
            </Link>
            <div className="row justify-content-center cards">
              <div className="col-lg-5 kyc_information">
                <h3 className="heading">
                  <span className="inf_no three">3</span>
                  {t("financial-information")}
                </h3>

                <div className="input-groups icons">
                  <h6 className="input-label">{t("net-worth-of-business")}</h6>
                  <div className="custom-select-stake kyc-verify my-2 w-100">
                    <div className="input-groups select-wrapper">
                      <Select
                        type="text"
                        options={Business}
                        value={Business.find((option) => option.value === formValue.bussinessworth) || null}
                        onChange={handleworthChange}
                        placeholder={t("select-type")}
                        styles={colourStyles}
                        className="reg-select-dropdown"
                      />
                    </div>
                  </div>
                  {errors.bussinessworth && (
                    <p className="errorcss">{errors.bussinessworth}</p>
                  )}
                </div>

                <div className="input-groups icons">
                  <h6 className="input-label">{t("source-of-funds-for-account")}</h6>
                  <div className="input-checkboxs">
                    <ul>
                      {fundAccountslist.map((option, index) => (
                        <li key={index} className="checkbox-container">
                          <input
                            id={`income-${index}`}
                            type="checkbox"
                            className="regular_checkbox"
                            value={option.value}
                            checked={formValue.fundAccounts.includes(option.value)}
                            onChange={handlefundingChange}
                          />
                          <div className="custom-check"></div>
                          <label htmlFor={`income-${index}`}>
                            {option.label}
                          </label>
                        </li>
                      ))}
                      {formValue.fundAccounts.includes("Others") && (
                        <div className="input-groups icons mt-3">
                          <h6 className="input-label">
                            {t("please-funds-for-account")}
                          </h6>
                          <div className="input-field ref_input upload_icon">
                            <input
                               type="text"
                               id="numberInput"
                               min="0"
                               maxLength={12}
                               onKeyDown={(evt) =>
                                 ["e", "E", "+", "-"].includes(evt.key) &&
                                 evt.preventDefault()
                               }
                              value={formValue.otherfundAccount}
                              onChange={handleotherfundAccountsChange}
                              placeholder={t("please-specify")}
                            />
                          </div>
                          {errors.otherfundAccount && (
                            <p className="errorcss">{errors.otherfundAccount}</p>
                          )}
                        </div>
                      )}
                      {formValue.fundAccounts.includes("Investments") && (
                        <div className="input-groups icons mt-3">
                          <h6 className="input-label">{t("please-funds-for-account")}</h6>
                          <div className="input-field ref_input upload_icon">
                            <input
                               type="text"
                               id="numberInput"
                               min="0"
                               maxLength={12}
                               onKeyDown={(evt) =>
                                 ["e", "E", "+", "-"].includes(evt.key) &&
                                 evt.preventDefault()
                               }
                              value={formValue.InvestmentsfundAccounts}
                              onChange={handleInvestmentsChange}
                              placeholder={t("please-specify")}
                            />
                          </div>
                          {errors.InvestmentsfundAccounts && (
                            <p className="errorcss">{errors.InvestmentsfundAccounts}</p>
                          )}
                        </div>
                      )}
                    </ul>
                  </div>
                  {errors.fundAccounts && (
                    <p className="errorcss">{errors.fundAccounts}</p>
                  )}
                </div>

                <div className="Submit my-4">
                {
                buttonLoader ==false ?
                  <button type="button" onClick={submit}>
                    {t("next")}
                  </button>
                  :

                  <button type="button" >
                    {t("loading")}...
                  </button>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

}
