import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setfromCurrencyRef(currArrayCrypto);
  }, [0]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Fullname, Setfullname, Fullnameref] = useState("Tether");
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [singleCurrecy, setsingleCurrecy] = useState();

  const [depositStatus, setDepositStatus, depositStatusref] = useState("");
  useEffect(() => {
    getAllcurrency();
    getKYCstatus();
    getdepositHistory();
    getTransaction();
    getSiteSettings();
    var urls = window.location.href;
    var single_currency = urls.split("deposit/")[1];

    if (single_currency) {
      get_single_currecny(single_currency);
      setsingleCurrecy(single_currency);
    }
  }, [0]);
const [Balance,setBalance,Balanceref]=useState(0)
  const get_balance = async (data,data2)=>{
    console.log(data,data2,",data")
    var obj = {
      currency: data,
      currId: data2,
    };

    var data = {
      apiUrl: apiService.user_balance,
      payload: obj,
    };

    var resp = await postMethod(data);
    if (resp.status) {
      setBalance(resp.data.balance);
    } else {
    }
  }
  const getSiteSettings = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setDepositStatus(resp.data.depositStatus);
      }
    } catch (err) {}
  };
  const [refreshLoader, setrefreshLoader] = useState(false);
  const getTransaction = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getdepositHistory();
    }
  };


  const timeOption = {
    timeZone: 'Europe/Madrid', // Spanish timezone
    month: 'short', // Short month (e.g., Dec)
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  };
  const formatter = new Intl.DateTimeFormat('es-ES', timeOption);


  
  const getTransaction2 = async () => {
    setrefreshLoader(true);
    var data = {
      apiUrl: apiService.transaction,
    };
    const timer = setTimeout(() => {
      setrefreshLoader(false);
    }, 10000);
    var resp = await getMethod(data);

    setrefreshLoader(false);
    getdepositHistory();
    getTransaction();
    if (resp.message == true) {
      getdepositHistory();
      getTransaction();
    }
    // return () => clearTimeout(timer);
  };

  const get_single_currecny = async (single_currency) => {
    try {
      if (single_currency) {
        var obj = {
          currency: single_currency,
        };
        var data = {
          apiUrl: apiService.singlewalletcurrency,
          payload: obj,
        };
        var resp = await postMethod(data);
        var obj = {
          value: resp.data[0]._id,
          key: resp.data[0]._id,
          text: resp.data[0].currencySymbol,
          image: { avatar: true, src: resp.data[0].Currency_image },
          //label: resp.data[0].currencySymbol,
          erc20token: resp.data[0].erc20token,
          bep20token: resp.data[0].bep20token,
          arbtoken: resp.data[0].arbtoken,
          trc20token: resp.data[0].trc20token,
          rptc20token: resp.data[0].rptc20token,
          coinType: resp.data[0].coinType,
          currencyName: resp.data[0].currencyName,
          imgurl: resp.data[0].Currency_image,
        };
        setcurrency(obj.text);
        Setfullname(obj.currencyName);
        setImage(obj.imgurl);
        setcointype(obj.coinType);
        onSelect("", obj);
        setallCurrency(resp.data);
      }
    } catch (err) {}
  };
  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setTimeout(() => {
      setSiteLoader(false);
    }, 800);

    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          var obj = {
            value: data[i]._id,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            //label: data[i].currencySymbol,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            arbtoken: data[i].arbtoken,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            coinType: data[i].coinType,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
      setcurrency(currArrayCrypto[0].text);
      Setfullname(currArrayCrypto[0].currencyName);
      setImage(currArrayCrypto[0].imgurl);
      setcointype(currArrayCrypto[0].coinType);
    }
  };

  const [type, settype] = useState();

  useEffect(() => {
    var Type = localStorage.getItem("registe_type");
    settype(Type);
  }, [0]);

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    // setSiteLoader(true);
    var getKYC = await getMethod(data);
    // setSiteLoader(false);
    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
    } else {
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: 1 },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (e, option) => {
    setcur_network([]);
    setnet_default("");
    // setcurrency(option.label);
    console.log(option,"option")
    setcurrency(option.text);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            //label: "ERC20",
            text: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            //label: "BEP20",
            text: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.arbtoken == "1") {
          network_cur = {
            value: "arbtoken",
            //label: "BEP20",
            text: "ARB",
          };
          network_names.push(network_cur);
        }
        
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            //label: "TRC20",
            text: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        setnet_default(network_currencyref.current[0].text);
      }
    get_balance(currencydata.currencySymbol,option.value)

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currencySymbol: currencydata.currencySymbol,
          currId: option.value,
          network: "",
        };

        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };

        setview("load");
        var resp = await postMethod(data);
        if (resp.status) {
          setview("view");
          setAddress(resp.data);
        } else {
        }
      } else {
        var obj = {
          currency: option.label,
        };
        // var data = {
        //   apiUrl: apiService.bankwire,
        //   payload: obj,
        // };

        // var resp = await postMethod(data);
        // if (resp.status) {
        //   setBankwire(resp.data);
        // } else {
        // }
      }
    }
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("copied-success"));
  };

  const onSelect_network = async (e, option) => {
    setNetworks(option.text);
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currencySymbol: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      var data = {
        apiUrl: apiService.generateAddress,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      if (resp.status) {
        setview("view");
        setAddress(resp.data);
      } else {
      }
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>  

            {siteLoader == true ? (
              <div className="loadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="col-lg-10 col-md-12 p-0">
                <div className="pad-l-r">
                  <section className="asset_section pad-r-none pad-l-none">
                    <div className="row">
                      {depositStatusref.current == "Active" ? (
                        kycStatusref.current == 1 ? (
                          <>
                            <div>
                              <div className="identification_title deposit-flex">
                                <h5 className="deposit--title">
                                  {t("deposit")}
                                </h5>
                              </div>
                            </div>
                            <div className="col-lg-7">
                              <div className="mt-5 deposit depo-pad">
                                <div className="form_div">
                                  <div className="sides">
                                    <div className="w-100 rights">
                                      <h4>
                                        {" "}
                                        <span className="deposit-num">
                                          1
                                        </span>{" "}
                                        {t("choose-token-deposit")}
                                      </h4>
                            <div className="balance"> <h6>{t("token")}</h6><span><b>Balance</b> : {Balanceref.current.toFixed(8)}</span></div>

                                     
                                      {singleCurrecy == "" ||
                                      singleCurrecy == undefined ||
                                      singleCurrecy == null ? (
                                        <Dropdown
                                          placeholder={
                                            singleCurrecy == "" ||
                                            singleCurrecy == undefined ||
                                            singleCurrecy == null
                                              ? t("Select Coin")
                                              : singleCurrecy
                                          }
                                          fluid
                                          className="dep-drops"
                                          selection
                                          options={allCurrencyref.current}
                                          defaultValue={
                                            allCurrencyref.current[0]
                                          }
                                          onChange={onSelect}
                                        />
                                      ) : (
                                        <Dropdown
                                          placeholder={singleCurrecy}
                                          fluid
                                          className="dep-drops"
                                          selection
                                          options={allCurrencyref.current}
                                          defaultValue={singleCurrecy}
                                          onChange={onSelect}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {cur_currencyref.current.currencyType == "2" ? (
                                  <div className="form_div ">
                                    <h4>
                                      {" "}
                                      <span className="deposit-num">
                                        2
                                      </span>{" "}
                                      {t("choose-network")}
                                    </h4>
                                    <h6>{t("network")}</h6>
                                    <Dropdown
                                      placeholder={t("Select Network")}
                                      fluid
                                      className="dep-drops"
                                      selection
                                      options={network_currencyref.current}
                                      defaultValue={
                                        network_currencyref.current[0]
                                      }
                                      onChange={onSelect_network}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {addressref.current == undefined ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="form_div boder-none ">
                                      <h4>
                                        {" "}
                                        <span className="deposit-num">
                                          3
                                        </span>{" "}
                                        {t("network-address")}
                                      </h4>
                                      <img
                                        src={
                                          addressref.current == undefined
                                            ? ""
                                            : addressref.current.qrcode
                                        }
                                        className="mx-auto d-block dep-qr "
                                        alt="deposit-QR"
                                      />

                                      <p className="depo-qr-content">
                                        {t("scan-qr-deposit")}
                                      </p>
                                    </div>

                                    {/* <div className="add_box">
                                      <h6 className="address">
                                        {addressref.current == undefined
                                          ? ""
                                          : addressref.current.address}
                                      </h6>
                                      <i
                                        class="ri-file-copy-line text_green icon"
                                        onClick={() =>
                                          copy(addressref.current.address)
                                        }
                                        className="d-block mx-auto dep-qr "
                                      />
                                      <p>{t("scan-qr-deposit")}</p>
                                    </div> */}

                                    <div className="form_div boder-none depo-qr-input">
                                      <div className="add_box">
                                        <h6 className="address">
                                          {addressref.current == undefined
                                            ? ""
                                            : addressref.current.address}
                                        </h6>
                                        <i
                                          class="ri-file-copy-line text_green icon"
                                          onClick={() =>
                                            copy(addressref.current.address)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                            </div>

                            <div className="col-lg-5">
                              <div>
                                <div className="container">
                                  <div className="mt-5 deposit-imp-notes">
                                    <div className="imp-notes-title">
                                      <span>
                                        <img
                                          src={ICON}
                                          alt="warn-icon"
                                          className="deposit-imp-icon"
                                        />
                                      </span>
                                      <p>{t("imp-notes")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("double-check-dep-ad")}</h6>
                                      <p>{t("double-check-dep-ad-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("verify-dep-amount")}</h6>
                                      <p>{t("verify-dep-amount-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("security")}</h6>
                                      <p>{t("imp-security-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("network-compatibility")}</h6>
                                      <p>
                                        {t("network-compatibility-content")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="dashboard_table">
                              <div className="staking-flex dash_assets">
                                <h5 className="opt-title">
                                  {t("recent-deposit")}
                                  {refreshLoader == true ? (
                                    <i class="fa-solid fa-arrows-rotate fa-spin-pulse deposit_refresh"></i>
                                  ) : (
                                    <i
                                      class="fa-solid fa-arrows-rotate deposit_refresh"
                                      onClick={getTransaction2}
                                    ></i>
                                  )}
                                </h5>
                                <Link to="/depositHistory">
                                  <div className="ref_view_main">
                                    <span className="invite_text">
                                      {t("view-all")}
                                    </span>
                                    <i
                                      class="ri-arrow-right-s-line"
                                      style={{ color: "#33C2B9" }}
                                    ></i>
                                  </div>
                                </Link>
                              </div>
                              <div className="table-responsive table-cont dash_table_content">
                                <table className="table ">
                                  <thead>
                                    <tr className="stake-head depo-head font-satoshi">
                                      <th className="deposit-table-date">
                                        {t("date")}
                                      </th>
                                      {/* <th className="table_center_text">
                                        {t("time")}
                                      </th> */}
                                      <th className="table_center_text">
                                        {t("currency")}
                                      </th>
                                      <th className="table_center_text">
                                        {t("amount")}
                                      </th>
                                      <th className="table_center_text">
                                        {t("transaction-id")}
                                      </th>
                                      <th className="table_action">
                                        {t("status")}
                                      </th>
                                    </tr>
                                  </thead>

                                    {refreshLoader == true ? (
                                   <div className="loadercss width"  colSpan={6}>
                                     <Bars
                                      
                                       height="80"
                                       width="80"
                                       color="#33c2b9"
                                       ariaLabel="bars-loading"
                                       wrapperStyle={{}}
                                       wrapperClass=""
                                       visible={true}
                                     />
                                   </div>
                                 ) : (
                                  <tbody>
                                    {depositHistory &&
                                    depositHistory.length > 0 ? (
                                      depositHistory
                                        .slice(0, 5)
                                        .map((item, i) => {
                                          return (
                                            <tr>
                                              {/* <td className="table-flex">
                                    <img
                                      src={require(`../assets/${options.optImg}`)}
                                      alt=""
                                    />
                                    <div className="table-opt-name">
                                      <h4 className="opt-name font-satoshi font_14">
                                        {options.optName}
                                      </h4>
                                      <h3 className="opt-sub font-satoshi font_14">
                                        {options.optSub}
                                      </h3>
                                    </div>
                                  </td> */}
                                              <td className="px-4 opt-term font-satoshi font_14">
                                                {/* {Moment(item.date).format(
                                                  "lll"
                                                )} */}
                                            { formatter.format(new Date(item.date))}
                                              </td>
                                              {/* <td className="opt-term font-satoshi font_14 table_center_text">
                                                {Moment(item.date).format(
                                                  "HH:mm:ss"
                                                )}
                                              </td> */}
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {item.currencySymbol}
                                              </td>
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {parseFloat(
                                                  item.amount
                                                ).toFixed(6)}
                                              </td>
                                              <td className="opt-term font-satoshi font_14 table_center_text">
                                                {item.txnid.substring(0, 10)}{" "}
                                                ...{" "}
                                                <i
                                                  class="fa fa-clone"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    copy(item.txnid)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                ></i>
                                              </td>
                                              <td className="px-2 opt-term font-satoshi font_14 table_center_last text-success">
                                                {t("completed")}
                                              </td>
                                            </tr>
                                          );
                                        })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="py-5 text-center"
                                        >
                                          <div className="empty_data">
                                            <div className="empty_data_img">
                                              <img
                                                src={require("../assets/No-data.png")}
                                                width="100px"
                                              />
                                            </div>
                                            <div className="no_records_text">
                                              {t("no-records-found")}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                   )}
                                </table>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row ">
                              <div>
                                <div className="identification_title deposit-flex">
                                  <h5 className="deposit--title">
                                    {t("deposit")}
                                  </h5>
                                  {/* <Link to="" className="deposit--btn">
                                  {t("fiat-deposit")}{" "}
                                  <span>
                                    <i class="fa-solid fa-chevron-right"></i>
                                  </span>
                                </Link> */}
                                </div>
                              </div>

                              <div className="col-lg-7">
                                <div className="mt-5 deposit-identity-box h-100">
                                  <div className="dep-kyc">
                                    <div className="dep-kyc-head">
                                      <img
                                        src={ICON}
                                        alt="warn-icon"
                                        className="deposit-imp-icon"
                                      />
                                      {type == "Corporates" ? (
                                        <h6>
                                          {t("kyb-verification-required")}
                                        </h6>
                                      ) : (
                                        <h6>
                                          {t("kyc-verification-required")}
                                        </h6>
                                      )}
                                    </div>
                                    {type == "Corporates" ? (
                                      <p>
                                        {t(
                                          "kyb-dep-verification-required-content"
                                        )}
                                      </p>
                                    ) : (
                                      <p>
                                        {t("dep-verification-required-content")}
                                      </p>
                                    )}
                                    <div>
                                      <img
                                        src={require("../assets/deposit-id.webp")}
                                        alt="Verify kyc"
                                        className="before_kyc_depo withdraw-p-l-24"
                                      />
                                    </div>
                                    <p className="mt-4">
                                      {t("please-click-button")}
                                    </p>
                                    <div className="withdraw-p-l-24">
                                      {type == "Corporates" ? (
                                        <Link to="/kyb">
                                          <button className="mb-2 dep-kyc-btn w-100">
                                            {t("verify-now")}
                                          </button>
                                        </Link>
                                      ) : (
                                        <Link to="/kyc">
                                          <button className="mb-2 dep-kyc-btn w-100">
                                            {t("verify-now")}
                                          </button>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-5">
                                <div>
                                  <div className="container">
                                    <div className="mt-5 deposit-imp-notes max-lg-t-3">
                                      <div className="imp-notes-title">
                                        <span>
                                          <img
                                            src={ICON}
                                            alt="warn-icon"
                                            className="deposit-imp-icon"
                                          />
                                        </span>
                                        <p>{t("imp-notes")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("double-check-dep-ad")}</h6>
                                        <p>
                                          {t("double-check-dep-ad-content")}
                                        </p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("verify-dep-amount")}</h6>
                                        <p>{t("verify-dep-amount-content")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("security")}</h6>
                                        <p>{t("imp-security-content")}</p>
                                      </div>
                                      <div className="imp-notes-content">
                                        <h6>{t("network-compatibility")}</h6>
                                        <p>
                                          {t("network-compatibility-content")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <div className="row ">
                            {/* <div>
                            <div className="identification_title deposit-flex">
                              <h5 className="deposit--title">{t("deposit")}</h5>
                              <Link to="" className="deposit--btn">
                                {t("fiat-deposit")}{" "}
                                <span>
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </div>
                          </div> */}

                            <div className="col-lg-7">
                              <div className="mt-5 deposit-identity-box h-100">
                                <div className="dep-kyc">
                                  <div className="dep-kyc-head">
                                    <img
                                      src={require("../assets/dep-warn.png")}
                                      alt="warn-icon"
                                      className="deposit-imp-icon"
                                    />
                                    <h6>{t("deposit-temp-unavail")}</h6>
                                  </div>
                                  <p>{t("deposit-temp-unavail-content")}</p>
                                  <p className="my-3">
                                    {t("estimated-time-to-resolution: ")}{" "}
                                    <span className="text-primary">
                                      00:00:00
                                    </span>
                                  </p>
                                  <div>
                                    <img
                                      src={require("../assets/dep-unavail.png")}
                                      alt="Verify kyc"
                                      className="before_kyc_depo withdraw-p-l-24"
                                    />
                                  </div>
                                  <p className="mt-4">
                                    {t("thank-you-for-your-patience")}
                                  </p>
                                  <div className="withdraw-p-l-24">
                                    <Link to="/dashboard">
                                      <button className="mb-2 dep-kyc-btn w-100">
                                        {t("back-to-home")}
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-5">
                              <div>
                                <div className="container">
                                  <div className="mt-5 deposit-imp-notes max-lg-t-3">
                                    <div className="imp-notes-title">
                                      <span>
                                        <img
                                          src={ICON}
                                          alt="warn-icon"
                                          className="deposit-imp-icon"
                                        />
                                      </span>
                                      <p>{t("imp-notes")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("double-check-dep-ad")}</h6>
                                      <p>{t("double-check-dep-ad-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("verify-dep-amount")}</h6>
                                      <p>{t("verify-dep-amount-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("security")}</h6>
                                      <p>{t("imp-security-content")}</p>
                                    </div>
                                    <div className="imp-notes-content">
                                      <h6>{t("network-compatibility")}</h6>
                                      <p>
                                        {t("network-compatibility-content")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
